@font-face {
  font-family: "Spartan", sans-serif;
  src: url("../../../../assets/fonts/emarsys/spartan/SpartanMB-SemiBold.otf") format("otf");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "Spartan", sans-serif;
  src: url("../../../../assets/fonts/emarsys/spartan/SpartanMB-Bold.otf") format("otf");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}
