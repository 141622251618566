cx-page-layout {
  &.ProductListPageTemplate,
  &.ProductGridPageTemplate,
  &.SearchResultsListPageTemplate {
    @extend %ProductListPageTemplate !optional;
    @extend %ProductGridPageTemplate !optional;
    @extend %SearchResultsListPageTemplate !optional;

    max-width: var(--cx-page-width-max);
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    .ProductLeftRefinements {
      max-width: 25%;
      padding-top: 4px;
      padding-inline-end: 15px;
      padding-bottom: 15px;
      padding-inline-start: 15px;

      cx-product-facet-navigation {
        margin-top: 4px;

        @include media-breakpoint-down(md) {
          > .btn-action:first-child {
            text-align: left;

            .fa-filter {
              float: right;
              margin-top: 2px;
            }
          }
        }
      }

      cx-active-facets {
        padding: 10px 16px 0;

        h4 {
          margin: 8px 0 0;
        }
      }

      cx-facet {
        button.heading {
          border-bottom: none;
          text-transform: uppercase;
          font-size: 16px;
          margin: 0 0 10px;
          padding-bottom: 0;
        }

        a.value {
          padding: 6px 12px;
          font-size: 16px;
          text-transform: uppercase;

        }

        &.multi-select a.value {
          font-size: 14px;
        }

        .more .cx-action-link {
          margin-left: 8px;
        }

        + cx-facet {
          margin-top: 10px;
        }

        cx-icon {
          &.fas:before {
            font-family: "icomoon";
          }

          &.fa-plus:before {
            content: '\e973';
          }

          &.fa-minus:before {
            content: '\e979';
          }
        }
      }
      @include media-breakpoint-down(md) {
        width: 100%;
        max-width: none;
      }
      cx-facet-list {
        @include media-breakpoint-up(lg) {
          cx-active-facets {
            display: none;
          }
        }
        @include media-breakpoint-down(lg) {
          cx-active-facets {
            padding: 0 20px;

            h4 {
              display: block;
              margin: 0 0 0 -20px;
            }
          }
        }
      }
    }
    .ProductListSlot,
    .ProductGridSlot,
    .SearchResultsListSlot {
      position: relative;
      max-width: 75%;

      .cx-sorting {
        display: flex;

        &.top {
          padding-bottom: 30px;
          border-bottom: thin solid $border-menu;
        }

        &.bottom {
          border-top: thin solid $border-menu;
        }

        cx-product-view {
            margin-left: auto;

            @include media-breakpoint-down(sm) {
              display: none;
            }
        }
      }

      .cx-sort-dropdown {
        width: 200px;
        margin-bottom: 0;
      }

      .product-grid-results {
        padding: 11px 0px 11px 20px;

        @include media-breakpoint-up(lg) {
          border-left: thin solid $border-menu;
          margin-left: 24px;
          min-width: 100px;
        }

        @include media-breakpoint-down(md) {
          margin: auto;
        }

        @include media-breakpoint-down(sm) {
          margin-right: 0;
        }
      }

      cx-add-to-cart {
        .btn-add-to-cart {
          .btn-add-to-cart-text {
            display: inline;
          }
        }
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        max-width: none;

        .product-grid-results {
          top: 12px;
        }

        cx-add-to-cart {
          .unit-discount,
          .unit-price-item {
            display: none;
          }
        }
      }

      .search-empty {
        font-size: 20px;
        font-weight: bold;
        margin: 60px 0;
      }

      .cx-product-image-slots {
        position: relative;
      }

      .cx-product-slots {
        border: thin solid $border-menu;
        position: absolute;
        top: 6px;
        right: 6px;
        padding: 0 6px;
        font-size: 14px;
        color: var(--cx-color-text);
      }
    }

    .Section1,
    .Section2 {
      padding: 0 1rem;

      @extend .container !optional;

      @include media-breakpoint-up(lg) {
        max-width: 100%;
        margin-left: 0;
        justify-content: start;
        flex-flow: row wrap;

        > * {
          flex: 1 100%;
          max-width: 75%;
        }

        cx-cart-totals {
          max-width: 25%;
          margin: 0;
        }
      }
    }

    .Section2 {
      padding: 0 1rem;
    }
  }

  &.ProductGridPageTemplate .Section2 {
    padding-top: 12px;
    padding-bottom: 12px;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
    box-shadow: 0 4px 12px rgb(0 0 0 / 7%);
    margin-bottom: 12px;

    @include media-breakpoint-only(md) {
      margin-bottom: 20px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 30px;
      padding-bottom: 0;
      padding-top: 10px;
    }
  }
}
