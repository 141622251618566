app-custom-media-links {
  text-align: center;
  padding: 44px 0 40px;

  .title {
    font-weight: 900;
    font-size: 18px;
    color: $secondary;
    margin-bottom: 8px;
    line-height: 1.22;
  }

  img {
    height: 40px;
    width: 40px;
  }

  ul {
    list-style: none;
    display: inline-flex;
    margin: 0;
    padding: 0;

    li + li {
      margin-left: 6px;
    }
  }
}
