app-custom-carousel-banner-slider {
  position: relative;
  display: block;

  cx-carousel {
    height: 100vh;
    overflow: hidden;
    position: relative;

    @include media-breakpoint-up(sm) {
      height: 700px;
    }


    .carousel-panel, .slide {
      height: 100%;
      background: #00000047;
      position: relative;
    }

    .indicators {
      position: absolute;
      bottom: .5rem;
      width: 100%;
      z-index: 1;
      text-align: center;

      button {
        margin: 10px;
        padding: 0;
        background-color: transparent;
        @include media-breakpoint-down(md) {
          margin: 5px;
          padding: 0;
        }

        cx-icon {
          @include media-breakpoint-down(md) {
            font-size: 12px;
          }
          color: rgba(255, 255, 255, .3) !important;
          border: none !important;
          background-color: transparent !important;
        }

        &:disabled {
          cx-icon {
            color: rgba(255, 255, 255, 1) !important;
          }
        }
      }

      @include media-breakpoint-down(sm) {
        display: block;
      }
    }

    .previous, .next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

app-custom-carousel-banner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .container{
    padding: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: $compCarouselBannerSliderJustifyContent;
    text-align: $compCarouselBannerSliderTextAlign;

    > cx-media {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .content {
    padding: 2rem;
    position: relative;

    .btn {
      font-family: $compCarouselBannerSliderFontFamily;
      padding: $compCarouselBannerSliderBtnPadding;
      font-weight: $compCarouselBannerSliderFontWeight;
    }

    > cx-media {  
      margin-top: 90px;
      margin-bottom: 35px;

      img {
        max-height: 100px;
      }
    }
  }

  .title {
    font-size: 48px;
    font-family: $font-family-custom1;
    color: white;
    font-weight: $compCarouselBannerSliderFontWeight;
    line-height: $compCarouselBannerSliderLineHeight;
    letter-spacing: $compCarouselBannerSliderLetterSpace;
    text-shadow: 2px 4px 6px #09090985;

    @include media-breakpoint-only(md) {
      font-size: 64px;
      line-height: $compCarouselBannerSliderLineHeightMd;
    }

    @include media-breakpoint-up(lg) {
      font-size: 75px;
      line-height: $compCarouselBannerSliderLineHeightLg;
    }
  }

  .description {
    font-size: 12px;
    color: white;
    font-weight: 600;
    text-shadow: 2px 4px 6px #09090985;

    @include media-breakpoint-up(md) {
      font-size: 18px;
      max-width: 700px;
    }
  }
}

