.cx-product-labels-container {
  > span {
    display: inline-block;
    margin: 0 10px 10px 0;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 2px;
    background-color: var(--cx-color-primary);
    color: white;
    opacity: 0.8;
  }
}
