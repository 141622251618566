cx-searchbox {
  position: static;
  @extend %cx-searchbox !optional;
  > * {
    @include media-breakpoint-up(md) {
      background-color: var(--cx-color-transparent);
    }
  }
  .search-icon {
    color: currentColor !important;
  }

  .cx-icon, cx-icon {
    font-size: 20px;
  }
  // cxFeat_a11ySearchboxLabel allow to new SearchBox component. However, since we customized this component, breaking changes are expected
  // New Class: https://github.com/SAP/spartacus/blob/ec51a25f1d34f01d298c6d1d7dc87151c41b301c/projects/storefrontlib/cms-components/navigation/search-box/search-box.component.html#L71
  // Feature docs: https://help.sap.com/docs/SAP_COMMERCE_COMPOSABLE_STOREFRONT/10a8bc7f635b4e3db6f6bb7880e58a7d/16bfdef6ff0d48d48f67f1af8cd49cc5.html
  // cxFeat_a11ySearchboxLabel
  label:not(.cxFeat_a11ySearchboxLabel) {
    width: auto;
    max-width: none;
    min-width: auto;
    padding-bottom: 7px;
    border: none;

    input {
      width: 100%;
      max-width: var(--cx-page-width-max);
      opacity: 0;
      border: 0 solid $border-menu;
      padding: 0 15px;
      height: 0;
      background-color: white;
      box-shadow: 0 5px 27px rgb(0 0 0 / 9%);
      transition: all .5s ease, height 1s, opacity 0.9s;
    }

    cx-icon.reset {
      body:not(.searchbox-is-active) & {
        display: none;
      }

      @include media-breakpoint-down(sm) {
        left: 100px;
        z-index: 25;
        top: 37px;
      }
    }
    cx-icon.search {
      body:not(.searchbox-is-active) & {
        display: block;
      }
    }
  }
  .hidden {
    display: none;
  }
  .results {
    width: 100%;
    top: 170px;
    left: 0;
    @include media-breakpoint-down(lg) {
      top: 120px;
    }
    @include media-breakpoint-down(sm) {
      top: 104px;

      .products {
        display: block;
      }
    }
    .suggestions {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      padding-inline-start: 0;
      margin-bottom: 0;
      li,
      a {
        flex: 100%;
        line-height: 2rem;
        display: block;
      }
    }
    .products {
      list-style: none;
      padding-inline-start: 0;
      margin-bottom: 0;

      @include media-breakpoint-down(sm) {
        display: none;
      }

      a {
        display: grid;
        grid-column-gap: 16px;

        &.has-media {
          // create a grid layout in case we show an image
          grid-template-columns: 50px 1fr;
        }

        border-top: solid 1px var(--cx-color-light);

        cx-media {
          grid-row: 1 / 3;
        }

        div.name {
          text-overflow: ellipsis;

          font-size: inherit;
          font-weight: inherit;

          // Required for text-overflow to do anything
          white-space: nowrap;
          overflow: hidden;
        }
        .price {
          font-weight: normal;
        }
      }
    }
  }
}

body.searchbox-is-active {
  .LandingPageTransparentHeaderTemplate {
    header {
      position: absolute;
    }
  }
}

body.searchbox-is-active.has-searchbox-results cx-storefront:before {
  content: "";
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
}
.searchbox-is-active {
  .searchbox input {
    opacity: 1;
    height: 40px;
    border-width: thin;
  }
}

@media (max-width: 767.98px){
cx-searchbox label input {
    position: absolute;
    left: 0;
    top: 64px;
    width: 100%;
    background-color: var(--cx-color-secondary);
    padding: 6px 16px;
    height: 80px;
    border-bottom: 1px solid var(--cx-color-light);
  }
}
@media (max-width: 767.98px){
  cx-searchbox .results {
      top: 89px;
      z-index: 10;
  }

}

@media (max-width: 767.98px) {

  cx-searchbox{
   label{
    button.reset cx-icon, cx-searchbox label div.search-icon.reset cx-icon {
      position: relative;
      left: 140px;
      z-index: 99;
      top: 90px;
      size: 100px;
      margin-top: 0;
    }
   }

  }
}


