a.btn-login,
button.btn-login {
    background-color: transparent;
    border: 2px solid white;
    color: white;
    text-transform: $btn-text-transform;
    letter-spacing: 0.3px;
    border-radius: $btn-border-radius;

    &:hover {
        background-color: $primary;
        border-color: $primary;
        color: white;
    }
}

a.btn-send,
button.btn-send {
    color: $secondary;
    background-color: transparent;
    border: 2px solid $primary;
    border-radius: $btn-border-radius;
    text-transform: $btn-text-transform;
    font-weight: bold;

    &:not(:disabled):not(.disabled),
    &:not(:disabled):not(.disabled) {
      &.active,
      &:active,
      &:hover {
        filter: none;
        background-color: var(--cx-color-primary);
        border-color: var(--cx-color-primary);
        color: white;
      }
      
      &.active,
      &:active {
        &:focus {
          box-shadow: 0 0 0 0.2em rgba(var(--cx-color-primary), 0.5);
        }
      }
    }
}

a,
button {
  &.link-alt {
    @extend .btn, .btn-link, .btn-sm;

    color: var(--cx-color-secondary);
    text-transform: $btn-text-transform;
    font-weight: 600;
    letter-spacing: 2.4px;
    text-decoration: none;

    &:hover {
      color: var(--cx-color-primary);
      text-decoration: none;
    }

    &.disabled,
    &:disabled {
        &:hover, &:after, &:focus{
          color: var(--cx-color-secondary);
        }
    }
  }
}

.btn-action {
    @extend .btn-action;
    border-radius: $btn-border-radius;
    border-color: $primary;
    color: $secondary;
    text-transform: $btn-text-transform;
    font-weight: bold;
    letter-spacing: 2px;

    &:not(:disabled):not(.disabled),
    &:not(:disabled):not(.disabled) {
      &.active,
      &:active,
      &:hover {
        filter: none;
        background-color: var(--cx-color-primary);
        border-color: var(--cx-color-primary);
        color: white;
      }
      
      &.active,
      &:active {
        &:focus {
          box-shadow: 0 0 0 0.2em rgba(var(--cx-color-primary), 0.5);
        }
      }
    }

    &.disabled,
    &:disabled {
        &,
        &:hover,
        &:active,
        &:focus {
            color: $color-extra-5;
            background-color: transparent;
            border-color: $light;
            cursor: not-allowed;
        }
    }
}

.btn-primary {
  @extend .btn-primary;

  border-radius: $btn-border-radius;
  font-weight: bold;
  text-transform: $btn-text-transform;

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    filter: none;
  }

  &:not(:disabled):not(.disabled) {
    &:hover {
      background-color: var(--cx-color-secondary);
      border-color: var(--cx-color-secondary);
      filter: none;
    }
  }
  
  &:disabled {
    background-color: var(--cx-color-primary);
    border-color: var(--cx-color-primary);
    &:hover {
      background-color: var(--cx-color-primary);
      border-color: var(--cx-color-primary);
    }
  }

}

.btn-default{
    border: none;
    color: $secondary;
    text-transform: $btn-text-transform;
    font-weight: bold;

    &:not(:disabled):not(.disabled) {
        &:hover, &:after, &:focus{
            color: $primary;
        }
    }

    &.disabled,
    &:disabled {
        &:hover, &:after, &:focus{
            color: $secondary;
        }
    }
}

.btn-icon {
    border-width: 2px;
    border-style: solid;
    cursor: pointer;

    &:not(:disabled):not(.disabled) {
        &:hover,
        &.active {
        color: var(--cx-color-primary);
        background-color: none;
        }
    }

    &.disabled,
    &:disabled {
      color: var(--cx-color-light);
      opacity: 1;
    }

    &.btn-icon-fade:not(:disabled):not(.disabled) {
      &:hover,
      &.active {
        opacity: .8;
      }
    }
  }

.btn-sm{
  padding-top: 6px;
  padding-bottom: 6px;
}

.btn {
  height: auto;
  min-height: 48px;
  max-height: none;
  letter-spacing: $btn-letter-spacing;
  font-size: $btn-custom-font-size;
  padding-left: $btn-padding-sides;
  padding-right: $btn-padding-sides;
}

a.download {
  color: var(--cx-color-secondary);
  display: flex;
  font-size: 14px;
  margin: 5px 0 0;
  padding-bottom: 10px;
  position: relative;

  &:after {
    position: absolute;
    content: "";
    width: 0;
    display: block;
    border-bottom: 1px solid var(--cx-color-primary);
    padding-top: 10px;
    -webkit-transition: .3s all;
    -moz-transition: .3s all;
    -ms-transition: .3s all;
    -o-transition: .3s all;
    transition: .3s all;
    bottom: 0;
  }

  .file-name {
    font-size: 14px;
  }

  .icon {
    margin-left: 16px;
    &:before {
      color: var(--cx-color-secondary);
    }
  }

  &:hover {
    &:after {
      width: 98%;
      -webkit-transition: .3s all;
      -moz-transition: .3s all;
      -ms-transition: .3s all;
      -o-transition: .3s all;
      transition: .3s all;
    }

    .icon {
      &:before {
        color: var(--cx-color-primary);
      }
    }
  }
}

.btn-link {
  letter-spacing: normal;
  transition: all .3s;

  &.active,
  &:active,
  &:hover {
      text-decoration: none;
  }
}

.btn-read-more {
  color: var(--cx-color-secondary);
  font-size: 12px;
  text-decoration: none;
  transition: all .3s;
  letter-spacing: 1.2px;
  text-transform: $btn-text-transform;
  padding: 0;
  font-weight: 600;

  &:not(:disabled):not(.disabled),
  &:not(:disabled):not(.disabled) {
    &.active,
    &:active,
    &:hover {
      color: var(--cx-color-primary);
      text-decoration: none;

      &::before {
        color: var(--cx-color-primary);
      }
    }
  }

  cx-icon:last-child {
    padding-left: 8px;
  }
}

.btn-read-more-alt {
  color: var(--cx-color-secondary);
  font-size: 12px;
  text-decoration: none;
  transition: all .3s;
  letter-spacing: 1.2px;
  text-transform: $btn-text-transform;
  padding: 0;
  font-weight: 600;

  &::before {
    color: $color-extra-15;
    content: ">";
    transition: all .3s;
    padding-right: 4px;
  }

  &:not(:disabled):not(.disabled),
  &:not(:disabled):not(.disabled) {
    &.active,
    &:active,
    &:hover {
      color: var(--cx-color-primary);
      padding-left: 5px;
      text-decoration: none;

      &::before {
        color: var(--cx-color-primary);
      }
    }
  }
}
