app-custom-modal {
  img{
    max-width: 100%;
    object-fit: contain;
  }
  .cx-dialog-header {
    padding-top: 2rem;
    padding-inline-end: 1.75rem;
    padding-bottom: 0.85rem;
    padding-inline-start: 1.75rem;
    border-width: 0;
    display: flex;
  }

  .cx-dialog-body {
    padding: 1rem 1.75rem;;
  }

  .cx-dialog-title {
    font-size: var(--cx-font-size, 1.375rem);
    font-weight: var(--cx-font-weight-semi);
    line-height: var(--cx-line-height, 1.2222222222);
    align-self: flex-start;
  }

  .cx-dialog-buttons{
    padding: .5rem 1.75rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-width: 100%;
  }

  .btn {
    cursor: pointer;
    margin: .5rem
  }
}

