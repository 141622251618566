@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../../../../assets/fonts/emarsys/poppins/Poppins-Medium.ttf") format("ttf");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../../../../assets/fonts/emarsys/poppins/Poppins-Bold.ttf") format("ttf");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}
