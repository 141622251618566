.form-group{
    @extend %form-group !optional;
    margin-bottom: 2rem;
}

input,
textarea {
  &.form-control {
    @extend %form-control !optional;

    background-color: $input-bg-color;
    border-color: $input-bg-color;
    border-radius: 0;
  }
}

.form-control {
  &:read-only,
  &:disabled {
    &:focus {
      background-color: $color-extra-8;
    }
  }
}

.ng-select{
    .ng-select-container{
        border-radius: 0;
        border-color: $grey-light;
        background: $input-bg-color;
    }

    .ng-dropdown-panel-items{
        background-color: $grey-light;
    }

    &.ng-select-opened {
        &.ng-select-single .ng-arrow-wrapper,
        .ng-arrow-wrapper {
            .ng-arrow {
                transform: rotate(-135deg) translate(-25%, -25%);
            }
        }
        &.ng-select-bottom{
            > .ng-select-container{
                background-color: $input-bg-color;
            }
        }
    }

    &.ng-select-single .ng-arrow-wrapper, .ng-arrow-wrapper {
        .ng-arrow{
            width: 12px;
            height: 12px;
            border-width: thin;
            border-style: solid;
            border-left: none;
            border-top: none;
            border-color: gray;
            transform: rotate(45deg);
            transition: ease all .3s;
        }
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked,
    .ng-select-single .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
        background-color: $primary;
    }

}

.ng-dropdown-panel{
    &.ng-select-bottom{
        box-shadow: 0 8px 60px 0 rgb(181 181 181 / 34%), 0 12px 90px 0 rgb(0 0 0 / 5%);
    }

    .ng-dropdown-panel-items .ng-option{
        background-color: $grey-light;
    }
}

.label-content{
    text-transform: uppercase;
    color: $color-extra-5;

    &.required:after {
        content: '*';
        position: relative;
        color: red;
        margin-left: 4px;
    }
}

.input-group {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;

    >.custom-file, >.custom-select, >.form-control{
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        width: 1%;
        margin-bottom: 0;
    }
}

.input-group-append, .input-group-prepend {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;

    &.absolute{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}

.input-group-append {
    &.absolute{
        right: 0;
    }
}

.input-group-prepend {
    &.absolute{
        left: 0;
    }
}

.input-with-btn {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  align-content: center;

  label {
    flex-grow: 1;
  }

  .btn {
    border-radius: 0;
  }
}

.form-actions {
  text-align: right;
  margin-top: 20px;
  margin-bottom: 20px;
  
  .btn {
    min-width: 140px;
  }
}
