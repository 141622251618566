cx-amend-order-items {
  @extend %cx-amend-order-items !optional;

  .cx-info-container {
    .cx-label {
      font-size: 16px;
      font-weight: normal;
    }

    .cx-quantity {
      cx-item-counter {
        width: auto;
        display: inline-flex;

        button {
          margin-bottom: 0;
        }

        &.readonly {
          display: inline-flex;
          border: solid 1px var(--cx-color-light);
          border-radius: 4px;

          button {
            display: block;
          }

          input {
            cursor: default;
            border: solid 1px var(--cx-color-light);
            border-width: 0 1px;
      
            padding: 12px 9px;
            max-height: 46px;
            min-width: 48px;
            max-width: 78px;
          }
        }
      }

      @include media-breakpoint-up(md) {
        justify-content: flex-end;
      }
    }
  }

  .cx-quantity-container {
    @include media-breakpoint-down(md) {
      margin-top: 10px;

      .cx-label {
        font-weight: 600;
      }
    }
  }

  .cx-item-list-row {
    border-color: $border-menu;
  }
  
  .cx-price,
  .cx-request-qty {
    max-height: 48px;
  }

  .cx-price {
    .cx-value {
      font-size: 16px;
      font-weight: bold;
    }
    
    @include media-breakpoint-down(md) {
      padding-top: 4px;
      padding-bottom: 2px;
    }
  }

  .cx-name {
    min-width: auto;
  }

  .cx-item-list-header {
    font-size: 18px;
    color: inherit;
    text-transform: none;
    font-weight: 400;
  }
}
