$space: 0.5rem;
header {
  @extend %header !optional;
  background: rgba(46, 46, 46, 0) !important;
  color: var(--cx-color-secondary);
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  width: 100%;

  &.is-expanded .navigation {
    background-color:  rgba(46, 46, 46, 0);
  }

  app-custom-mini-cart {
    a {
      position: relative;
      cursor: pointer;
      min-height: 0;

      .count {
        color: var(--cx-color-secondary);
        font-size: 15px;
        position: absolute;
        top: -5px;
        left: 26px;
        bottom: 0;
        right: 0;
        text-align: center;
      }
    }
  }

  app-base-store:not(:empty) {
    span {
      margin-right: 15px;
      background-color: $primary;
      color: white;
      cursor: pointer;
      border-radius: 15px;
      padding: 4px 10px 2px;
    }

    @include media-breakpoint-down(md) {
      position: fixed;
      top: 65px;
    }
  }

  .header {
    position: relative;
    display: flex;
    //min-height: 125px; /*The default value was 75px;*/

    .HeaderText {
      text-align: center;
      background-color: $primary;
      color: white;
      position: fixed;
      top: 0;
      width: 100vw;
      left: 0;
      /*margin: -30px 0 6px -16px;*/
      padding: 0 16px;

      @include media-breakpoint-down(md) {
        position: relative;
      }

      @include media-breakpoint-up(lg) {
        width: calc(100vw);
        left: 50%;
        margin-left: -50vw;
        margin-top: 0;
      }

      /* @include media-breakpoint-down(md) {
         margin: -8px 0 0 -20px;
       }*/

      p {
        margin-bottom: 0;
      }
      a {
        color: white;
      }
    }

    @include media-breakpoint-up(lg) {
      padding: 0 10px 0 10px;
    }

    @include media-breakpoint-down(md) {
      .SiteLogo {
        padding-inline-start: 15px;
        padding-block: 15px;
      }

      .PreHeader {
        padding-right: 20px;
      }
    }

    > * {
      align-self: center;
      display: block;
      flex: none;
    }
  }

  &.sticky ~ .BottomHeaderSlot {
    margin-top: 111px;

    @include media-breakpoint-down(md) {
      margin-top: 75px;
    }
  }

  @include media-breakpoint-down(md) {
    &.is-expanded {
      z-index: 3;

      ::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: transparent;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: white;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: white;
        opacity: 0.7;
      }

      .SiteContext {
        visibility: hidden;
      }

      .SearchBox {
        visibility: hidden;
      }

      .SiteLinks {
        a {
          color: white;
        }
      }

      .SiteLogin {
        i {
          color: white;
          filter: brightness(40);
        }
      }

      .MiniCart {
        display: none;

        i {
          display: none;

          &:hover {
            opacity: 0.7;
          }
        }
      }

      cx-hamburger-menu {
        position: fixed;
        right: 20px;
        top: 12px;
        padding: 0;
      }

      .navigation {
        position: fixed;
        width: 85%;
        z-index: 3;
        left: 15%;
        max-height: calc(100vh - 75px);
        overflow: auto;
        top: 0;
        margin-top: 75px;
        padding-right: 16px;
      }

      .PreHeader {
        z-index: 4;
      }

      &:after {
        content: '';
        display: block;
        position: fixed;
        width: 85%;
        height: 100%;
        top: 0;
        left: 15%;
        background-color: black;
        z-index: 2;
        opacity: 0.9;
      }
    }
  }

  &:not(.is-expanded) {
    cx-page-layout.navigation {
      display: none;
    }
  }

  .SiteContext {
    app-custom-site-context-selector {
      &:last-child {
        display: none;
      }

      select {
        width: 38px;
        margin: 0;
        padding: 0 20px 0 0;
        background-color: transparent;
        z-index: 3;

        @include media-breakpoint-down(sm) {
          width: 39px;
          color: var(--cx-color-secondary);
        }
      }

      i {
        transform: scale(0.5) translateX(-10px);
      }
    }
  }
  .SiteContext,
  .SiteLinks {
    font-size: 0.75rem;
    align-items: center;

    a {
      color: var(--cx-color-light);
      padding-right: 10px;
    }

    > * {
      flex: none;
    }
  }

  .SiteLinks {
    --cx-display: block;

    cx-link {
      display: block;
      padding-inline-end: 10px;
    }
  }

  .SiteLogo {
    flex: 15.01%;

    img {
      width: 90px;
      margin: -10px auto 10px;
      display: flex;
      height: auto;
    }

    @include media-breakpoint-down(md) {
      img {
        margin: 0;
      }
    }

    @include media-breakpoint-down(sm) {
      img {
        width: 75px;
      }
    }
  }
  .hidden {
    display: none;
  }
  .searchbox {
    position: static;
    cursor: pointer;

    &--container {
      display: flex;
      justify-content: center;
      width: 100%;
      position: fixed;
      left: 0;
      top: 135px;
      background-color: transparent;
      border: none;
      z-index: 5;

      @include media-breakpoint-down(md) {
        top: 90px;
      }
      @include media-breakpoint-down(sm) {
        top: 64px;
      }
    }

    input {
      width: 100%;
      max-width: var(--cx-page-width-max);
      opacity: 0;
      border: 0 solid $border-menu;
      padding: 0 15px;
      height: 0;
      background-color: white;
      box-shadow: 0 5px 27px rgb(0 0 0 / 9%);
      transition: all .5s ease, height 1s, opacity 0.9s;
    }
  }

  /*
  .searchbox {
    position: static;
    padding-top: 4px;
    padding-bottom: 9px;

    input {
      width: 100%;
      position: absolute;
      left: 0;
      top: 130px;
      opacity: 0;
      border: 0 solid $border-menu;
      transition: height 1s, opacity 0.9s;
      padding: 0 15px;
      height: 0;
      background-color: white;
      box-shadow: 0 5px 27px rgb(0 0 0 / 9%);
      transition: all .5s ease;

      @include media-breakpoint-down(md) {
        top: 76px;
      }
    }
  }
*/

  .SearchBox {
    position: static;

    @include media-breakpoint-down(md) {
      margin-top: 0;
      margin-inline-end: 25px;
      margin-bottom: 0;
      margin-inline-start: auto;
    }
  }

  .NavigationBar {
    flex: 60%;
    margin-top: 10px;

    @include media-breakpoint-down(md) {
      background-color: transparent;

      cx-category-navigation {
        width: 100%;
        height: 400px;
        overflow-y: auto;
      }
    }

    a {
      padding: 20px 20px 22px 20px;

      .ellipsis {
        font-size: 16px;
      }

      .cx-icon {
        transform: scale(0.5);
      }
    }

    cx-category-navigation {
      cx-navigation-ui {
        nav {
          height: 68px;
          display: flex;

          .wrapper {
            background-color: white;
            border-bottom: none;
            top: 129.5px; /*The default value was 90px;*/
            width: 100vw;
            margin-left: 0 !important;
            padding: 20px;
            z-index: 999;

            @include media-breakpoint-down(md) {
              background-color: none !important;
              width: auto;
            }

            cx-generic-link a {
              color: var(--cx-color-secondary);

              &:hover {
                opacity: 0.7;
              }

              .ellipsis {
                font-size: 16px;
              }
            }

            .childs {
              nav {
                height: auto;
                @include media-breakpoint-up(xxl) {
                  padding-left: 400px;
                }
              }
            }

            @include media-breakpoint-up(xxl) {
              margin-left: calc((98vw - var(--cx-page-width-max)) / -2) !important;
            }

            @include media-breakpoint-down(md) {
              background-color: initial;
              padding: 0;

              cx-generic-link a {
                color: white;

                .ellipsis {
                  font-size: 16px;
                }
              }
            }
          }
        }

        nav.is-open {
          .wrapper {
            background: transparent !important;

            @include media-breakpoint-down(md) {
              transition: none;
            }

            .childs {
              nav {
                padding: 0 15px;
              }
            }
          }
        }
      }
    }
  }

  .navigation {
    h5 {
      .ellipsis {
        font-size: 16px;
      }

      .cx-icon {
        transform: scale(0.5);
      }
    }

    .SiteLogin {
      .logout-link {
        padding-left: 45px;
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
  }

  .cx-hamburger {
    display: block;
    padding: 5px 0 0 21px;

    @include media-breakpoint-down(md) {
      padding: 8px 0 0 21px;
    }
  }

  .cx-hamburger.is-active {
    @include media-breakpoint-down(md) {
      padding: 21px 0 0 21px;
    }
  }

  @include media-breakpoint-only(xs) {
    .SearchBox {
      margin-inline-start: auto;
    }
  }

  .MiniCart {
    a {
      text-decoration: none;
    }

    i {
      font-size: 22px;
      margin-left: 0;
      color: $secondary;
      padding-right: 15px;
    }
  }

  // non-desktop
  @include media-breakpoint-down(md) {
    .SiteContext,
    .SiteLinks,
    .SiteLogin {
      background-color: transparent;
      color: $secondary;
      margin: 0 10px;
      text-transform: uppercase;
      font-weight: 300;
      font-size: 14px;
    }

    .SiteLogin {
      margin: 0;

      app-custom-login {
        padding: 0 0 0 15px;

        & > a {
          padding-left: 15px;
        }
      }

      nav {
        padding: 0 0 0 15px;

        h5 {
          display: flex;
          justify-content: flex-start;
          padding: 0;
        }
      }
    }

    .SiteLinks,
    .SiteLogin {
      i {
        font-size: 18px;
        color: $secondary;
      }
    }

    .SiteContext {
      padding: 0px 0px 0px 20px;

      app-custom-site-context-selector {
        margin-inline-end: 0;
      }

      i {
        display: none;
        color: white;
      }
    }

    .SiteLinks {
      padding: 35px 20px 25px 20px;
    }

    .SiteLogin {
      i:after {
        content: attr(data-text);
        color: inherit;
        padding-left: 5px;
        font-weight: lighter;
        font-family: 'Teodor';
        font-size: 16px;
        position: relative;
        top: -2px;
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }
  }

  // desktop
  @include media-breakpoint-up(lg) {
    header {
      padding: 0 $space * 2;
      display: grid;
      grid-template-columns: auto auto 50px 50px 50px;
      grid-template-rows: 35px 75px;
    }

    .cx-hamburger {
      display: none;
    }

    .MiniCart {
      margin-left: 5px;
      margin-right: 30px;
      cx-icon {
        &::before {
          color: $secondary;
        }
      }
    }

    .SearchBox,
    .MiniCart,
    .SiteLogo,
    .SiteLogin {
      display: flex;

      i {
        color: $secondary;
        font-size: 20px;
      }
    }

    .SiteContext,
    .SiteLinks {
      display: flex;
      --cx-margin: #{$space} 8px;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1.5px;
    }

    .SiteContext {
      &:after {
        border-top: none;
      }
    }

    .SiteLinks {
      flex: 85%;
      margin-top: 35px; /*The default value was 14px;*/
      margin-bottom: 0;
      transition: all 0.5s ease;
    }

    .SiteLogin {
      padding: $space $space * 2;
      justify-content: flex-end;

      a:hover {
        text-decoration: none;
      }

      i:after {
        content: attr(data-text);
        color: inherit;
        padding-left: 5px;
        font-weight: lighter;
        font-family: 'Teodor';
        font-size: 16px;
        top: -3px;
        position: relative;
        @include media-breakpoint-down(lg) {
          display: none;
        }
      }

      .user-menu {
        padding: 5px 0 15px 0;
      }

      .HeaderLinks {
        .wrapper.wrapper-menu {
          top: 40px;
          padding: 30px 20px 20px;
          background-color: white;
          border-bottom: none;
          color: var(--cx-color-secondary);

          &:after {
            border-bottom: 15px solid white;
          }

          .childs {
            nav {
              cx-generic-link {
                a {
                  color: var(--cx-color-secondary);

                  &:hover {
                    color: #dddddd;
                  }
                }
              }
            }
          }

          .logout-link {
            padding: 5px 0;
          }
        }
      }
    }

    &.sticky {
      .SiteLogin {
        .HeaderLinks {
          .wrapper.wrapper-menu {
            top: 40px;
            padding: 30px 20px 20px;
            background-color: #303030;
            border-bottom: none;
            color: white;

            &:after {
              border-bottom: 15px solid #303030;
            }

            .childs {
              nav {
                cx-generic-link {
                  a {
                    color: white;

                    &:hover {
                      color: #dddddd;
                    }
                  }
                }
              }

              .logout-link {
                color: white;

                &:hover {
                  color: #dddddd;
                }
              }
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    &:not(.sticky).has-header-banner {
      .NavigationBar {
        cx-category-navigation {
          cx-navigation-ui {
            nav:hover {
              .wrapper {
                top: 129.5px;
              }
            }
          }
        }
      }
    }
  }
}

.cx-hamburger {
  padding: 5px 0px 0 35px;

  .hamburguer-box {
    width: 16px;
  }
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 16px;
  height: 1px;
  background-color: #691c32;
  border-radius: 0;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before {
  top: -5px;
}

.hamburger-inner::after {
  bottom: -5px;
}

.LandingPageTransparentHeaderTemplate header.sticky,
header.sticky {
  position: fixed;
  background: rgba(46, 46, 46, 0.7) !important;
  transition: all 0.5s ease;

  cx-category-navigation {
    cx-navigation-ui {
      nav {
        .wrapper {
          background-color: rgba(46, 46, 46, 0.7) !important;
          transition: all 0.5s ease;

          cx-generic-link a {
            color: white;
          }
        }
      }
    }
  }

  .SiteLogo {
    img {
      filter: brightness(9);
      transition: all 0.5s ease;
    }
  }

  .SiteLinks {
    margin-bottom: 0;
    transition: all 0.5s ease;
  }

  .NavigationBar {
    cx-navigation-ui {
      cx-generic-link {
        color: white;

        a {
          color: white;

          h5 {
            color: white;
          }
        }
      }
    }
  }

  .SearchBox {
    input {
      top: 110px;
      transition: all 0.5s ease;

      @include media-breakpoint-down(md) {
        top: 76px;
      }
    }

    .search {
      filter: brightness(9);
    }
  }

  .SiteLogin {
    color: white;

    i {
      filter: brightness(40);
    }

    .cx-icon {
      filter: brightness(9);
    }
  }

  .MiniCart {
    i,
    span {
      filter: brightness(9);
    }
  }

  .PreHeader {
    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: white;
    }
  }

  &.is-expanded {
    height: 74px;
  }

  .HeaderText {
    cx-paragraph {
      display: none;
    }
  }
}

header.sticky {
  .SiteContext {
    filter: brightness(9);
  }
}

.LandingPageTransparentHeaderTemplate {
  header {
    background-color: rgba(46, 46, 46, 0.7) !important;

    &.sticky ~ .BottomHeaderSlot {
      margin-top: 0;
    }

    .SiteLogin {
      i {
        color: white;
      }
    }

    &:not(.sticky) ~ cx-global-message {
      position: absolute;
      top: 140px;
      right: 0;
      left: 0;
      transition: all 0.5s ease;

      @include media-breakpoint-down(md) {
        top: 105px;
      }
    }

    .SiteLogin {
      .HeaderLinks {
        .wrapper.wrapper-menu {
          background-color: #303030;
          color: white;

          @include media-breakpoint-down(md) {
            background-color: transparent;
          }

          &:after {
            border-bottom: 15px solid #303030;
          }

          .childs {
            nav {
              cx-generic-link {
                a {
                  color: white;

                  &:hover {
                    color: #dddddd;
                  }
                }
              }
            }
          }
          .logout-link {
            color: white;

            &:hover {
              color: #dddddd;
            }
          }
        }
      }
    }

    .NavigationBar {
      cx-category-navigation {
        cx-navigation-ui {
          nav {
            .wrapper {
              background-color: rgba(46, 46, 46, 0.7) !important;

              @include media-breakpoint-down(md) {
                background-color: transparent !important;
              }

              cx-generic-link {
                a {
                  color: white;

                  h5 {
                    color: white;
                  }
                }
              }
            }
          }
        }
      }
    }

    .SiteContext {
      filter: brightness(9);

      app-custom-site-context-selector {
        select {
          color: #ffffff;
          text-shadow: none;
        }
      }
    }

    &.sticky {
      ~ cx-global-message {
        position: sticky;
        top: 101px;
        transition: all 0.5s ease;

        @include media-breakpoint-down(md) {
          top: 74px;
        }
      }
    }
  }
}

.MultiStepCheckoutSummaryPageTemplate header.sticky {
  position: relative;
  background: none !important;

  .SiteLogo {
    img {
      filter: none;
    }
  }

  .NavigationBar {
    cx-navigation-ui {
      nav {
        cx-generic-link {
          color: var(--cx-color-secondary);

          a {
            color: var(--cx-color-secondary);

            @include media-breakpoint-down(md) {
              color: white;
            }

            h5 {
              color: var(--cx-color-secondary);
            }
          }
        }
      }

      .childs {
        nav {
          cx-generic-link {
            a {
              color: var(--cx-color-secondary);

              @include media-breakpoint-down(md) {
                color: white;
              }
            }
          }
        }
      }
    }
  }

  .PreHeader {
    .hamburger-inner {
      background-color: var(--cx-color-secondary);
      &:before,
      &:after {
        background-color: var(--cx-color-secondary);
      }
    }
  }

  .SearchBox {
    .search {
      filter: none;
    }
  }

  .SiteLogin {
    i,
    cx-icon {
      filter: none;
    }
  }

  .MiniCart {
    i,
    span {
      filter: none;
    }
  }

  .SiteContext {
    filter: none;

    app-custom-site-context-selector {
      select {
        color: var(--cx-color-secondary);
      }
    }
  }

  .SiteLogin {
    .HeaderLinks {
      .wrapper.wrapper-menu {
        background-color: white;
        border-bottom: none;
        color: white;

        @include media-breakpoint-down(md) {
          background-color: transparent;
        }

        &:after {
          border-bottom: 15px solid white;
        }

        .childs {
          nav {
            cx-generic-link {
              a {
                color: var(--cx-color-secondary);

                @include media-breakpoint-down(md) {
                  color: white;
                }

                &:hover {
                  color: #dddddd;
                }
              }
            }
          }

          .logout-link {
            color: var(--cx-color-secondary);

            @include media-breakpoint-down(md) {
              color: white;
            }

            &:hover {
              color: #dddddd;
            }
          }
        }
      }
    }
  }
}

.MultiStepCheckoutSummaryPageTemplate header {
  &.sticky ~ .BottomHeaderSlot {
    margin-top: 0;
  }

  .SiteContext {
    app-custom-site-context-selector {
      select {
        color: var(--cx-color-secondary);

        @include media-breakpoint-down(md) {
          color: var(--cx-color-secondary);
        }
      }
    }
  }

  .NavigationBar {
    cx-navigation-ui {
      nav {
        .wrapper {
          background-color: white !important;

          @include media-breakpoint-down(md) {
            background-color: transparent;
          }
        }

        cx-generic-link {
          color: var(--cx-color-secondary);

          a {
            color: var(--cx-color-secondary);

            @include media-breakpoint-down(md) {
              color: white;
            }

            h5 {
              color: var(--cx-color-secondary);
            }
          }
        }
      }

      nav.is-open {
        .wrapper {
          @include media-breakpoint-down(md) {
            background-color: transparent !important;
          }
        }
      }

      .childs {
        nav {
          cx-generic-link {
            a {
              color: var(--cx-color-secondary);

              @include media-breakpoint-down(md) {
                color: white;
              }
            }
          }
        }
      }
    }
  }
}

cx-global-message {
  .alert {
    .close {
      right: 1rem;
    }
  }
}
progress-bar {
  @extend %progress-bar !optional;
}
progress-bar-loading {
  @extend %progress-bar-loading !optional;
  @keyframes loading {
    0% {
      margin-inline-start: 0px;
      margin-inline-end: 100%;
    }
    50% {
      margin-inline-start: 25%;
      margin-inline-end: 0%;
    }
    100% {
      margin-inline-start: 100%;
      margin-inline-end: 0;
    }
  }

  &:after {
    animation: loading 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
}

.revolution {
  &.has-header-banner:not(.sticky-banner) {
    progress-bar {
      &:before {
        background-color: var(--cx-color-light);
      }

      &:after {
        background-color: $primary;
      }
    }
    progress-bar-loading {
      &:after {
        background-color: var(--cx-color-dark);
        animation: loading 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
      }
    }
  }
}
