app-custom-paragraph-column-info {
  display: block;

  p {
    width: 100%;
  }

  > .container {
    padding: 30px 15px;
    @include media-breakpoint-up(lg) {
      padding: 50px;
    }

    .full-page-width{
      width: 100%;
      min-width: unset;
      max-width: unset;
      margin-left: unset;
      margin-right: unset;
    }

    .title {
      margin-bottom: 36px;
      color: var(--cx-color-secondary);
      font-size: 32px;
      font-weight: bold;
      display: block;
    }

    strong, bold {
      color: var(--cx-color-secondary);
    }
  }

  .content {
    > .row {
      > .col {
        flex: auto;
        min-width: 100%;
        @include media-breakpoint-up(md) {
          max-width: 50%;
          min-width: unset;
        }

        @include media-breakpoint-up(lg) {
          max-width: 33%;
        }
      }
    }
  }

  app-custom-paragraph-column-with-expandable-info {
    padding: 0;

    .container {
      padding: 0;
    }

    .title {
      font-weight: 600;
      font-size: 18px;
      color: var(--cx-color-primary);
      letter-spacing: 2.5px;
      line-height: 20px;
      text-transform: uppercase;
    }
  }
}
