app-custom-product-intro {
  @include media-breakpoint-up(lg) {
    padding-inline-end: 20px;
    padding-inline-start: 20px;
  }

  .title {
    border-bottom: $pdpTitleBorderBottom;
    font-size: $pdpTitleSize;
    margin-bottom: 0;
    text-transform: $pdpTitleTextTransform;
  }

  .cx-action-link {
    text-decoration: none;
    padding: 0;
    text-transform: none;
  }

  .count {
    padding-left: 4px;
    text-align: right;
    vertical-align: text-top
  }
  
  .code {
    @include type('7');
    color: var(--cx-color-secondary);
    margin: 0 0 20px 0;
  }

  .price {
    font-size: $pdPriceSize;
    font-weight: 600;
  }
  
  @include media-breakpoint-down(lg) {
    margin-top: 30px;
  }

  app-custom-paypal-installments {
    .installments-container {
      margin-top: 20px;
    }
  }
}
