app-custom-hover-aware-image {
  position: relative;

  cx-media {
    transition: opacity .8s;
    -webkit-transition: opacity .8s;
    opacity: 1;
    top: 0;
    left: 0;
    mix-blend-mode: multiply;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &:first-child {
      opacity: 1;
    }

    &:last-child {
      position: absolute;
      opacity: 0;
      padding: 15px;
    }
  }

  @include media-breakpoint-up(lg) {
    &:hover cx-media {
      &:first-child {
        opacity: 0;
      }
  
      &:last-child {
        position: absolute;
        opacity: 1;
      }
    }
  }
}
