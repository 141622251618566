cx-return-request-items {
  @extend %cx-return-request-items !optional;

  .cx-image-container {
    width: 25%;
    max-width: 160px;

    a {
      display: block;
      padding: 0 30px;
      
      @include media-breakpoint-down(md) {
        padding: 0 15px;
      }
    }
  }

  .cx-info {
    width: 75%;
  }

  .cx-info-container {
    a {
      color: var(--cx-color-text);
      text-decoration: none;

      &:hover {
        color: var(--cx-color-primary);
      }
    }
  }

  .cx-item-list-row {
    border-color: $border-menu;

    .cx-quantity {
      justify-content: flex-start;
    }

    .cx-total {
      justify-content: flex-start;

      .cx-value {
        font-size: 20px;
        font-weight: bold;
      }

      @include media-breakpoint-up(lg) {
        justify-content: flex-end;
        margin-top: 28px;
      }
    }

    .cx-price {
      justify-content: flex-start;
      padding: 4px 0 2px;

      .cx-value {
        font-size: 16px;
        font-weight: bold;
      }

      @include media-breakpoint-up(lg) {
        margin-top: -48px;
      }
    }
  }
}
