app-custom-giftbox-item {
  cx-product-carousel & {
    &:not(.no-stock):hover {
      cursor: pointer;

      .btn.btn-primary {
        @include cx-darken(88);
      }
    }

    &.no-stock {
      a {
        pointer-events: none;
        opacity: 0.7;

        &:hover,
        &:focus {
          h4 {
          color: inherit;
          }
        }
      }
    }
  }
}
