cx-product-reviews {
  @extend %cx-product-reviews !optional;

  .header {
    border-bottom: none;
    margin-bottom: 0;
    margin-top: 12px;

    h3 {
      align-self: center;
    }

    + .review {
      border-top: 1px solid var(--cx-color-light);
      padding-top: 35px;
      margin-top: 5px;
    }
  }
}
