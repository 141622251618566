cx-add-to-wishlist {
  @extend %cx-add-to-wishlist !optional;

  .btn {
    color: var(--cx-color-primary);
    padding-left: 0;
    padding-right: 0;

    &,
    &:hover {
      text-decoration: none;
    }
  }
}
