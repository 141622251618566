cx-page-layout {
    app-custom-box-link-image {
      .title {
        font-size: 32px;
        color: $secondary;
        text-align: center;
        letter-spacing: 1px;
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-weight: bold;
        display: block;

        &:empty {
          display: none;
        }
      }

      .content {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-flow: row;
        position: relative;
        padding: 0;
        grid-gap: 2rem;
        padding-bottom: 1rem;

        @include media-breakpoint-up(md) {
            grid-auto-columns: 1fr;
            grid-template-columns: 1fr;
            grid-auto-flow: column;
            grid-gap: 15px;
            padding-bottom: 1rem;
        }
      }

      &.alternative {
        .content {
          @include media-breakpoint-up(md) {
            height: 250px;
            height: auto;
            padding-bottom: 1rem;
            grid-gap: 15px;
          }

          @include media-breakpoint-down(sm) {
            grid-gap: 2rem;
          }

          app-custom-box-link-image-item {
            padding-bottom: 100%;
            border: none;

            @include media-breakpoint-up(md) {
              min-height: unset;
              height: 250px;
            }

            cx-media, > cx-media.is-missing {
              @include media-breakpoint-down(sm) {
                img {
                  position: absolute;
                }
              }
            }

            button,
            cx-generic-link {
              @include media-breakpoint-down(sm) {
                height: 100%;
              }
            }

            button,
            cx-generic-link a {
              @include media-breakpoint-up(md) {
                height: 250px;
                max-height: 430px;
                padding-bottom: 100%;
              }

              @include media-breakpoint-down(sm) {
                height: 330px;
                max-height: 475px;
                padding-bottom: 100%;
                width: 100%;
                position: absolute;
                top: 0;
              }

              &:hover,
              &:focus,
              &:active {
                @include media-breakpoint-down(sm) {
                  max-width: 100%;
                }
              }
            }
          }
        }
      }
    }
}
