cx-return-request-totals {
  @extend %cx-return-request-totals !optional;

  padding-left: 0;
  padding-right: 0;

  .cx-summary {
    padding: 30px;
    text-transform: uppercase;
    background-color: var(--cx-color-background);

    h4 {
      margin: 0 0 5px;
    }
  }

  .cx-nav {
    text-align: right;
    padding: 1rem 0 2rem;
    margin-top: 30px;

    div:empty {
      display: none;
    }

    .btn {
      @include media-breakpoint-up(md) {
        margin-bottom: 12px;

        +.btn {
          margin-left: 16px;
        }
      }

      @include media-breakpoint-down(sm) {
        margin: 0 0 16px;
        width: 100%;
        display: block;
      }
    }
  }
}
