cx-page-layout {
  app-info {
    background: $compInfoBackground;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;

    .title {
      color: var(--cx-color-secondary);
      text-align: center;
      display: $compInfoTitleDisplay;
      margin-top: 1vw;
      margin-bottom: 25px;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 4px;
    }

    .info-title {
      font-size: 14px;
      letter-spacing: 0.2px;
    }

    p {
      font-size: 16px;
    }

    .info-wrapper {
      display: grid;
      grid-template-columns: 1fr;
      grid-auto-flow: row;
      position: relative;
      padding: 0;
      text-align: center;
      align-items: baseline;

      @include media-breakpoint-up(md) {
        grid-auto-columns: 1fr;
        grid-template-columns: 1fr;
        grid-auto-flow: column;
      }

      @include media-breakpoint-down(md) {
        grid-template-columns: $compInfoGridMobile;
      }
    }
  }
}
