html[dir='rtl'] cx-breadcrumb nav span:not(:last-child):after {
  content: '/';
}

cx-breadcrumb {
  @extend %cx-breadcrumb !optional;
  @extend .container !optional;

  flex-direction: row;
  background-color: var(--cx-color-transparent);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  flex-wrap: wrap;

  > span {
    padding: 5px 10px 5px 5px;
    font-size: 10px;
    text-transform: uppercase;
    margin-bottom: 0;
    line-height: 1.6;
    font-weight: normal;
    color: $color-theme-2;
  }

  nav {
    font-size: 10px;
    justify-content: left;

    span {
      color:$color-theme-1;

      &:not(:last-child), & {
        &:after {
          content: '/';
        }
      }

      a {
        text-transform: uppercase;
        font-weight: 500;
        color: $color-theme-1;
        padding: 4px;

        &:hover {
          color: $color-theme-3;
          text-decoration: none;
        }
      }

      &:first-child {
        a {
          padding-left: 0;
        }
      }
    }
  }
}
