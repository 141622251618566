app-custom-product-summary {
  display: flex;
  flex-direction: column;
  flex-grow: 0;

  @include media-breakpoint-down(md) {
    padding-top: 1.5em;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 20px;
    padding-inline-end: 20px;
    padding-bottom: 0px;
    padding-inline-start: 20px;
  }

  .summary {
    @include type('4');
    font-weight: normal;
  }

  .brand {
    text-decoration: none;
    font-weight: bold;
  }

  .brands {
    margin-bottom: 20px;
    list-style: none;
    padding-left: 0;
    display: $pdpBrandsDisplay;
  }
}
