.bg-alternative {
  background-color: $alternative;
}

.bg-highlight {
  background-color: $highlight;
}

ngx-charts-chart {
  .ngx-charts {
    .grid-panel {
      &.odd {
        rect {
          fill: none;
        }
      }
    }
  }

  .chart-legend {
    .legend-labels {
      background: transparent;
    }

    .legend-label {
      margin: 8px 0;
    }

    .legend-label-text {
      width: 42px;
      overflow: visible;
      overflow-wrap: normal;
      white-space: normal;
      text-align: right;
    }

    .legend-title {
      display: block;
      text-align: right;
      margin-right: 8px;
    }
  }

  ngx-charts-legend-entry {
    > span:first-of-type {
      display: flex;
      justify-content: flex-start;
      flex-direction: row-reverse;

      .legend-label-color {
        margin-left: 4px;
        margin-right: 0;
        height: auto;
      }
    }
  }
}

ngx-charts-bar-vertical-2d {
  min-height: 400px;
  height: 75vh;
  max-height: 700px;
}
