app-custom-my-friends-list {
  .custom-list-filter {
    margin-top: 40px;
  }

  .table-main{
    margin-top: 40px;

    th{
      color: var(--cx-color-text);
      font-size: 20px;
      font-weight: inherit;
    }
  }
}
