cx-page-layout.LoginPageTemplate {

  cx-page-slot {
    padding-top: 0;

    h2 {
      font-weight: 600;
      margin-bottom: 20px;
    }
  }

  app-custom-page-top-title {
    margin-bottom: 40px;
    max-width: 100%;
  }

  app-custom-login-form,
  app-custom-login-register {
    max-width: var(--cx-max-width, 66%);

    @include media-breakpoint-up(md) {
      .form-group {
        label {
          display: flex;
          align-items: center;
        }

        .label-content {
          flex-basis: 40%;
          margin-bottom: 0;
        }

        input.form-control {
          margin-bottom: 0;
        }
      }

      #loginFormSubmit{
        float: right;
        flex: none;
      }

      cx-checkout-login .btn-send {
        float: right;
        margin-bottom: 40px;
      }

      .register-reset-password a {
        color: var(--cx-color-secondary);
        font-size: 18px;
      }

      .register-guest-checkout + .register-new {
        clear: both;
      }
    }

    @include media-breakpoint-down(sm) {
      .register-reset-password a {
        padding: 0;
        margin-top: 16px;
        min-height: auto;
        color: var(--cx-color-secondary);
        font-size: 18px;
      }
    }
  }
}

a.btn-login,
button.btn-login,
app-custom-login-register a:not(.btn-link) {
  @extend .btn;
  @extend .btn-send;
}

.register {
  
  .register-new,
  .register-guest-checkout {
    border-top: 1px solid #eae9e9;
    margin-top: 40px;
    padding-top: 40px;
  } 
  
  .register-guest-checkout {

  } 

  .register-reset-password {
    a {
      @extend .link-alt !optional;
    }
  }
}
