app-custom-mini-cart-preview {

  .cx-mini-cart-preview-content {
    background-color: white;
    display: block;
    position: fixed;
    width: 500px; 
    max-width: 100%;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 30;
    overflow-y: auto;
    box-shadow: 0 5px 27px rgb(0 0 0 / 9%);
    padding: 50px 30px 0;
    overflow: inherit;
    animation: appearFromLRight .3s;
  }

  .cx-mini-cart-preview-backdrop {
    position: fixed;
    width: 100vw;
    background: black;
    height: 100%;
    top: 0;
    right: 0;
    transition: opactiy .3s ease;
    z-index: 30;
  }

  .cx-mini-cart-preview-body {
    max-height: calc(100vh - 250px);
    overflow: auto;
    display: inline-block;
    border-bottom: thin solid $border-menu;
    width: 100%;
  }

  .cx-mini-cart-preview-backdrop {
    opacity: .5;
    animation: fadeInMedium .3s;
  }

  cx-cart-item-list {
    .cx-item-list-row:last-child {
      border-bottom: none;
    }

    .cx-total {
      display: none;
    }
  }

  .cx-cart-preview-empty {
    padding: 15px 0;
    font-size: 18px;
    text-align: center;
    font-weight: 900;
    margin-top: 40px;
  }

  > a.btn:last-child {
    margin-top: 20px;
  }

  .cx-cart-preview-total {
    font-size: 32px;
    text-align: right;
    margin-bottom: 20px;
    color: var(--cx-color-text);
  }

  .cx-cart-preview-empty,
  .cx-info-price-slim-info,
  .cx-info-price-slim-price {
    color: var(--cx-color-text);    
  }

  button.close {
    position: absolute;

    cx-icon {
      font-size: 26px;
      text-shadow: none;
    }

    @include media-breakpoint-up(sm) {
      background: var(--cx-color-primary);
      left: -26px;
      opacity: 1;
      border-radius: 50%;
      width: 52px;
      height: 52px;
      top: 30px;
      transition: background .3s ease;
  
      cx-icon {  
        &:before {
          color: white;
        }
      }
  
      &:not(.disabled):not(:disabled) {
        &:hover,
        &:focus {
          background: var(--cx-color-secondary);
          opacity: 1;
        }
      }
    }
    
    @include media-breakpoint-down(xs) {
      top: 12px;
      right: 26px;
    }
  }
}
