app-custom-unit-selector {
  flex-grow: 1;
  margin-right: 16px;

  @include media-breakpoint-down(xs) {
    margin-right: unset;
  }

  .unit-selector-control {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    min-height: 48px;

    > button {
      cursor: pointer;
      width: 48px;
      padding-left: 10px;
      padding-right: 10px;

      [class^=icon-], [class*=" icon-"] {
        font-weight: bold;
        font-size: 25px;
        line-height: 0.5;
        vertical-align: middle;
      }
    }
  }

  .options-wrapper {
    position: relative;
    width: 100%;
  }

  .options-container {
    position: absolute;
    top: 0;
    width: 100%;
    background-color: white;
    z-index: 10;

    .option {
      cursor: pointer;
      max-height: 48px;
      overflow: hidden;
      transition: max-height 0.3s ease, background-color 0.1s ease;

      &:hover, &.selected {
        background-color: $background;
      }
    }

    &.collapsed {
      .option {
        &:not(.selected) {
          max-height: 0;
        }

        &.selected {
          background-color: unset;
        }
      }
    }
  }

  app-custom-unit-display.unit {
    margin-right: 0;
  }
}
