footer{
  --cx-color-dark: var(--cx-color-background);
  --cx-color: var(--cx-color-secondary);
  background-color: var(--cx-background-color, var(--cx-color-dark));
  color: var(--cx-color, var(--cx-color-inverse));

  cx-page-layout{
    &.footer{
      display: flex;
      flex-wrap: wrap;
      @extend .container !optional;

      cx-footer-navigation {
        @extend %cx-footer-navigation !optional;
        background-color: transparent;

        cx-navigation-ui {
          /*@extend .justify-content-start !optional;*/
          @extend .align-items-start !optional;
          justify-content: center !important;
          /*align-items: center !important;
          padding: 3vw;*/

          > nav {
            margin: 3vw;
          }

          @include media-breakpoint-between(md, lg) {
            > nav {
              margin-left: 10px;
              margin-right: 10px;
            }
          }

          @include media-breakpoint-up(sm) {
            .ellipsis {
              display: block;
              line-height: 1em;
              padding-bottom: 10px;
            }

            h5 .ellipsis {
              white-space: nowrap;
            }
          }

          span {
            margin-bottom: 0;
          }

          .ellipsis {
            font-weight: var(--cx-font-weight);
            text-transform: none;
          }

          h5 .ellipsis {
            font-weight: var(--cx-font-weight-bold);
            text-transform: uppercase;
          }
        }

        nav {
          color: var(--cx-color-secondary);

          a {
            @include link-anim-ellipsis;
            text-decoration: none !important;
            text-transform: none !important;
            font-size: 16px;
            line-height: 25px;
      
            h5 {
              margin-bottom: 0;
            }

            .ellipsis {
              display: inline-block;
              line-height: 1.6;
              padding-bottom: 12px;
            }
      
            &:hover {
              color:inherit;
            }
          }
        }
      }

      cx-page-slot{
        &.Footer{
          > {
            cx-footer-navigation {
              cx-navigation-ui > nav {
                > cx-generic-link a {
                  display: block;
                  padding: 0;

                  .ellipsis {
                    padding-bottom: 4px;
                  }
                }

                .childs {
                  .ellipsis {
                    padding-bottom: 0;
                  }
                }
              }

              ~ cx-paragraph {
                padding: 3vw;
                color: var(--cx-color-secondary);

                a {
                  @include link-anim-ellipsis;
                  text-decoration: none !important;
                  text-transform: none !important;
                  font-size: 16px;
                  line-height: 25px;
                  color: currentColor;
            
                  h5 {
                    margin-bottom: 0;
                    font-weight: var(--cx-font-weight-bold);
                  }

                  .ellipsis {
                    display: inline-block;
                    line-height: 1.6;
                    padding-bottom: 4px;
                    font-size: var(--cx-font-size, 1rem);
                  }
            
                  &:hover {
                    color:inherit;
                  }
                }

                ul {
                  list-style: none;
                  padding: 0;
                }
              }
            }

            @include media-breakpoint-up(md) {
              cx-footer-navigation {
                flex: 0 0 50%;

                cx-navigation-ui > nav:first-child .childs {
                  column-count: 1;
                }

                ~ cx-paragraph {
                  flex: 0 0 25%;
                }
              }
            }
          }
        }
        &.FooterTop {
          padding: 10px 3vw 40px;
          display: block;

          cx-paragraph {
            margin-bottom: 12px;
            color: var(--cx-color-secondary);
          }

          cx-generic-link {
            display: flex;
            height: 100%;
            object-fit: contain;
          }

          app-custom-generic-link, .img-payment-brand {
            display: flex;
            float: left;
            margin: 15px 15px 10px 10px;

            cx-generic-link {
              display: flex;
              align-items: center;
            }

            img {
              width: 100%;
              height: 100%;
              max-width: 65px;
              max-height: 65px;
            }
          }
        }

        &.FooterLeftSide{
          padding-bottom: 3vw;
          @include media-breakpoint-up(lg) {
            flex: 0 0 58.3333333333%;
            max-width: 58.3333333333%;
          }

          cx-navigation-ui {
            margin: .25vw .25vw .25vw 3vw;
            justify-content: flex-start!important;

            > nav {
              justify-content: flex-end;
              margin: 0;

              &:not(:first-child) {
                @include media-breakpoint-up(md) {
                  padding-left: 10px;
                }
              }

              a{
                padding-top: 5px;
                padding-bottom: 5px;
                padding-inline-end: 15px;
              }
            }
          }
        }
        &.FooterRightSide{
          padding: 10px 3vw 40px;
          display: block;

          @include media-breakpoint-up(lg) {
            flex: 0 0 41.6666666667%;
            max-width: 41.6666666667%;
            padding: 35px 2vw 0 2vw;
            display: flex;
            justify-content: flex-end;
          }

          cx-paragraph {
            margin-bottom: 12px;
            color: var(--cx-color-secondary);
          }

          cx-generic-link{
            display: flex;
            height: 100%;
            object-fit: contain;
          }

          app-custom-generic-link, .img-payment-brand{
            display: flex;
            float: left;
            margin: 15px 5px 10px 5px;
            flex: 0 1 auto;

            cx-generic-link {
              display: flex;
              align-items: center;
            }

            img {
              width: 100%;
              height: 100%;
              max-width: 65px;
              height: 29px;
            }
          }
        }

        &.FooterBottom{
          display: block;
        }

        .img-payment-brand{
          width: auto;
          height: 25px;
        }
      }
    }
  }
}

app-custom-newsletter-dialog {
  .modal-dialog {
    .modal-content {
      background: rgb(231, 232, 224);
    }
  }
}