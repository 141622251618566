app-custom-paragraph-column-with-expandable-info {
  display: block;

  p {
    width: 100%;
    line-height: 22px;
    color: $color-extra-5;
  }

  .container {
    padding: 30px 15px;
    display: block;
    @include media-breakpoint-up(lg) {
      padding: 50px;
    }

    .title {
      font-family: $font-family-custom1;
      letter-spacing: 1px;
      line-height: 38px;
      margin-bottom: 36px;
      color: var(--cx-color-secondary);
      font-size: 36px;
      font-weight: bold;
      display: block;
    }

    strong, bold {
      color: var(--cx-color-secondary);
    }

    .expandable-info {
      display: block;
    }

    .btn-read-more {
      cx-icon {
        font-size: 12px;
      }
    }

    .download {
      padding-bottom: 0;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
