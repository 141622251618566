app-custom-category-summary {
  margin-top: 50px;
  word-break: break-word;

  .title {
    font-size: 35px; 
    text-transform: uppercase;
    font-weight: bold;
  }

  .description {
    margin: 20px 0 8px;
    font-size: 18px;
    line-height: 1.4;
  }

  .image {
    img {
      width: 100%;
    }
  }
}