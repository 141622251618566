cx-added-to-cart-dialog {
  @extend %cx-added-to-cart-dialog !optional;

  .cx-dialog-body {
    padding: 1rem 0.2rem;
  }

  .cx-dialog-row {
    padding-inline-start: 0.875rem;
  }

  .cx-dialog-item .cx-compact .cx-info {
    padding: 0;
  }
  .cx-dialog-buttons {
    a:first-child {
      margin-bottom: 10px;
    }
  }

  cx-cart-item {
    @extend %cx-cart-item !optional;

    .cx-info {
      display: grid;
      position: relative;
      grid-template-columns: 84px auto;
      flex-grow: 1;

      .cx-name {
        min-width: auto;
        padding-right: 40px;
        font-size: 18px;
      }

      .cx-image-container {
        grid-column: 1;
        grid-row: 1 / span 4;
      }

      cx-media img {
        max-width: 75px;
      }

      cx-promotions {
        grid-column: 1/span 2;
        grid-row: 5;
      }

      .cx-total {
        grid-column: 2;
        grid-row: 2;
        justify-content: start;

        .cx-value {
          font-size: 20px;
          font-weight: bold;
        }
      }

      .cx-info-price {
        grid-column: 1 / span 2;
        display: flex;
        align-items: center;
        margin-top: 20px;

        .cx-quantity {
          padding: 0;
        }
      }

      .cx-info-container {
        grid-column: 2;
        grid-row: 1;
        align-self: start;
        margin-bottom: 0.25rem;
      }

      .cx-remove-btn {
        position: absolute;
        top: -6px;
        right: -6px;
        padding: 8px 12px;

        .cx-action-link {
          text-decoration: none;
        }
      }

      app-custom-unit-display.unit {
        min-height: 43px;

        .unit-discount,
        .unit-price-item,
        .unit-price-old {
          display: none;
        }
      }
    }
  }
}
