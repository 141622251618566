cx-page-layout {
  app-custom-box-link-image-item {
    overflow: hidden;
    position: relative;
    min-height: 300px;
    @extend .align-items-center !optional;
    @extend .justify-content-center !optional;

    cx-media,
    > cx-media.is-missing {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .wrapper-text,
    .wrapper-link {
      width: 100%;
    }

    .wrapper-text {
      display: flex;
      justify-content: center;
      font-weight: bold;
      position: absolute;
      bottom: 40px;
    }

    button,
    cx-generic-link {
      width: 100%;
      border: none;
    }

    button,
    cx-generic-link a {
      cursor: pointer;
      background-color: rgba(0, 0, 0, .5);
      display: block;
      position: relative;
      font-family: $font-family-custom1;
      letter-spacing: 5.47px;
      font-size: 28px;
      line-height: 33px;
      text-align: center;
      margin: 0 auto;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, .45);
      transition: background-color .15s ease-in;
      line-height: 33px;
      height: 298px;

      h3 {
        font-size: 28px;
        font-family: inherit;
        line-height: 33px;
        margin: 0;
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        padding: 0 70px;
        color: white;
      }

      &:hover,
      &:focus,
      &:active {
        color: white;
        text-decoration: none;
      }
    }

    p {
      position: relative;
      font-size: 18px;
      color: white;
    }

    &.alternate {
      // ocultar título hasta hacer hover
      button,
      cx-generic-link a {
        background-color: transparent;

        h3 {
          opacity: 0;
          transition: opacity .15s ease-in;
        }

        &:hover,
        &:focus,
        &:active {
          background-color: rgba(0, 0, 0, .5);

          h3 {
            opacity: 1;
          }
        }
      }
    }
  }
}


