.MultiStepCheckoutSummaryPageTemplate {
    cx-page-layout.MultiStepCheckoutSummaryPageTemplate {
        @extend %MultiStepCheckoutSummaryPageTemplate !optional;
        padding: 30px;
    }

    padding: 0;
    cx-page-slot.SideContent {
        cx-checkout-order-summary {
            @extend cx-cart-totals !optional;
        }
    }

    cx-page-layout {
        cx-page-slot.SideContent {
            cx-checkout-order-summary {
                @extend cx-cart-totals !optional;
                cx-order-summary {
                    padding: 0;
                }
            }
        }
    }
    cx-place-order .cx-place-order-form {
        label {
            font-size: 15px;
        }
        .form-check-input {
            top: 10px;
        }
    }
    .apply-coupon-button {
        margin: 0;
    }
    .cx-place-order-form {
        margin: 30px auto;
        text-align: center;

        @include media-breakpoint-down(md) {
          padding: 0 1.3rem;
        }

        span {
            text-transform: uppercase;
            color: var(--cx-color-light);
        }

      .form-group {
        @include media-breakpoint-up(md) {
          display: flex;
        }
      }
    }

    #enabled-paypal {
      width: 100%;
      height: 55px;
      position: absolute;
      z-index: 101;
      cursor: pointer;
    }
    .cx-checkout-body {
        margin-top: 30px;
    }
    .wrapper-icon-rotate {
        display: inline-block;
        margin-left: 5px;
    }
    .wrapper-payment-types {
        margin-top: 30px;
        background-color: $grey-light;
        border: 1px solid $border-menu;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        label {
            text-transform: uppercase;
            color: $color-extra-5;
            min-width: 100%;
        }
    }
    .cx-list-media {
        flex-wrap: wrap;
        h1 {
            margin-bottom: 30px;
            border: none;
            color: black;
            font-weight: bold;
            letter-spacing: 0.3px;
            font-size: 30px;
        }
        text-transform: uppercase;
        color: $color-extra-6;
        &.is-active {
            color: black;
        }
    }
    cx-review-submit .cx-review-summary {
        margin-bottom: 50px;
    }
    cx-cart-item-list {
        .cx-item-list-header {
            display: none;
        }
        margin-top: 30px;
        .cx-info {
            .cx-info-container {
                width: 100%;
            }
            .cx-code {
                display: none;
            }
            .cx-quantity {
                .cx-label {
                    display: block !important;
                    font-size: 17px;
                    font-weight: normal;
                }
                margin-top: 20px;
            }
            .cx-price {
                margin-top: 10px;
                .cx-value {
                    font-size: 18px;
                }
            }
            .cx-price,
            .cx-quantity,
            .cx-info-container > div {
                width: 100%;
                max-width: 80%;
                flex: 0 0 80%;
                justify-content: flex-start;
                padding: 0;
            }
        }
    }
    app-custom-gift-cards-details {
        .wrapper-gift {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            .details {
                .uppercase {
                    text-transform: uppercase;
                }
                margin-left: 15px;
                display: flex;
                flex-direction: column;
            }
        }
        cx-media img {
            max-width: 150px;
        }
        span {
            color: $color-extra-5;
        }
    }
    h3.cx-checkout-title {
        margin-top: 30px;
        color: $color-extra-5;
        text-transform: uppercase;
        padding: 0;
        background-color: transparent;
        font-size: var(--cx-font-size, 1.375rem);
    }
    h4.cx-checkout-title {
        margin-top: 30px;
        color: $color-extra-5;
        text-transform: uppercase;
    }
    .cx-delivery-note {
        .form-check input + .form-check-label {
            color: $color-extra-5;
        }
        text-transform: uppercase;
    }
    .cx-delivery-mode {
        span {
            font-size: 16px;
            color: $color-extra-5;
        }
        font-size: 20px;
    }
    .cx-checkout-btns {
        margin: 50px 0;
        justify-content: flex-end;

      .order-button {
        @include media-breakpoint-up(md) {
          display: flex;
          justify-content: flex-end;
          padding-right: 0;
        }

        .credit-button {
          width: 100%;
        }
        app-custom-paypal-button {
          width: 100%;
        }
      }

    }
    cx-checkout-progress {
        margin-bottom: 30px;
    }
    .form-wrapper-cards {
      position: relative;

      .cx-spinner {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;

        &:not([hidden]) ~ div {
          opacity: .5;
        }
      }
    }
    .wrapper-cards {
        cx-media {
            max-width: 150px;
            min-width: 150px;
            width: 150px;
        }

      text-align: center;
      border: 2px solid transparent;

      @include media-breakpoint-up(lg) {
        margin-bottom: 30px;
        display: flex;
      }

      .gift-card {
        border: 2px solid transparent;

        span {
          display: block;
          margin-top: 5px;
          color: var(--cx-color-light);
        }

        &.selected {
          border-color: var(--cx-color-primary);
        }

        padding: 10px;
        position: relative;
        margin-right: 15px;

        a {
          cursor: pointer;
        }
      }
    }

    @include media-breakpoint-down(md) {
        cx-review-submit .cx-review-summary {
            background-color: white;
            .cx-review-summary-card {
                background-color: $background;
                border: none;
            }
        }
        cx-cart-item {
            .cx-price {
                .cx-label {
                    display: none !important;
                }
            }
            .cx-image-container {
                padding: 0;
                max-width: 100%;
            }
            .cx-info {
                flex: auto;
                padding: 0;
                width: 100%;
                .cx-info-container {
                    width: auto;
                    align-items: flex-start;
                }
            }
        }
        cx-page-layout cx-page-slot.SideContent cx-checkout-order-summary cx-order-summary {
            padding: 30px;
        }
        cx-review-submit {
            background-color: white;
        }
        cx-checkout-progress-mobile-top .cx-media .cx-list-media {
            padding: 0;
        }
        cx-page-slot.SideContent cx-checkout-order-summary {
            width: 100%;
        }

        .shipping-method {
            padding: 20px 45px;
        }
        cx-checkout-progress-mobile-top .cx-media .cx-list-media {
            display: none;
            &.is-active {
                border-bottom: 1px solid var(--cx-color-light);
                border-top: none;
                display: flex;
            }
            &.title {
                border: none;
            }
            border-top: 1px solid var(--cx-color-light);
        }
        app-custom-gift-cards-details {
            .wrapper-gift .details {
                margin-left: 0;
                margin-top: 15px;
            }
        }
        app-custom-gift-card form {
          position: relative;
          min-height: 190px;
        }
        .wrapper-cards {
          position: absolute;
          overflow-x: auto;
          width: 100%;
          height: 190px;
          white-space: nowrap;
          transform: translateX(-50%);
          top: 36px;
          left: 50%;

          > * {
            display: inline-block;
          }

          + * {
            margin-top: 220px;
          }
        }
        .cx-checkout-btns {
          margin: 30px 0;

          div:not(:first-child) .btn {
            margin-top: 30px;
          }

        }
    }
    @include media-breakpoint-down(sm) {
      .wrapper-cards {
        width: 100vw;
      }
      cx-cart-item {
        .row {
          flex-wrap: nowrap;
        }
      }
    }

  app-custom-paypal-button.disabled {
    #paypal-button-container {
      position: relative;

      @include media-breakpoint-down(md) {
        height: 55px;
      }

      @include media-breakpoint-down(sm) {
        height: 35px;
      }

      &:after {
        content: '';
        background-color: white;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1001;
        opacity: .5;
      }
    }
  }
  app-custom-paypal-button {
    &.not-validated {
      pointer-events: none;
      cursor: pointer;
    }
  }

}

.block-input {
  .MultiStepCheckoutSummaryPageTemplate {
    .wrapper-payment-types {
      background-color: $color-extra-8;
      cursor: not-allowed;
      .form-check {
        pointer-events: none;
        input[type=radio]:checked::after {
          opacity: 0.7;
        }
      }
    }
    #invoiceObservations {
      cursor: not-allowed;
    }
    .cx-cart-coupon-container {
      cursor: not-allowed;

      .input-coupon-code {
        cursor: not-allowed;
        pointer-events: none;
        background-color: $color-extra-8;
      }
      .apply-coupon-button {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.65;
      }
    }

  }
}
