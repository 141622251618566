cx-page-layout.CartPageTemplate {
  @include media-breakpoint-up(xl) {
    padding: 0 1.5rem 40px;

    cx-page-slot {
      flex: 1 1;
    }
  }
}

.CartPageTemplate {
  @extend %CartPageTemplate !optional;
  max-width: var(--cx-page-width-max);
  /*padding: 0 1.5rem;*/
  margin: auto;
  display: flex;
  flex-wrap: wrap;

  .header {
    .HeaderText {
      @include media-breakpoint-down(sm) {
        width: 100.2vw;
        margin: -30px -15px 6px -16px;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .EmptyCartMiddleContent {
      margin-bottom: 15px;
    }
  }

  .cx-title-wrapper {
    .title {
      font-size: 35px;
      text-transform: uppercase;
      font-weight: bold;
    }
    .delivery-message {
      display: flex;
      gap: 10px;
      color: var(--cx-color-primary);
      p {
        margin-bottom: 0;
      }
    }
      border-bottom: 1px solid $border-menu;
      margin-top: 40px;
      padding-bottom: 12px;
  }
  .cx-name {
    a {
      color: $secondary;
      font-size: 18px;
      font-weight: 700;
    }
  }
  .wrapper-buttons {
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    a {
      margin-right: 10px;
    }
    a,
    button {
      max-width: 280px;
    }
    @include media-breakpoint-down(md) {
      flex-direction: column;
      width: 100%;
      a,
      button {
        max-width: none;
        width: 100%;
      }
      a {
        margin-right: 0;
        margin-bottom: 30px;
      }
    }
  }

  .TopContent {
    --cx-flex-basis: 68%;
    flex-direction: column;
    flex-wrap: nowrap;

    @include media-breakpoint-down(lg) {
      --cx-flex-basis: 100%;
      padding: 0 1.5rem;
    }
  }

  .CenterRightContentSlot {
    --cx-flex-basis: 30%;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: nowrap;

    .cx-cart-coupon-container {
      display: block;

      .apply-coupon-button {
        border-radius: 0;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 2px;
        border-style: solid;
        color: var(--cx-color-primary);
        background-color: var(--cx-color-inverse);
        border-color: var(--cx-color-primary);
        border-width: var(--cx-buttons-border-width);

        &:hover {
          color: white;
          background-color: var(--cx-color-primary);
          border-color: var(--cx-color-primary);


        }
      }
    }

    @include media-breakpoint-down(md) {
      padding: 0 1.5rem;
      margin-top: 0;
    }

    // @include media-breakpoint-up(xl) {
      margin-top: 136px;

      cx-cart-coupon {
        padding-inline-start: 30px;
        padding-inline-end: 0;
        margin-top: 2rem;
        margin-bottom: 2rem;
        flex: none;

        .form-group {
          form {
            .cx-cart-coupon-title {
              font-size: var(--cx-font-size, 1rem);
              font-weight: var(--cx-font-weight-bold);
              line-height: var(--cx-line-height, 1.2222222222);
              margin: 1.125rem 0 0.5rem;
            }
          }
        }
      }
    // }

    @include media-breakpoint-only(lg) {
      > * {
        flex: 1 1 var(--cx-flex-basis, 100%);
      }
    }

    @include media-breakpoint-down(lg) {
      flex-direction: row-reverse;

      cx-cart-coupon {
        padding-inline-end: 3rem;
        max-width: 50%;
      }

      cx-cart-totals {
        max-width: 50%;
        margin-left: 0;

        .btn-primary.btn-block {
          width: calc(100% - 20px);
          margin: 0 auto 20px;
        }
      }
    }

    @include media-breakpoint-down(md) {
      cx-cart-totals {
        padding: 0;

        .btn-primary.btn-block {
          width: calc(100% - 60px);
        }
      }

      cx-cart-coupon {
        margin-top: 40px;
      }

      cx-name {
        padding-right: 50px;
      }
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column-reverse;
      flex-wrap: nowrap;

      cx-cart-totals {
        max-width: none;
      }

      cx-cart-coupon {
        margin-top: 0;
        max-width: 100%;
        padding-inline-end: 30px;
        padding-inline-start: 30px;
      }
    }
  }
}
