app-custom-giftbox-cart {
  .ProductGridPageTemplate .Section2 & {
      margin-bottom: 40px;

    @include media-breakpoint-up(lg) {
      max-width: calc(75% - 8px);

      ~ cx-cart-totals {
        margin-left: 8px;
      }
    }
  }

  .giftbox-cart-header {
    border: 2px solid $background;
    height: 100px;
    margin-bottom: 8px;

    cx-media {
      max-height: 100%;
      height: 100px;
      text-align: center;
    }
  }

  .giftbox-cart-items {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    max-height: 120px;
    column-gap: 10px;
  }

  .giftbox-cart-item {
    max-height: 120px;
    flex-grow: 1;
    text-align: center;
    position: relative;
    border: 2px solid $background;

    &.animate {
      animation: fadeInPlace .45s;
    }

    &.empty {
      border-color: var(--cx-color-light);
      border-style: dashed;
      animation: fadeIn .45s;
    }

    cx-media,
    .giftbox-cart-item-no-media {
      position: relative;
      height: 0;
      padding-bottom: 100%;
      width: 100%;
      max-width: 116px;

      img {
        max-height: none;
        max-width: none;
        width: 100%;
      }
    }

    button {
      position: absolute;
      bottom: -50px;
      transform: translateX(-50%);
      text-decoration: none;
      z-index: 1;

      i {
        font-size: 20px;
      }
    }
  }

  .giftbox-cart-header,
  .giftbox-cart-item {
    .giftbox-cart-item-info {
      text-align: center;
      visibility: hidden;
      position: absolute;
      bottom: 100%;
      left: calc(50% - 30px);
      width: 240px;
      padding: 12px;
      background-color: white;
      box-shadow: 0 4px 12px rgb(0 0 0 / 7%);
      border: 1px solid #d1d1d1;
      border-radius: 3px;
      opacity: 0;
      transform: translateY(0);
      transition: all .3s ease-out;
      z-index: 2;

      .giftbox-cart-item-info-name {
        display: block;
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 4px;
        line-height: 1.2;
      }
      
      @include media-breakpoint-up(md) {
        &:before {
          bottom: -22px;
          content: " ";
          display: block;
          left: 19px;
          position: absolute;
          border-color: #a9a9a9 transparent transparent transparent;
          border-style: solid;
          border-width: 11px;
        }

        &:after {
          border-color: white transparent transparent transparent;
          border-style: solid;
          border-width: 10px;
          bottom: -20px;
          content: " ";
          height: 0;
          left: 20px;
          position: absolute;
          width: 0;
        }
      }
    }

    @include media-breakpoint-up(md) {
      &:not(:first-child):not(:nth-child(2)) {
        &:last-child,
        &:nth-last-child(2) {
        .giftbox-cart-item-info {
            left: auto;
            right: calc(50% - 30px);
    
            &:before {
              right: 19px;
              left: auto;
            }
    
            &:after {
              right: 20px;
              left: auto;
            }
          }
        }
      }
    }

    &:hover,
    &:focus {
      cx-media +,
      .giftbox-cart-item-image +,
      button:not(:hover):not(:focus) + {
        .giftbox-cart-item-info {
          opacity: 1;
          transform: translateY(10px);
          visibility: visible;

          @include media-breakpoint-down(sm) {
            transform: translateY(-50%);
          }
        }
      }
    }

    .giftbox-cart-item-info {
      @include media-breakpoint-down(sm) {
        position: fixed;
        top: 50%;
        left: 10px;
        width: calc(100% - 20px);
        bottom: auto;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .giftbox-cart-header {
      position: relative;

      cx-media + .giftbox-cart-item-info {
        left: 0;
        top: 50%;
        bottom: auto;
        transform: translateY(-50%) translateX(20px);

        &:after,
        &:before {
          left: auto;
          right: -20px;
          bottom: auto;
          top: 50%;
          transform: translateY(-50%);
        }

        &:after {
          border-color: transparent transparent transparent white;
        }

        &:before {
          border-color: transparent transparent transparent #a9a9a9;
          right: -22px;
        }
      }

      &:hover,
      &:focus {
        cx-media + .giftbox-cart-item-info {
          transform: translateY(-50%) translateX(10px);
        }
      }
    }
  }

  .giftbox-cart-item-empty {
    align-content: center;
    height: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    font-weight: bold;
    color: var(--cx-color-light);
    font-size: 60px;
    line-height: 60px;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .giftbox-cart-item-image {
    position: relative;
    max-width: 116px;
    margin: 0 auto;
  }

  @include media-breakpoint-down(md) {
    .giftbox-cart-header {
      max-height: 80px;
    }

    .giftbox-cart-items {
      max-height: 80px;
      column-gap: 6px;
    }
  
    .giftbox-cart-item {
      max-height: 80px;

      cx-media,
      .giftbox-cart-item-no-media {
        max-width: 76px;
      }
    }

    .giftbox-cart-item-image {
      max-width: 76px;
    }
  

    .giftbox-cart-item-empty {
      font-size: 40px;
      line-height: 40px;
    }
  }

  @include media-breakpoint-down(sm) {    
    .giftbox-cart-header {
      max-height: 50px;
    }

    .giftbox-cart-items {
      max-height: 50px;
      column-gap: 2px;
    }
  
    .giftbox-cart-item {
      max-height: 50px;

      cx-media,
      .giftbox-cart-item-no-media {
        max-width: 46px;
      }
    }

    .giftbox-cart-item-image {
      max-width: 46px;
    }

    .giftbox-cart-item-empty {
      font-size: 20px;
      line-height: 20px;
    }
  }

  .Section2 & ~ .cx-dialog-popover-center-backdrop {
    max-width: 100%;
  }
}
