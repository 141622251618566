app-custom-divider {
  height: 200px;
  position: relative;

  svg {
    width: 100%;
    left: 0;
    bottom: -1px;
    height: 100%;
    position: absolute;
  }

  @include media-breakpoint-down(md) {
    display: none!important;
  }
}
