@mixin defaultUnitOnly () {
  flex-direction: column;

  .unit,
  .btn-add-to-cart {
    width: 100%;
  }

  .btn-add-to-cart {
    margin-top: 14px;
    margin-bottom: 10px;
  }
}

cx-add-to-cart {
  @extend %cx-add-to-cart !optional;

  form {
    display: flex;
    align-items: center;
  }

  app-custom-add-to-cart-unit {
    ~ app-custom-add-to-cart-unit form {
      margin-top: 20px;

    }

    form {
      cx-item-counter {
        display: inline-flex;
        border: 1px solid var(--cx-color-light);
        border-radius: 4px;

        &:disabled {
          cursor: not-allowed;
        }

        button {
          margin: 0;
          max-height: 48px;
          min-width: 40px;
          border: none;
          cursor: pointer;
          color: var(--cx-color-text);
          background-color: var(--cx-color-transparent);
          font-size: var(--cx-font-size,1rem);
          font-weight: var(--cx-font-weight-normal);
          line-height: var(--cx-line-height,1.6);

          &:disabled {
            color: var(--cx-color-light);
          }
        }

        input {
          text-align: center;
          border: 1px solid var(--cx-color-light);
          border-width: 0 1px;
          padding: 12px 9px;
          max-height: 46px;
          min-width: 48px;
          max-width: 78px;
        }

        input[type="number"] {
          /* Remove inner arrows */
          -webkit-appearance: none;
          -moz-appearance: textfield; /* to Firefox */
        
          /* Remove outer arrows */
          appearance: textfield; /* to Firefox */
        }
        
        /* Removes inner arrows in Webkit browsers (Chrome, Safari) */
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      cx-item-counter input[type=number] {
        width: 54px;
        display: inline-flex;
      }

      &.nostock {
        button.btn-add-to-cart {
          opacity: .6;
        }

        cx-item-counter input[type=number] {
          cursor: not-allowed;
        }
      }
    }
  }

  .quantity {
    margin: 0 16px 0 0;

    label {
      @include type('8');
      margin: 15px 0 10px 0;
    }

    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

  .info {
    font-size: 24px;
    font-weight: bold;
    display: block;
    margin: 0 0 24px;
    color: var(--cx-color-secondary);
  }

  .btn-add-to-cart {
    width: 60px;
    min-width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    .btn-add-to-cart-text {
      display: none;

      @include media-breakpoint-down(xs) {
        display: inline;
      }
    }
  }

  cx-product-grid-item &.default-unit-only form {
    @include defaultUnitOnly;
  }

  cx-product-list-item &.default-unit-only form {
    @include media-breakpoint-down(xs) {
      @include defaultUnitOnly;
    }

    @include media-breakpoint-up(sm) {
      .btn-add-to-cart {
        min-width: auto;
        width: auto;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    form {
      flex-direction: column;
      align-items: stretch;
    }

    .btn-add-to-cart {
      width: 100%;

      .btn-add-to-cart-text {
        display: inline;
      }
    }

    app-custom-unit-selector {
      margin-bottom: 16px;
    }
  }
}
