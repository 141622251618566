app-custom-image-info-container {

  > .container {
    padding: 0;
    margin: 30px 0 0;

    @include media-breakpoint-up(lg) {
      padding: 50px;
    }

    @include media-breakpoint-down(md) {
      .content {
        padding: 30px 15px;
      }
    }

    > .content {
      @include media-breakpoint-up(md) {
        display: flex;
        column-gap: 30px;
      }
    }
  }

  app-custom-image-info {
    flex: 1;

    @include media-breakpoint-down(sm) {
      &:not(:last-child) {
        display: block;
        margin-bottom: 30px;
      }
    }

    .container {
      grid-auto-flow: row;
      padding: 0;

      @include media-breakpoint-down(md) {
        .content {
          padding: 0;
        }
      }
    }
  
    .image {
      order: 0;
    }
  
    .title {
      color: $compImageInfoContainerTitleColor;
      font-size: 16px;
      margin-top: 16px;
      margin-bottom: 16px;
      letter-spacing: normal;
    }
  
    .subtitle {
      font-size: 12px;
    }
  
    .description {
      margin-top: 12px;
      margin-bottom: 10px;
    }
  
    .custom-image-info-actions {
      .btn {
        @include link-anim;
        border: none;
        padding: 0;
        margin: 0;
        min-width: auto;
        min-height: auto;
        font-size: 12px;
        margin-left: 18px;

        &:before {
          content: ">";
          margin-right: 4px;
          background-color: $primary;
          height: 14px;
          width: 14px;
          display: inline-block;
          border-radius: 12px;
          color: white;
          font-size: 12px;
          margin-left: -18px;
        }

        &.btn-send:hover {
          color: inherit;
          background-color: inherit;
        }
      }
    }
  }
  
  &.full-page-width {
    @media (min-width: map-get($container-max-widths, "xl")) {
      > .container > .content {
        max-width: 1320px;
        padding: 50px;
        margin: auto;
      }
    }

    @include media-breakpoint-up(lg) {
      > .container {
        max-width: none;
      } 
    }
  }
}
