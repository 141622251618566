app-custom-product-attributes {
  @include media-breakpoint-up(lg) {
    padding-inline-end: 20px;
    padding-inline-start: 20px;
  }

  ul {
    width: 100%;
    display: table;
    list-style: none;
    padding: 0;
    font-size: 18px;
    border-collapse: collapse;

    li {
      display: table-row;

      + li {
        border-top: 10px solid transparent;
      }

      > * {
        display: table-cell;
      }
    }
      
    .info {
      padding-left: 15px;
      vertical-align: middle;
    }
    
    .icon {
      width: 35px;
      height: 35px;
      vertical-align: middle;

      &.icon-icon-pdf,
      &.icon-file-empty {
        font-size: 30px;
        text-align: center;
        color: $color-extra-4;
      }
    }

    img {
      width: 35px;
      height: 35px;
    }

    .title {
      font-weight: bold;
      display: inline-block;
      margin: 0;
      font-size: 18px;
    }

    a.title {
      text-decoration: none;
    }
  }
}
