app-custom-columns-with-file-list-group {
  display: block;

  .column-title {
    font-size: 36px;
    color: var(--cx-color-secondary);
    letter-spacing: -.2px;
    line-height: 40px;
    font-weight: bold;
    font-family: $font-family-custom1;
  }

  .column-description {
    font-size: 18px;
    color: var(--cx-color-secondary);
    letter-spacing: 1px;
    line-height: 22px;
    margin-bottom: 45px;
    margin-top: 45px;
  }
}
