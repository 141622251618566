.follow-social-media {
  p {
    margin: 8px;
  }

  ul {
    list-style: none;
    display: inline-flex;
    margin: 0;
    padding: 0;

    app-custom-icons {
      font-size: 30px;
    }

    li + li {
      margin-left: 6px;
    }
  }
}
