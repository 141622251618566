cx-storefront{
  &.ProductGridPageTemplate {
    @include media-breakpoint-up(md) {
      footer {
        position: relative;
        max-height: 99.9vh;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
        app-info {
          position: sticky;
          top: 0;
        }
      }
    }
  }
}
