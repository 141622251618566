app-custom-double-image-info {
  cx-media img {
    width: 100%;
    height: 100%;
  }

  > .container {
    padding: 0;
    display: grid;

    @include media-breakpoint-up(md) {
      grid-auto-columns: 1fr;
      grid-template-columns: 1fr;
      grid-auto-flow: column;
      column-gap: 50px;
      margin: 30px auto;

      app-custom-image-info {

        &.full-page-width{
          width: 100%;
          margin: unset;
        }

        &.invert {
          > .container {
            .content {
              padding-top: 50px
            }
          }
        }

        > .container {
          padding: 0;
          margin: 0;
          display: flex;
          flex-direction: column;

          .image {
            margin-bottom: 30px;
          }

          .content {
            flex: auto;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            padding: 0 50px;
          }

          &.alternate {
            .content {
              padding-bottom: 50px;
            }
          }

          .image,
          .content {
            align-self: auto;
          }

          cx-generic-link {
            flex-grow: 1;
            display: flex;
            align-items: flex-end;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    app-custom-image-info {
      padding: 0;
    }
  }
}
