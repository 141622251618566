cx-page-layout.ProductDetailsPageTemplate {
  @extend %ProductDetailsPageTemplate !optional;

  cx-page-slot {
    &.Summary {
      @include media-breakpoint-up(lg) {
        grid-template-columns: 1fr 630px;
        grid-template-rows: auto;

        cx-product-images {
          grid-row: 1/span 11;
          margin-bottom: 10px;
          position: relative;

          cx-media {
            height: 0;
            padding-top: 100%;

            img {
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              width: 100%;
              height: 100%;
              margin: 0 auto;

              &:hover {
                cursor: zoom-in;
              }
            }
          }

          .item{
            cx-media {
              height: 100%;
              padding-top: 0.5vw;
              margin-top: 0;
              margin-bottom: 0;

              img {
                position: relative;
              }
            }
          }

          cx-carousel {
            .item cx-media{
              height: 100%;
            }
          }
        }

        app-custom-product-intro {
          grid-column: 2;

          &.has-stock {
            position: sticky;
            top: 0;
            background-color: var(--cx-color-inverse);
            z-index: 1;

            .title {
              position: sticky;
              top: 0;
            }
          }
        }

        app-custom-product-summary {
          grid-column: 2;
        }

        cx-product-variants {
          grid-column: 2;
          grid-row: none;
        }

        cx-add-to-cart {
          grid-row: auto;

          &.has-stock {
            position: sticky;
            top: var(--cx-pdp-title-height);
            background-color: var(--cx-color-inverse);
            z-index: 1;
          }
        }

        cx-stock-notification {
          padding-top: 0;
          grid-row: none;
        }

        cx-stock-notification:empty {
          display: none;
        }

        app-custom-product-attributes {
          grid-column: 2;
        }
      }
      position: relative;

      cx-carousel {
        margin-top: .5vw;
      }

      @include media-breakpoint-down(md) {
        app-custom-product-summary {
          padding-top: 30px;
        }
      }

      cx-add-to-cart {
        padding-top: 20px;
        padding-bottom: 20px;

        .quantity {
          label {
            text-transform: uppercase;
            display: none;
            margin-right: 10px;
          }
        }

        cx-item-counter + .info {
          display: none;
        }
      }

      cx-add-to-wishlist {
        margin-top: 18px;
      }

        cx-breadcrumb {
          @include media-breakpoint-up(lg) {
            padding-inline-end: 20px;
            padding-inline-start: 20px;
          }
      }

      app-custom-product-labels {
        position: absolute;
        top: 15px;
        left: 15px;
        z-index: 1;
      }
    }

    &.AddToCart {
      max-width: 50%;
    }

    &.UpSelling,
    &.CrossSelling {
      max-width: var(--cx-page-width-max);
      margin: auto;

      h3 {
        @extend .container !optional;
        text-align: $pdpCarouselTitleAlign;
        padding: 10px 15px;
        background-color: $pdpCarouselTitleBgColor;
      }
    }
  }

}

.cx-stock-notification-dialog {
  .cx-stock-notification-container{
    .cx-dialog-body {
      padding: 27px 30px !important;

      p {
        margin-top: 0 !important;
        margin-bottom: 1.5rem !important;
      }
    }
  }
}