cx-product-references {
  @extend %cx-product-references !optional;

  padding: 0 15px 50px;

  cx-media {
    margin-bottom: 20px;

    &:not(.is-missing) {
      height: 0;
      padding-bottom: 60%;

      img {
        width: 100%;
        height: auto;
        max-height: none;
      }
    }

    &.is-missing {
      min-height: auto;
      width: 100%;
      padding-bottom: 60%;
    }
  }

  h4 {
    @include media-breakpoint-up(sm) {
      min-height: 40px;
      line-height: 1;
      margin-bottom: 0;
    }
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }
}
