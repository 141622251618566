app-custom-product-categories {
  color: $compProductCategoriesTitleColor;

  ul {
    margin-bottom: 0;
    list-style: none;
    padding-left: 0;
    display: inline-flex;
    padding-left: 4px;
    font-weight: 10px;

    .btn-link {
      text-decoration: none;
    }

    li:not(:last-child) a:after {
      content: ",";
      padding-right: 4px;
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 0 20px;
  }
}
