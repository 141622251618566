:root {
  --cx-page-width-max: 1320px;
  --cx-pdp-title-height: 53px;

  body {
    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background: $background;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
      -webkit-border-radius: 10px;
      border-radius: 10px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: $primary;
      -webkit-box-shadow: inset 0 0 2px $background;
      border-radius: 1px;
    }
    
    ::-webkit-scrollbar-thumb:window-inactive {
      background: $secondary;
    }

    overflow-x: hidden;
  }
}

a {
  min-height: 0;
}

@include media-breakpoint-down(md) {
  .LandingPageTransparentHeaderTemplate cx-page-slot.Section3, cx-page-layout.LandingPage2Template cx-page-slot.Section3 {
    max-width: 100%;
  }
}