cx-order-history {
  @extend %cx-order-history !optional;

  .cx-order-history-no-order {
    margin: 30px;
    text-align: center;
    font-size: 18px;
  }

  .cx-order-history-sort.top {
    > div:first-child {
      padding: 0;

    }

    @include media-breakpoint-up(lg) {
      align-items: flex-end;

      > div:first-child:not(:last-child) {
        text-align: right;
        padding-bottom: 16px;
      }

      .form-check {
        display: inline-block;
        margin-bottom: 0;

        + .form-check {
          margin-left: 28px;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    max-width: 100%;
    padding: 0;

    .cx-order-history-form-group {
      padding: 0;
    }
  }

  .cx-order-history-header {
    padding: 0;

    .title {
      font-size: 35px;
      text-transform: uppercase;
      font-weight: bold;
    }

    @include media-breakpoint-down(md) {
      padding-inline-end: 0;
      padding-inline-start: 0;
      margin-top: 50px;
    }
  }
}
