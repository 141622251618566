app-custom-contact-form {
  padding: 40px 0;
  min-height: 300px;

  .title {
    color: var(--cx-color-secondary);
    font-weight: 600;
    font-size: 36px;
    font-family: $font-family-custom2;
    letter-spacing: -0.2px;

    @include media-breakpoint-only(md) {
      font-size: 32px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 28px;
    }
  }

  .description {
    margin: 24px 0;
    line-height: 24px;
    font-size: 18px;
  }

  .table-content {
    font-size: 11px;
  }

  .subtitle {
    font-size: 22px;
    text-transform: uppercase;
    font-family: $font-family-custom1;
    letter-spacing: 1px;
    color: $color-extra-16;
  }

  .form-check .form-check-label {
    font-size: 12px;
    text-transform: uppercase;
    color: $color-extra-5;
  }

  .content {
    margin-top: 40px;
  }

  .cx-product-affected {
    display: flex;
    gap: 10px;

    .cx-product-affected-description {
      flex-grow: 1;
    }

    .cx-product-affected-remove {
      width: 50px;
    }

    .cx-product-affected-quantity {
      width: 100px;

      @include media-breakpoint-down(sm) {
        width: 60px;
      }
    }

    &:first-child .cx-product-affected-quantity {
      width: 160px;

      @include media-breakpoint-down(sm) {
        width: 120px;
      }
    }
  }
}
