.LandingPageTransparentHeaderTemplate {
  @extend %LandingPage2Template !optional;
  max-width: var(--cx-page-width-max);

  cx-page-slot.Section1 {
    margin-top: 0;
  }

  header {
    color: white;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgb(26, 26, 26) 150%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    width: 100%;

    .SiteLinks {
      a {
        color: #ffffff;
        text-shadow: 0 0 4px black;
      }
    }

    app-custom-site-context-selector {
      select, .icon-chevron-down {
        color: #ffffff;
        text-shadow: 0 0 4px black;
      }
      icon-chevron-down {
        z-index: 1;
      }
      option {
        color: var(--cx-color-text, #000000);
      }
    }

    app-custom-mini-cart {
      .count{
        color: #ffffff;
        text-shadow: 0 0 4px black;
      }
    }

    cx-category-navigation {
      cx-navigation-ui {
        @include media-breakpoint-up(lg) {
          >
          nav {
            color: white;

            > {
              h5 {
                color: white;

                &:hover {
                  color: white;
                }
              }

              cx-generic-link {
                a, h5 {
                  color: white;

                  &:hover {
                    color: white;
                    opacity: .9;
                  }
                }
              }
            }
          }

          @include media-breakpoint-up(xxl) {
            >
            nav {
              > {
                .wrapper {
                  width: 100vw;
                  margin-left: calc((100vw - var(--cx-page-width-max)) / -2) !important;
                  margin-right: calc((100vw - var(--cx-page-width-max)) / -2) !important;
                }
              }
            }
          }
        }
      }

      .SearchBox, .SiteLogin, .MiniCart {
        i {
          color: white;

          &:before {
            color: white;
          }
        ;
        }
      }
    }

    .searchbox input {
      color: var(--cx-color-primary);
    }

    main {
      position: relative;
      z-index: 0;
    }

    .MiniCart i,
    .icon-ico-user-color::before,
    .user-menu .icon-chevron-down::before {
      color: white;
    }

    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
      background-color: white;
    }
  }

  cx-asm-main-ui {
    display: block;
    z-index: 4;
    @media (min-width: map-get($container-max-widths, "xl")) {
      min-width: 100vw;
      width: 100vw;
      margin-left: calc((100vw - var(--cx-page-width-max)) / -2);
      margin-right: calc((100vw - var(--cx-page-width-max)) / -2);
      max-width: 100vw;
    }

    + header {
      position: relative;
      @media (min-width: map-get($container-max-widths, "xl")) {
        min-width: 100vw;
        width: 100vw;
        margin-left: calc((100vw - var(--cx-page-width-max)) / -2);
        margin-right: calc((100vw - var(--cx-page-width-max)) / -2);
        max-width: 100vw;
      }
    }

    ~ main {
      margin-top: -90px;
      @include media-breakpoint-up(lg) {
        margin-top: -110px;
      }
    }
  }

  .SliderHeader {
    @media (min-width: map-get($container-max-widths, "xl")) {
      min-width: 100vw;
      width: 100vw;
      margin-left: calc((100vw - var(--cx-page-width-max)) / -2);
      margin-right: calc((100vw - var(--cx-page-width-max)) / -2);
      max-width: 100vw;
    }
  }

  cx-page-layout {
    &.footer {
      --cx-color-dark: var(--cx-color-background);
      --cx-color: var(--cx-color-primary);
    }
  }

  @media (min-width: map-get($container-max-widths, "xl")) {
    footer {
      min-width: 100vw;
      width: 100vw;
      margin-left: calc((100vw - var(--cx-page-width-max)) / -2);
      margin-right: calc((100vw - var(--cx-page-width-max)) / -2);
      max-width: 100vw;
    }
  }
}


[class*="smartedit-page-uid"] {
  .LandingPageTransparentHeaderTemplate header {
    position: relative
  }
}
