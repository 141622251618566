cx-order-summary {
  @extend %cx-order-summary !optional;

  .cx-summary-label {
    align-self: center;
    text-align: start;
    padding: 0;
  }
  .cx-summary-total .cx-summary-label {
    font-size: 20px;
  }

  .cx-summary-amount {
    text-align: end;
    padding: 0;
  }

  .cx-summary-total {
    font-weight: var(--cx-font-weight-bold);
  }
}
