cx-order-return-request-list {
  @extend %cx-order-return-request-list !optional;

  .cx-order-history-header {
    padding: 0;

    .title {
      font-size: 35px;
      text-transform: uppercase;
      font-weight: bold;
    }

    @include media-breakpoint-down(md) {
      padding-inline-end: 0;
      padding-inline-start: 0;
      margin-top: 50px;
    }
  }

  .cx-order-history-no-order {
    margin: 30px;
    text-align: center;
    font-size: 18px;
  }

  .cx-spinner {
    padding: 2rem 0;
  }

  @include media-breakpoint-down(md) {
    max-width: 100%;
    padding: 0;

    .cx-order-history-form-group {
      padding: 0;
    }
  }
}
