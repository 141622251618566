cx-my-interests {
  @extend %cx-my-interests !optional;

  .cx-product-interests-title h3 {
    font-size: 30px;
    margin-bottom: 30px;
    font-weight: bold;
  }

  .cx-product-interests-sort.bottom,
  .cx-product-interests-product-item .cx-code {
    display: none;
  }

  .cx-product-interests-table {
    td {
      &:nth-child(4) {
        text-align: center;
        justify-content: flex-start;
        button {
          text-decoration: none;
          color: $text;
        }
      }
    }
  }
}

cx-notification-preference {
  .pref-header {
    margin-bottom: 10px;
  }

  .cx-notification-channels {
    margin-left: 10px;
  }
}