.FooterLessPageTemplate {
  header {
    display: flex;

    &.is-expanded {
      .navigation {
        margin-top: 50px;
        height: calc(100vh - 50px);
        overflow: auto;
      }
    }

    .header {
      padding: 10px;
      @include media-breakpoint-down(lg) {
        padding: 10px 15px;
      }
    }

    .SiteLogo {
      padding: 0;
    }
  }

  cx-page-layout {
    &.FooterLessPageTemplate {
      padding: 0;

      app-custom-register-friend-form {
        cx-form-errors p {
          color: white;
        }

        .label-content {
          color: white;
          text-shadow: 1px 1px 1px #000000;
        }

        .info {
          text-transform: uppercase;
          font-weight: bold;
          color: white;
          margin-bottom: 15px;
        }
      }

      h2 {
        color: white;
        font-size: 22px;
        letter-spacing: 0.3px;
        border-bottom: 1px solid white;
        padding-bottom: 10px;
        text-transform: uppercase;
        margin-bottom: 30px;
      }

      app-banner-image-text {
        text-shadow: 1px 1px 1px #000000;

        h1 {
          color: $primary;
          font-size: 80px;
          text-transform: uppercase;
        }

        p {
          color: white;
          font-size: 17px;
        }

      }

      .RightContentSlot, .LeftContentSlot {
        padding: 30px;
      }

      cx-media {
        img {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: -1;
          z-index: -1;
          width: 100vW;
          height: 100vh;
          object-fit: cover;
        }
      }

      display: flex;
      align-content: flex-start;
      margin: 50px auto;
      @extend .container !optional;
    }
  }

  @include media-breakpoint-down(md) {
    .wrapper-text {
      display: none;
    }
    cx-page-layout {
      &.FooterLessPageTemplate {
        display: block;
      }
    }
  }
}
