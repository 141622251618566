app-custom-video-info {
  background-color: var(--cx-color-background);
  display: block;
  &[style*=background] .container {
    background: transparent;
  }

  .container {
    padding: 0;
    display: grid;

    @include media-breakpoint-up(md) {
      padding: 50px;
      grid-auto-columns: 1fr;
      grid-template-columns: 1fr;
      grid-auto-flow: column;
      column-gap: 50px;
      margin: 30px auto;

      youtube-player,
      .content {
        align-self: center;
      }

      youtube-player {
        order: 1;
      }
    }
  }

  youtube-player {
    position: relative;
    padding-bottom: calc(100% / 16 * 9);
    height: 0;
    order: 2;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
    }

    @include media-breakpoint-down(sm) {
      margin: 0;
    }
  }

  @include media-breakpoint-down(sm) {
    .content {
      padding: 50px;
    }
  }

  .btn {
    min-width: 150px;
  }

  .title {
    color: var(--cx-color-primary);
    font-size: 18px;
    letter-spacing: 3.5px;
    font-weight: 600;

    + button {
      margin-top: 30px;
    }
  }

  .subtitle {
    color: var(--cx-color-secondary);
    font-weight: 600;
    font-size: 36px;
    font-family: $font-family-custom2;
    letter-spacing: -0.2px;

    @include media-breakpoint-only(md) {
      font-size: 32px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 28px;
    }

    + button {
      margin-top: 30px;
    }
  }

  .description {
    font-size: 18px;
    color: var(--cx-color-secondary);
    margin: 45px 0;
    line-height: normal;
  }
}
