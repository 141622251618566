app-custom-video-full-width {
  &:not(:first-child) {
    youtube-player {
      margin-top: 48px;
      @include media-breakpoint-down(sm) {
        margin-top: 30px;
      }
    }
  }

  youtube-player {
    position: relative;
    padding-bottom: calc(100% / 16 * 9);
    height: 0;
    display: block;
    z-index: -1;
    pointer-events: none;
    margin-bottom: 48px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 30px;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
    }
  }
}
