cx-facet-list {
  @extend %cx-facet-list !optional;

  .inner h4,
  &.dialog > .inner {
    background-color: var(--cx-color-inverse);
  }

  @include media-breakpoint-up(lg) {
    cx-active-facets {
      display: none;
    }
  }
}
