app-custom-hover-aware-images-container {
  .container {
    padding: 0;

    @include media-breakpoint-up(lg) {
      padding: 50px;
    }
    @include media-breakpoint-only(md) {
      padding: 20px;
    }

    @include media-breakpoint-down(md) {
      padding-bottom: 20px;

      .content {
        padding: 30px 15px;
      }
    }
  }

  .title {
    color: var(--cx-color-primary);
    font-size: 18px;
    letter-spacing: 3.5px;
    font-weight: 600;
  }

  .subtitle {
    color: var(--cx-color-secondary);
    font-weight: 600;
    font-size: 36px;
    font-family: $font-family-custom2;
    letter-spacing: -.2px;

    @include media-breakpoint-only(md) {
      font-size: 32px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 28px;
    }
  }
  app-custom-hover-aware-image {
    width: calc(100% / 3);
    min-width: calc(100% / 3);
    text-align: center;
    position: relative;
    padding: 15px;
    display: inline-block;
    
    @include media-breakpoint-down(xs) {
      width: 50%;
      min-width: 50%;
    }
  }
}
