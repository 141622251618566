cx-cart-item-list {
  @extend %cx-cart-item-list !optional;
  .cx-item-list-row {
    padding: 1.25rem 0;
    border: solid $border-menu;
    border-width: 0 0 1px;
  }
  cx-media {
    &.is-missing {
      width: 96px;
    }

    img {
      max-width: 96px;
      margin-left: 0;
    }
  }

  .border-wrapper {
    flex: 0%;
    justify-content: flex-end;
    display: flex;
    height: 46px;
    align-items: center;
    padding: 10px;
    border: solid 1px var(--cx-color-light);
    margin-right: 20px;
    .cx-value {
      font-weight: bold;
    }
  }
  .cx-remove-btn {
    justify-self: self-end;
    display: flex;

    i {
      font-size: 20px;
    }
  }
  .cx-info-container {
    .cx-quantity {
      margin-right: 30px;
    }

    .cx-total,
    .cx-remove-btn {
      margin-right: 20px;
    }
  }

  app-custom-unit-display.unit {
    min-height: 45px;
    
    @include media-breakpoint-down(sm) {
      min-height: 43px;
    }
  }

  cx-item-counter:not(.readonly) input {    
    @include media-breakpoint-up(sm) {
      width: 57px;
    }

    @include media-breakpoint-down(xs) {
      width: 48px;
    }
  }

  .cx-info {
    position: relative;
    justify-items: stretch;
    display: grid;

    .cx-info-price {
      display: flex;
      align-items: center;
    }

    .cx-total-remove {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  cx-cart-item .cx-value {
    padding-inline-start: 0;
  }
  
  cx-item-counter {
    display: inline-flex;
    border: 1px solid var(--cx-color-light);
    border-radius: 4px;

    &:disabled {
      cursor: not-allowed;
    }

    button {
      margin: 0;
      max-height: 48px;
      min-width: 40px;
      border: none;
      cursor: pointer;
      color: var(--cx-color-text);
      background-color: var(--cx-color-transparent);
      font-size: var(--cx-font-size,1rem);
      font-weight: var(--cx-font-weight-normal);
      line-height: var(--cx-line-height,1.6);

      &:disabled {
        color: var(--cx-color-light);
      }
    }

    input {
      text-align: center;
      border: 1px solid var(--cx-color-light);
      border-width: 0 1px;
      padding: 12px 9px;
      max-height: 46px;
      min-width: 48px;
      max-width: 78px;
    }

    input[type="number"] {
      /* Remove inner arrows */
      -webkit-appearance: none;
      -moz-appearance: textfield; /* to Firefox */
    
      /* Remove outer arrows */
      appearance: textfield; /* to Firefox */
    }
    
    /* Removes inner arrows in Webkit browsers (Chrome, Safari) */
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  cx-item-counter input[type=number] {
    width: 54px;
    display: inline-flex;
  }

  .cx-total {
    display: flex;
    /*width: 100%;*//*To fix price value*/
    width: 100px;

    .cx-value {
      font-size: 20px;
      font-weight: bold;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;

      @include media-breakpoint-down(md) {
        justify-content: flex-start;
      }
    }
  }

  .cx-remove-btn {
    .cx-action-link {
      text-decoration: none;
      padding: 6px;
    }
  }

  .cx-info-price-slim-price {
    font-weight: bold;
    display: block;
  }

  .cx-delivery-information {
    color: var(--cx-color-primary);
    margin: 0 2px;
  }

  @include media-breakpoint-up(lg) {
    app-custom-unit-display.unit {
      min-width: 350px;
    }

    .cx-remove-btn {
      grid-row: 3;
      justify-self: self-end;
      display: flex;
    }

    .cx-info {
      grid-template-columns: 150px auto;
  
      .cx-image-container {
        grid-column: 1;
        grid-row: 1/ span 3;
        justify-self: center;
      }
  
      cx-promotions {
        grid-column: 2/5;
        grid-row: auto;
      }
  
      .cx-info-price-slim,
      .cx-info-price {
        grid-column: 2;
        grid-row: 3;
      }
  
      .cx-info-container {
        grid-column: 2;
        grid-row: 1;
        align-self: end;
        /*width: 14rem;*//*To fix price value*/
        margin-bottom: 0.25rem;
      }
  
      .cx-total-remove {
        grid-column: 2;
        grid-row: 3;
      }
    }
  
    .cx-total {
      grid-row: 3;
      justify-self: flex-end;
    }
  
    .cx-remove-btn {
      justify-content: flex-end;
      grid-row: 3;
      width: 50px;
    }

    .cx-delivery-information {
      grid-row: 4;
      grid-column: 2;
    }

  }

  @include media-breakpoint-down(md) {
    .cx-item-list-items {
      padding: 0;
    }
    .cx-info {
      display: grid;
      position: relative;
      grid-template-columns: 120px auto;
      .cx-image-container {
        grid-column: 1;
        grid-row: 1/span 5;
        justify-self: center;
      }
      cx-media {
        &.is-missing {
          width: 75px;
        }

        img {
          max-width: 75px;
        }
      }
      cx-promotions {
        grid-column: 2;
        grid-row: 3;
      }
      .cx-name {
        padding-right: 40px;
      }
      .cx-total {
        grid-column: 2;
        grid-row: 2;
        justify-content: start;
        align-self: self-start;
      }
      .cx-info-price {
        grid-column: 1/span 2;
        margin: 16px 6px 2px;
      }
      .cx-info-container {
        grid-column: 2;
        grid-row: 1;
        align-self: start;
      }
      .cx-remove-btn {
        position: absolute;
        top: 0;
        right: 0;

        .cx-action-link {
          text-decoration: none;
        }
      }
    }
    .cx-delivery-information {
      margin: 0 8px;
    }
  }
}
