@font-face {
  font-family: "Teodor";
  src: url('../../../assets/fonts/teodor/Teodor-Light.woff2') format("woff2"),
    url('../../../assets/fonts/teodor/Teodor-Light.woff') format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Teodor";
  src: url("../../../assets/fonts/teodor/Teodor-LightItalic.otf") format("otf");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Teodor";
  src: url('../../../assets/fonts/teodor/Teodor-Regular.woff2') format("woff2"),
    url('../../../assets/fonts/teodor/Teodor-Regular.woff') format("woff");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Teodor-L";
  src: url('../../../assets/fonts/teodor/Teodor-Light.woff2') format("woff2"),
    url('../../../assets/fonts/teodor/Teodor-Light.woff') format("woff");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}
