cx-return-request-overview {
  @extend %cx-return-request-overview !optional;

  margin-bottom: 30px;

  .cx-header {
    border: none;
    margin: 0;
    padding: 15px 0;
    background-color: var(--cx-color-background);

    .cx-detail {
      padding: 20px;
      border: none;
    }
  }

  @include media-breakpoint-up(sm) { 
    h2.page-title {
      margin-top: 0;
    }
  }

  @include media-breakpoint-down(sm) {     
    .cx-header {
      padding: 15px 30px;

      .cx-detail {
        padding: 4px 0;
      }
    }
  }
}
