cx-register {
  @extend %cx-register !optional;

  cx-page-layout.LoginPageTemplate & {
    max-width: 66%;
  }

  .btn-send {
    display: block;
    margin-left: auto;

    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-bottom: 30px;

      + a {
        text-align: center;
        display: block;
      }
    }
  }

  .cx-section {
    width: 100%;
  }

  cx-page-slot.BodyContent {
    padding: 0;
    margin-bottom: 40px;

    cx-paragraph {
      max-width: none;

      table {
        thead {
          font-size: 12px;
        }

        tbody {
          font-size: 11px;

          td:first-child {
            min-width: 120px;
          }
        }
      }
    }
  }
}
.table-content {
  width: 100%;

  td {
    padding: 5px;
    border: 1px solid $light;
  }

  thead {
    color: $color-extra-5;
  }

  tbody {
    color: $light;
  }
}