app-custom-news-slider, app-custom-content-pages-slider-banner-component {
  @extend %news !optional;
  display: block;
  padding-top: 50px;
  padding-bottom: 50px;

  &.invert {
    @include media-breakpoint-up(md) {
      .content {
        flex-direction: row-reverse;
      }
    }
  }

  .title {
    color: $primary;
    font-size: 18px;
    letter-spacing: 3.5px;
    font-weight: 600;
  }

  .subtitle {
    color: $secondary;
    font-weight: 600;
    font-size: 36px;
    text-transform: uppercase;
    font-family: $font-family-custom2;

    @include media-breakpoint-only(md) {
      font-size: 32px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 28px;
    }
  }

  .cx-button {
    display: block;
    margin-top: 16px;
  }

  .media-container {
    margin-bottom: 30px;
  }

  cx-generic-link.btn-send {
    padding-left: 0;
    margin-bottom: 25px;
  }

  .item-title {
    letter-spacing: .5px;
    font-size: 15px;
    text-transform: uppercase;
  }

  cx-carousel {
    .item {
      padding-left: 20px;
      padding-right: 20px;

      cx-media {
        margin-bottom: 16px;
      }

      .item-link {
        font-size: 18px;
        font-weight: bold;
      }
    }

    button {
      flex-direction: column;
      display: flex;
      height: 100%;
    }

    .previous, .next:disabled {
      &:disabled {
        display: none;
      }
    }
  }

  .icon-chevron-right, .icon-chevron-left {
    &:before {
      font-weight: bold;
      color: $alternative;
    }
  }
}

app-custom-content-pages-slider-banner-component {
  .item {
    .item-link {
      text-transform: uppercase;
    }
  }
}
