cx-wish-list {
  .cx-wish-list-item {
    position: relative;

    .wish-list-item-delete {
      position: absolute;
      top: 0;
      right: 0;
    }

    .row {
      border-top: thin solid var(--cx-color-light);
      padding: 32px 0;
      margin-right: 0;
      margin-left: 0;
    }

    &:nth-child(2) .row {
      margin-top: 32px;
      border-top-color: #eae9e9;
    }

    &:last-child .row {
      margin-bottom: 32px;
      border-bottom: thin solid #eae9e9;
    }

    cx-add-to-cart .info {
      display: none;
    }
  }

  @include media-breakpoint-up(md) {
    .cx-product-name {
      margin-right: 34px;
    }
  }

  @include media-breakpoint-down(sm) {
    .cx-wish-list-item {
      .row {
        padding: 50px 0 32px;
      }
    }
  }
}
