.coupon {
  margin-top: 50px;
  border-top: 1px solid $border-menu;
  padding-top: 30px;
  a {
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    app-custom-icons {
      margin-left: 10px;
      margin-right: 10px;
      line-height: 22px;
    }
  }
  cx-cart-coupon {
    @extend %cx-cart-coupon !optional;
    padding-inline-end: 0;
    padding-inline-start: 3rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  @include media-breakpoint-down(md) {
    padding-top: 0;
    padding-inline-end: 0;
    padding-bottom: 2rem;
    padding-inline-start: 0;
  }

  .form-group .col-md-4 {
    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }

  .cx-cart-coupon-title {
    display: none;
  }

  .cx-cart-coupon-container {
    display: grid;
    width: 100%;

    .apply-coupon-button {
      border-radius: 0;
      border-color: var(--cx-color-primary);
      color: var(--cx-color-primary);
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 2px;
      border-width: var(--cx-buttons-border-width);
      border-style: solid;

      &:hover {
        background-color: var(--cx-color-primary);
        border-color: var(--cx-color-primary);
        color: white;
      }
    }

    @include media-breakpoint-down(sm) {
      display: block;
    }

    .apply-coupon-button {
      margin-bottom: 1rem;
    }
  }

  .cx-available-coupon {
    .title {
      font-weight: bold;
    }

    .message {
      display: flex;
      flex-wrap: wrap;
    }

    .scroll {
      display: block;
      height: auto;
      box-sizing: border-box;
      max-height: 240px;
      overflow-y: auto;
    }

    .card {
      @include type("7");
      display: inline-flex;
      align-items: flex-start;
      padding: var(--cx-padding, 0.25rem 0.25rem);
      word-break: break-all;
      margin-top: 0.25rem;
      margin-inline-end: 0.25rem;
      margin-bottom: 0;
      margin-inline-start: 0;
      background: var(--cx-color-background);
      border: 1px solid $light;
      border-radius: $border-radius;
    }
  }

  button.link {
    @include type("8");
    color: var(--cx-color-text);
    border-width: 0;
    text-decoration: none;
    background: var(--cx-color-background);
    &:hover {
      text-decoration: underline;
    }
  }
}
