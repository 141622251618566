cx-anonymous-consent-management-banner {
  .anonymous-consent-banner{
    left: 0;
    max-width: 100%;
  }
  .cx-banner-buttons{
    flex-wrap: wrap;
    @include media-breakpoint-down(sm) {
     .btn, button:last-child {
       margin: 16px 0 0 0;
       margin-inline-start: 0;
       width: 100%;
     }
    }
  }
}
