@mixin customAccordion () {
  > button {
    border-top: 1px var(--cx-color-light) solid;
    font-size: 18px;
    padding-top: 15px;
    padding-inline-end: 0;
    padding-bottom: 0;
    padding-inline-start: 15px;
    width: 100%;
    text-align: start;
    height: 63px;
    color: var(--cx-color-secondary);
    text-transform: uppercase;

    h2,
    h3 {    
      font-size: 18px;
      font-weight: 400;
      line-height: inherit;
    }

    &:before {
      font-family: 'icomoon';
      content: '\e973';
      margin: 15px;
      float: right;
      bottom: 12px;
      position: relative;
      color: var(--cx-color-text);
      transition: transform .3s;
    }
    
    &:after {
      margin-top: 15px;
      margin-inline-end: auto;
      margin-bottom: auto;
      margin-inline-start: -15px;
    }

    &.active {
      &:before {
        transform: rotate(180deg);
      }
    }

    &.active:after,
    &:hover:after {
      width: calc(100% + 15px);
    }

    &:last-of-type {
      border-bottom: 1px var(--cx-color-light) solid;
    }
  }

  > div {
    padding: 15px 5px;
  }
}

app-custom-tab-paragraph-container {
  margin-bottom: 20px;

  // On small devices we hide the toggled items
  // in order to allow for a more condensed layout
  @include media-breakpoint-down(sm) {
    > div.active.toggled {
      display: none;
    }
    > button.active.toggled {
      color: initial;
      &:before {
        content: '+';
      }
      &:after {
        width: 0;
        opacity: var(--cx-opacity, 0);
      }
    }
  }

  > div {
    &:not(.active) {
      display: none;
    }
    .container > h2 {
      display: none;
    }
  }
  > button {
    @include button-reset();
    margin-bottom: 0;
    &.active,
    &:hover {
      color: var(--cx-color-primary);
    }
    &:focus {
      z-index: 1;
    }
    cursor: pointer;

    // border effect
    &:after {
      content: '';
      display: block;
      margin-top: 4px;
      margin-inline-end: auto;
      margin-bottom: auto;
      margin-inline-start: auto;
      height: 5px;
      background: var(--cx-color-primary);

      // the tab hover effect uses a border in the :after pseudo
      // that is animated from 0% to 100% width
      width: 0;
      opacity: 0;
      transition: all 0.6s;
    }

    &.active:after,
    &:hover:after {
      width: 100%;
    }
    &.active:after,
    &.active:hover:after {
      opacity: 1;
    }
    &:not(.active):hover:after {
      opacity: 0.5;
    }
  }

  &.accordion {
    @include customAccordion;
    @extend .container !optional;

    > {
      button {
        border-top: none;

        &:last-of-type {
          border-bottom: none;
        }

        ~ button {
          margin-top: 10px;
        }
      }
    }
  }

  &:not(.accordion) {
    > button {
      color: var(--cx-color-primary);

      &:after {
        height: 1px;
        background: $color-theme-1;
      }

      h2,
      h3 {
        font-size: 16px;
      }

      &:hover,
      &.active {
        color: $color-theme-1;
      }

      &.active {
        cursor: initial;
      }
    }
  }

  // in order to align the tabs to the left
  // we simulate the container size
  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(4, auto);
  }

  &:not(.accordion) {
    grid-template-columns: auto repeat(2, 130px) auto;
    display: grid;

    > button {
      order: 0;
      transition: all 0.6s;
      font-size: 1.2rem;
      text-align: center;
      margin: 0;
      padding: 0 20px;
      margin-bottom: -1px;
      z-index: 1;

      &:first-of-type {
        grid-column: 2;
      }
    }

    // the div elements contain the content and will be rendered after the tabs
    > div {
      order: 2;
      grid-column-start: 1;
      grid-column-end: -1;
      padding: 30px 0;
      border-top: thin solid #8C9397;

      .container {
        padding: 0;
      }
    }
  }
}

.ProductDetailsPageTemplate cx-page-slot.Tabs {
  @include media-breakpoint-up (md) {
    max-width: 600px;
    margin: auto;
  }
}
