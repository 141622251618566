cx-order-consigned-entries {
  @extend %cx-order-overview !optional;

  .cx-order-summary {
    cx-card {
      @extend %cx-card !optional;

      &.cx-order-summary-alt .cx-card-body {
        @include media-breakpoint-down(sm) {
          flex-direction: row;
          align-items: baseline;
        }
      }

      &.cx-order-summary-order-number {
        .cx-card-label,
        .cx-card-title {
          font-size: 20px;
        }

        @include media-breakpoint-up(md) {
          .cx-card-body {
            margin-top: -2px;
          }
        }
      }

      .cx-card-title {
        font-size: 18px;
        text-transform: none;
        margin-bottom: 0;
        margin-top: 0;
        font-weight: bold;

        &:after {
          content: ':';
          margin-left: -3px;
          margin-right: 6px;
        }
      }

      .card-body {
        justify-content: start;
      }

      @include media-breakpoint-down(sm) {
        margin: 4px 0;

        .card-body {
          padding: 0;
        }
      }
    }

    @include media-breakpoint-up(md) {
      .container > * {
        flex-basis: 25%;
      }
    }

    @include media-breakpoint-down(sm) {
      background-color: var(--cx-color-background);

      > .container {
        padding: 15px 30px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    cx-cart-item-list .cx-info {
      grid-template-columns: 150px 300px 150px;
    }
  }

  .cx-order-summary {
    background-color: var(--cx-color-background);
  }

  cx-cart-item-list {
    .cx-item-list-row {
      padding: 1.25rem 0;
      border: solid #eae9e9;
      border-width: 0 0 1px;

      .cx-info {
        .cx-info-container {
          .cx-name {
            font-size: var(--cx-font-size, 1rem);
            font-weight: var(--cx-font-weight-bold);
            line-height: var(--cx-line-height, 1.2222222222);
            overflow-wrap: break-word;
            padding: 0;
            min-width: 14rem;

            .cx-link {
              text-decoration: none;
              color: var(--cx-color-text);
            }
          }
        }

        .cx-info-price-slim-price {
          font-weight: 700;
          display: block;
        }
      }

      .cx-total .cx-value {
        font-size: 20px;
        font-weight: 700;
    }
    }
  }
}
