app-custom-social-media-share {
  padding: 20px 0;

  @include media-breakpoint-up(lg) {
    padding: 20px;
  }

  .title {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 8px;
  }

  img {
    height: 17px;
    width: 17px;
  }

  ul {
    list-style: none;
    display: inline-flex;
    margin: 0;
    padding: 0;

    li + li {
      margin-left: 6px;
    }

    .btn {
      min-height: auto;
      min-width: auto;
      padding: 2px;
    }
  }
}
