.cx-card-label-bold{
    font-weight: bold;
}

.cx-card-border{
    border-color: #EAE9E9;
}
.card-body{
    border-color: #EAE9E9;
}


.cx-radio-card{
    
    position: relative;
    .form-check{
        position: absolute;
        left: 20px;
        z-index: 1;
    }
    .col{
        padding-left: 32px;
        padding-right: 0;
    }
    .cx-card-container{
        display: block;
        overflow: auto;
    }
}