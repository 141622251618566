cx-product-carousel {
  @extend %cx-product-carousel !optional;

  padding-bottom: 30px;

  cx-carousel {
    @extend %cx-carousel !optional;
  
    > {
      h3 {
        text-align: left;
        border-bottom: thin solid var(--cx-color-light);
        padding-bottom: 10px;
        color: $color-extra-9;

      + .carousel-panel {
          margin-top: 0;
        }
      }

      cx-generic-link {
        text-align: center;
        margin: 25px 0 35px;
      }
    }

    h4 {
      font-size: 20px;
      color: var(--cx-color-secondary);
      
      @include media-breakpoint-up(sm) {
        min-height: 40px;
        line-height: 1;
      }
    }

    .carousel-panel {
      margin-top: 30px;

      .btn-primary {
        width: 90%;
        align-self: center;
        padding: 6px;
        min-height: auto;
      }
    }

    a {
      &:hover,
      &:focus {
        text-decoration: none;
      }

      &:hover h4 {
        color: var(--cx-color-primary);
      }
    }

    cx-media {
      &:not(.is-missing) {
        height: 0;
        padding-bottom: 60%;

        img {
          max-height: none;
        }
      }

      &.is-missing {
        min-height: auto;
        width: 100%;
        padding-bottom: 60%;
      }
    }
  }

  // THEMES ONLY
  app-root.sparta & {
    .item > cx-generic-link {
      display: none;
    }
  }

  app-root.revolution & {
    cx-carousel {
      box-shadow: 0px 5px 24px #0000001E;
      margin-top: 32px;
      margin-bottom: 32px;

      .carousel-panel {
        .previous, .next {
          padding: 0 20px;

          .cx-icon {
            position: relative;
            top: -65px;
            font-size: 25px;
            font-weight: bold;
          }
        }
      }
    }


    h3 {
      text-align: center;
      border-bottom: none;
    }

    .item {
      > cx-generic-link {
        text-align: center;
        display: block;

        .btn {
          margin-bottom: 30px;
          padding-top: 5px;
          padding-bottom: 5px;
          min-height: auto;
        }
      }
    }

    .price {
      display: none;
    }
  }

  .container-product-carousel {
    max-width: var(--cx-page-width-max);
    box-shadow: 0px 5px 24px #0000001e;
    margin: 32px auto;
    background-color: white;
  }
}

