cx-cart-totals {
  @extend %cx-cart-totals !optional;
  padding: 15px;
  margin-left: 30px;
  text-transform: uppercase;
  background-color: $background;
  margin-bottom: 30px;

  h4 {
    font-size: 30px;
    padding-bottom: 5px;
    text-transform: uppercase;
    font-weight: var(--cx-font-weight-semi);
  }

  button.btn-primary {
    border-radius: 0;
    text-transform: uppercase;
    font-weight: 400;
  }
  
  .cx-summary-total {
    .cx-summary-amount {
      font-size: 22px;
    }
  }

  .cx-cart-slots-remain {
    color: var(--cx-color-secondary);
    font-weight: 600;
    margin: -8px 22px 12px;
    font-size: 15px;
    text-align: center;
    line-height: 18px;

    @include media-breakpoint-only(lg) {
      margin-left: 6px;
      margin-right: 6px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 12px;
      margin: 0 0 8px;
    }
  }

  @include media-breakpoint-down(md) {
    padding: 30px;
    margin-top: 30px;
    margin-left: 0;
  }

  @include media-breakpoint-down(sm) {
    padding: 0 0 1rem;
  }

  cx-order-summary {
    padding: 15px;
    display: block;
    margin: 0;

    @include media-breakpoint-down(md) {
      padding: 30px;
    }

    .cx-summary-row {
      padding: .5rem 0;
      display: flex;
      flex-wrap: wrap;
    }
  }

  cx-page-layout.ProductGridPageTemplate .Section2 & {
    .cx-summary-row {
      padding: 0;
    }

    h4 {
      font-size: 24px;
    }

    .cx-summary-partials {
      font-size: 15px;
    }

    cx-order-summary {
      padding-top: 0;
    }

    @include media-breakpoint-down(md) {
      border-top: thin solid white;
      width: 100%;
      position: fixed;
      bottom: 0;
      z-index: 1;
      margin-bottom: 0;
      padding: 10px;

      h4 {
        display: none;
      }

      cx-order-summary {
        padding: 2px 12px;

        .cx-summary-total {
          padding: 0;

          * {
            font-size: 16px;
          }
        }
      }
    }
    
    @include media-breakpoint-down(sm) {
      cx-order-summary {
        .cx-summary-row:not(.cx-summary-total) {
          display: none;
        }
      }
    }
  }

  app-custom-paypal-installments {
    .installments-container {
      margin-bottom: 15px;
      border: 1px solid $primary;
      border-radius: 5px;
      background-color: white;
      width: 100%;
      padding: 15px 15px 10px;

      @include media-breakpoint-down(lg) {
        margin-left: 10px;
        margin-right: 10px;
        width: auto;
      }
      @include media-breakpoint-down(md) {
        margin-left: 30px;
        margin-right: 30px;
        width: auto;
        }
      @include media-breakpoint-only(sm) {
        margin-bottom: 0;
      }
    }
  }

}
