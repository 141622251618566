cx-product-list-item {
  @extend %cx-product-list-item !optional;

  padding-bottom: 25px;

  .cx-product-name {
    font-size: 20px;
    color: var(--cx-color-secondary);
  }

  .cx-product-image-container {
    flex-grow: 1;
    text-decoration: none;
    position: relative;

    cx-media {
      height: 0;
      padding-top: 100%;

      img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  cx-media.is-missing {
    min-height: auto;
  }

  app-custom-product-labels-items-view {
    position: absolute;
    z-index: 1;
  }
}
