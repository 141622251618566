@use 'sass:math';

@mixin hideWrapperDesktop {
  display: none;
}

@mixin showWrapperDesktop {
  display: initial;
}

nav-wrapper {
  .wrapper {
    cursor: default;
  }

  // create width of wrapper
  .wrapper[attr="1"] {
    width: 200px;
  }

  .wrapper[attr="2"] {
    width: 400px;
  }

  &.flyout {
    .wrapper {
      @include media-breakpoint-down(md) {
        height: 0;
        overflow: hidden;
      }
      color: $secondary;
    }

    @include media-breakpoint-up(lg) {
      .wrapper {
        // we add a z-index since some of the elements on the page have (surprisingly) a z-index rule
        // we cannot make the z-index too high as it would conflict with the searchbox flyout.
        z-index: 20;
        color: $secondary;
      }
      > nav > .wrapper {
        padding: 15px 20px;
        background: #fff;
      }
    }
  }
}

%nav-back-button {
  &.flyout {
    .back {
      display: none;
    }

    @include media-breakpoint-down(md) {
      .back {
        display: initial;
        width: 100%;
        color: white;

        h5 {
          text-transform: uppercase;
          justify-content: flex-start;

          cx-icon {
            padding-inline-end: 10px;
          }
        }
      }
      &:not(.is-open) {
        .back {
          display: none;
        }
      }
    }
  }
}

%nav-heading {
  nav {
    &:focus {
      color: var(--cx-g-color-primary);
    }
  }

  h5 {
    margin: 0;
  }

  &.flyout {
    h5,
    cx-generic-link,
    a {
      font-size: 15px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 2px;
      display: flex;
      align-items: center;
      white-space: nowrap;
      color: $secondary;
      cursor: pointer;

      &:hover {
        color: var(--cx-color-primary);
      }

      &:focus {
        z-index: 1;
        position: relative;
      }

      a {
        display: block;
        width: 100%;

        &:focus {
          z-index: 1;
          position: relative;
        }
      }
    }

    @include media-breakpoint-down(md) {
      // make all first level hedings uppercase in mobile view
      > nav {
        > h5,
        > cx-generic-link {
          text-transform: uppercase;
          font-weight: 300;
          color: white;
        }
      }
      h5,
      cx-generic-link {
        border-bottom: none;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        color: white;
      }

      h5,
      cx-generic-link a {
        padding: 15px 15px 15px 30px;
        color: white;
      }
    }

    @include media-breakpoint-up(lg) {
      > nav {
        cursor: pointer;
        // top level headings
        > h5 {
          margin-top: 3px;
          padding-top: 20px;
          padding-inline-end: 15px;
          padding-bottom: 25px;
          padding-inline-start: 0;
        }
        > h5,
        > cx-generic-link h5 {
          font-size: 15px;

          &.user-menu {
            padding-right: 0;

            &:hover {
              ~ .wrapper {
                opacity: 1;
                @include showWrapperDesktop();
              }
            }
          }
        }

        // first level headings will be bold in desktop
        nav > h5,
        nav > cx-generic-link h5 {
          @include type('5');
          font-size: 14px;
          padding-bottom: 15px;

          &:hover {
            color: currentColor;
          }
        }
        nav > h5 {
          cursor: default;
        }
      }
    }
  }

  cx-generic-link.all {
    text-decoration: underline;
    display: none;
  }
}

@include media-breakpoint-down(md) {
  .user-menu {
    .icon-ico-user-color:before {
      color: white;
    }
  }
}

@include media-breakpoint-up(lg) {
  .HeaderLinks {
    position: relative;

    app-custom-navigation.flyout > nav,
    cx-navigation.flyout > nav {
      h5 {
        padding: 0;
      }

      .wrapper {
        top: 20px;
        width: 245px;
        left: auto;
        right: -40px;
        box-shadow: 0 5px 27px rgb(0 0 0 / 9%);
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  app-custom-navigation-ui > nav > cx-generic-link > a,
  cx-navigation-ui > nav > cx-generic-link > a {
    padding-top: 20px;
    padding-inline-end: 15px;
    padding-bottom: 22px;
    padding-inline-start: 0;
  }
  nav > div > cx-generic-link {
    padding: 10px 0;
  }
  div.childs > nav > cx-generic-link > a {
    padding: 5px 0;
  }
}

%nav-icon {
  cx-icon {
    &:before {
      font-size: 1.5rem;
    }
  }

  @include media-breakpoint-up(lg) {
    cx-icon {
      padding-inline-start: 7px;

      &:before {
        font-size: 1rem;
      }
    }
  }
}

%nav-childs {
  &.flyout {
    // the last level of navigations is tyically displayed in a column
    .childs[depth="1"] {
      flex-direction: column;
      display: flex;
    }

    .childs {
      @include media-breakpoint-up(lg) {
        .childs {
          flex-direction: column;
          display: flex;
          padding: 0;
        }
      }
    }
  }
}

%nav-links {
  a {
    color: currentColor;
  }

  &.flyout {
    a {
      display: block;
      text-decoration: none;
      white-space: nowrap;
    }
  }
}

%nav-wrap {
  @for $column from 1 through 10 {
    &.flyout .childs[columns="#{$column}"] {
      @if ($column > 1) {
        display: block;
      } @else {
        display: grid;
        &[depth="1"] {
          display: block;
        }
        .childs {
          display: flex;
          flex-direction: column;
        }
      }


      @include media-breakpoint-up(md) {
        column-count: $column;
      }
      @include media-breakpoint-down(sm) {
        column-count: ceil(math.div($column, 3));
        display: block;
      }
    }
  }
}

app-custom-navigation-ui,
cx-navigation-ui {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  
  @media (max-width: 991px) {
    flex-direction: column;
  }

  @extend %nav-heading;
  @extend %nav-icon;
  @extend %nav-wrapper;
  @extend %nav-childs;
  @extend %nav-links;
  @extend %nav-back-button;
  @extend %nav-wrap;

  nav {
    outline: none;
    @include media-breakpoint-down(md) {
      color: white;
    }
  }

  &.flyout {
    @include media-breakpoint-down(md) {
      flex-direction: column;
      color: var(--cx-color-secondary);
    }

    @include media-breakpoint-down(md) {
      &.is-open {
        .logout-link {
          color: white;
          padding-left: 30px;
          padding-top: 15px;
        }

        > nav {
          /*display: none;*/

          cx-generic-link.all {
            display: none !important;
          }
        }

        nav.is-open {
          display: initial;
          height: auto;

          > .wrapper {
            height: auto;
          }
        }

        nav.is-opened {
          display: initial;

          > .wrapper > .childs > nav {
            display: none;

            &.is-open {
              display: initial;
            }
          }

          > h5 {
            display: block;
          }

          > .wrapper {
            height: auto;
          }
        }
      }
    }

    @include media-breakpoint-up(lg) {
      // Show dropdowns via hover when no nav is focused
      > nav {
        > .wrapper {
          position: absolute;
          margin-inline-start: 0;
          border: none;
          width: 100%;
          left: 0;
          right: 0;
          opacity: 0;
          box-shadow: 0 4px 2px -2px rgb(0 0 0 / 9%);
          top: 125px; /*The default value is 90px;*/
          transition: opacity 0.8s;
          border-bottom: 1px solid $border-menu;
          padding: 20px;
          @include hideWrapperDesktop();
          z-index: 99;
          margin-top: 5px;

          &.wrapper-menu {
            width: 250px;
            right: -30px;
            opacity: 0;
            box-shadow: 0 5px 27px rgb(0 0 0 / 9%);
            top: 60px;
            transition: opacity 0.8s;
            left: auto;

            &:after {
              content: "";
              position: absolute;
              right: 40px;
              top: -15px;
              width: 0;
              height: 0;
              border-left: 15px solid transparent;
              border-right: 15px solid transparent;
              border-bottom: 15px solid white;
              clear: both;
            }
          }
        }

        // We only treat the first wrapper as a collapsible container
        &:hover {
          > .wrapper {
            opacity: 1;
            @include showWrapperDesktop();
          }
        }
      }

      // Show dropdowns via focus only once a nav is clicked
      &:focus-within {
        > nav {
          &:focus,
          &.is-open:focus-within {
            > .wrapper {
              @include showWrapperDesktop();
            }
          }
        }
      }

      .childs {
        grid-template-columns: repeat(4, 1fr);
        max-width: var(--cx-page-width-max);
        margin: 0 auto;
        display: grid;

        .childs {
          flex-wrap: wrap;
        }
      }

      .wrapper cx-icon {
        display: none;
      }
    }
  }
}
