cx-page-layout {
    app-box-item-categories{
        display: flex;
        flex-direction: column;
        overflow: hidden;
        position: relative;
        border: solid medium white;
        min-height: 300px;
        @extend .align-items-center !optional;
        @extend .justify-content-center !optional;
        
        cx-media, > cx-media.is-missing{
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            display: flex;
            position: absolute;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        
        .wrapper-text, .wrapper-link{
            display: flex;
            width: 100%;
            justify-content: center;
        }
        .wrapper-text{
            align-items: flex-end;
            height: 48px;
            font-weight: bold;
        }
        
        .wrapper-link{
            flex: auto;
            padding-top: 48px;
            align-items: center;
        }
        
        cx-generic-link {
            width: 65%;
            a {
                display: block;
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
                position: relative;
                color: white;
                font-size: 20px;
                font-weight: bold;
                line-height: 33px;
                text-align: center;
                text-transform: uppercase;
                padding: 12px;
                border: medium solid white;
                padding: 4px;            
                margin: 0 auto;
                &:hover, &:focus, &:active{
                    background-color: rgba(0,0,0,.3);
                    color: white;
                    text-decoration: none;
                    &:before{
                        background-image: inherit;
                        filter: blur(8px);
                        -webkit-filter: blur(8px);
                        -ms-filter: blur(8px);
                        filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='8');
                    }
                }
            }
            
            &:before{
                content: ' ';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: inherit;
                background-repeat: no-repeat;
                transform: scale(1.6);
            }
        }
        
        p{
            position: relative;
            font-size: 18px;
            color: white;
        } 
        
    }
}
