cx-promotions {
  @extend %cx-promotions !optional;

  .cx-promotions {
    font-size: var(--cx-font-size, 1rem) !important;
    color: var(--cx-color-text) !important;
    padding: 0 14px !important;
    background-color: $color-extra-7;
    
    p {
      font-weight: 400 !important;
      line-height: 1.6;
    }

    &:empty {
      margin: 0;
      padding: 0;
    }

    ul {
      padding: 0;
      margin-bottom: 4px;

      li {
        line-height: 1.6;
        font-weight: normal;
        padding: 6px 14px;
        background-color: transparent;
        margin-top: 6px;

        &:empty {
          margin: 0;
          padding: 0;
          background-color: transparent;

          &::before {
            margin: 0;
            padding: 0;
            background-color: transparent;
          }
        }
      }
    }
  }

  &.cx-promotions-applied .cx-promotions {
    background-color: $color-extra-7;
  }

  &.cx-promotions-applied .cx-promotions ul li {
    background-color: $color-extra-7;
  }
}


app-custom-promotions {
  width: 100%;

  .cx-promotions {
    font-size: var(--cx-font-size, 1rem);
    color: var(--cx-color-text);
    padding: 0 14px;
    background-color: $color-extra-7;
    
    p {
      font-weight: 400;
      line-height: 1.6;
      margin: 0;
      margin-block: 0;
      margin-inline: 0;
      display: flex;
    }

    ul {
      padding: 0;
      margin-bottom: 4px;

      li {
        line-height: 1.6;
        font-weight: normal;
        padding: 0 14px;
        background-color: transparent;
        margin-top: 6px;
        list-style: none;
      }
    }
  }

  &.cx-promotions-applied .cx-promotions {
    background-color: $color-extra-7;
  }

  &.cx-promotions-applied .cx-promotions ul li {
    background-color: $color-extra-7;
  }
}

.promotions-container {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}