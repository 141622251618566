@keyframes fadeInPlace {
  0% {
    opacity: 0;
    border-color: var(--cx-color-primary);
  }
  30% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.92);
  }
  65% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.96);
  }
  100% {
    opacity: 1;
    border-color: var(--cx-color-primary);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInMedium {
  from {
    opacity: 0;
  }
  to {
    opacity: .5;
  }
}

@keyframes appearFromLRight {
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spin .75s linear infinite;
  animation: spin .75s linear infinite;
}

.spinner-border-sm {
  width: 24px;
  height: 24px;
  border-width: 0.2em;
}

.fade-in {
  animation: fadeIn $defaultAnimationDuration;
}

.fade-in-place {
  animation: fadeInPlace $defaultAnimationDuration;
}
