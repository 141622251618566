.AccountPageTemplate {
  cx-page-layout {
    @extend .container !optional;
  }
  h2 {
    font-size: 30px;
    margin-bottom: 30px;
    font-weight: bold;
    text-transform: uppercase;

    &.page-title {
      @include media-breakpoint-down(md) {
        margin-top: 50px;
      }
    }
  }
  h5{
    font-weight: 700;
    font-size: 25px;
  }
  display: flex;
  .SideContent {
    flex: 25%;
    margin-top: 0;
    padding-left: 0;
    a {
      color: black;
      text-transform: uppercase;
      margin-bottom: 10px;
      display: block;
    }
  }
  .BodyContent {
    cx-promotion, app-custom-promotions {
      display: none;
    }
  }
  app-custom-my-account-edit-form{
    .info{
      text-transform: uppercase;
      margin-top: 30px;
      display: block;
      margin-bottom: 30px;
      font-weight: bold;
    }
    .radio-inline{
      label{
        margin-right: 10px;
        margin-bottom: 0;
        margin-top: 10px;
        font-size: 16px;
      }
      label, .form-check{
        display: inline-block;
      }
    }
  }
  .BodyContent {
    order: 1;
    margin-top: 0;
    app-custom-update-password {

      .label-content {
        text-transform: uppercase;
        margin-bottom: 10px;
        color: $color-extra-5;
      }
      .text-align {
        text-align: right;
        width: 100%;
        button{
          max-width: none;
        }
      }
    }
    .user-form {
      max-width: 100%;
    }
  }
  .action-name{
    display: none;
  }
  cx-order-details-actions{
    .cx-nav{
      div:last-child{
        display: none;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .action-icon{
      display: none;
    }
    .action-name{
      display: block;
    }
    flex-direction: column;
    h4{
      margin-top: 30px;
    }
    .BodyContent {
      margin: 0 15px;
      app-custom-update-password {
        .text-align {
          text-align: center;
        }
      }
    }
    .BodyContent,
    cx-update-password-form {
      padding: 0;
    }
    .SideContent {
      margin: 0 0 30px 20px;
      padding-top: 15px;
    }
  }
}
