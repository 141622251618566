quill-editor {
  min-height: 245px;
  display: flex;
  flex-direction: column;

  .ql-container {
    flex: auto;

    .ql-editor {
      min-height: 245px;
      max-height: 400px;
    }
  }
}

