.table {
  &-main {
    margin-bottom: 8px;
    
    @include media-breakpoint-up(md) {
      border-collapse: collapse;
    }

    thead {
      border: none;

      @include media-breakpoint-down(sm) {
        display: none;
      }

      th {
        text-transform: none;
        font-size: 20px;
        color: var(--cx-color-text);
      }
    }

    tbody {
      .table-main-label {
        min-width: 130px;
        font-weight: bold;
        padding-right: 6px;

        @include media-breakpoint-up(md) {
          display: none;
        }
      }

      tr {
        border: none;

        &:nth-of-type(odd) {
          background-color: var(--cx-color-background);
        }

        &:last-child:nth-of-type(even) {
          border-bottom: thin solid var(--cx-color-light);
        }
        td {
          a {
            color: var(--cx-color-text);
            text-decoration: underline;
          }
        }
      }
    }

    th,
    td {
      border-width: 0;
      padding: 14px;

      @include media-breakpoint-up(md) {
        text-align: center;

        &.table-main-cell-left {
          text-align: start;
        }

        &.table-main-cell-right {
          text-align: end;
        }
      }
    }

    td {
      @include media-breakpoint-down(sm) {
        width: 100%;
        display: flex;
        border-width: 0;
        padding: 0 1.25rem;

        &:first-child {
          padding-top: 1.25rem;
        }

        &:last-child {
          padding-bottom: 1.25rem;

          @include media-breakpoint-down(sm) {
            padding-bottom: 12px;
          }
        }
      }
    }
    .table-main-actions {
      text-align: end;

      .btn {
        + .btn {
          margin-left: 8px;
        }
      }

      button.btn[type='button'] {
        margin-bottom: 0;
        
        @include media-breakpoint-up(md) {
          padding: 10px 2px;
          min-width: auto;
        }
      }

      @include media-breakpoint-up(md) {
        width: 1px;
        white-space: nowrap;
      }

      @include media-breakpoint-down(sm) {
        justify-content: flex-end;
        margin-top: 6px;
      }
    }

    &-bottom {
      display: flex;
      justify-content: flex-end;
      padding: 1rem 0 1rem 0;
      margin: 0;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
    }

    &-pagination {
      @include media-breakpoint-down(sm) {
        margin: 0 auto;
      }
    }

    &.alternative {
      tbody {
        tr {
          &:nth-of-type(odd) {
            background-color: $highlight;
          }

          &:nth-of-type(even) {
            background-color: $alternative;
          }

          &:last-child:nth-of-type(even) {
            border-bottom: inherit;
          }
        }
      }
    }
    &.compress{
      th, td{
        font-size: 14px;
        padding: 4px;
      }
      tr:nth-of-type(2n) {
        border-bottom: solid 4px white;
      }
    }
    @include media-breakpoint-down(xs) {
      &:not(.compress) {
        tbody {
          .table-main-label {
            text-align: left;
          }
        }
        td {
          display: block;
          text-align: right;
        }
      }
    }
  }

  &-content {
    width: 100%;

    td {
      padding: 5px;
      border: 1px solid $light;
    }

    thead {
      color: $color-extra-5;
    }

    tbody {
      color: $light;
    }
  }
}
