// Added styles
.widgetcmsmodule {
  a {
    color: inherit;
    text-decoration: none;
  }
  strong {
    font-weight: 900;
  }
  .actions .action,
  .actions-toolbar .action,
  .box-actions .action,
  .checkout.methods .action,
  .product-item-actions .action {
    color: $secondary;
    background: 0 0;
    padding: 0 15px;
    font-family: $font-family-base;
    font-weight: 600;
    text-align: left;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .action.primary,
  .actions .action,
  .actions-primary .action,
  .actions-toolbar .action,
  .box-actions .action,
  .checkout.methods .action,
  .checkout.methods .primary,
  .primary .action,
  .product-item-actions .action {
    font-size: 16px;
    line-height: 20px;
    min-width: 140px;
    transform: translateZ(0);
    transition: all .2s;
    height: 48px;
    width: max-content;
    border: 2px solid $primary;
    font-weight: 400;
    white-space: nowrap;
    letter-spacing: 2.4px;
    text-transform: uppercase;
  }
  .action.primary,
  .actions-primary .action,
  .checkout.methods .primary,
  .primary .action {
    color: $secondary;
    background: 0 0;
    padding: 0 15px;
    font-family: $font-family-base;
    font-weight: 600;
    text-align: left;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .action.primary:hover,
  .actions-primary .action:hover,
  .checkout.methods .primary:hover,
  .primary .action:hover {
    color: #FFF;
    background: $primary;
    border: 2px solid $primary;
  }
  a.action.change-password,
  a.action.delete,
  a.action.edit {
    font-size: 20px;
    display: inline-block;
    padding: 13px 22px;
    border: 0;
    font-weight: 400
  }
  .action.primary.amcform-prev,
  .detail-booking-form .actions .action,
  .detail-contact-form .actions .action,
  .product-social-links .social-media-icons a {
    margin-right: 15px;
  }
  .detail-booking-form h4,
  .detail-contact-form h4 {
    font-size: 28px;
    text-transform: uppercase;
    font-family: $font-family-custom2;
    letter-spacing: 1px;
    color: $secondary;
  }
  .detail-booking-form h5,
  .detail-contact-form h5 {
    font-size: 22px;
    text-transform: uppercase;
    font-family: $font-family-custom1;
    letter-spacing: 1px;
    color: $color-extra-16;
  }
  .detail-booking-form iframe,
  .detail-contact-form iframe {
    width: 100%;
    height: 600px;
    border: none;
  }
  @media (max-width: 480px) {
    .detail-booking-form iframe,
    .detail-contact-form iframe {
      display:none;
    }
  }
  .detail-booking-form .form,
  .detail-contact-form .form {
    margin-top: 30px;
  }
  .detail-booking-form .form .amform-form .amcform-gdpr label:after,
  .detail-contact-form .form .amform-form .amcform-gdpr label:after {
    display: none;
    content: "";
  }
  .detail-booking-form .form .amform-form .ui-datepicker-trigger,
  .detail-contact-form .form .amform-form .ui-datepicker-trigger {
    display: none;
  }
  .detail-booking-form .address-title,
  .detail-contact-form .address-title {
    font-family: $font-family-base;
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
  }
  .detail-booking-form .actions,
  .detail-booking-form .hours,
  .detail-booking-form .phone,
  .detail-booking-form address,
  .detail-contact-form .actions,
  .detail-contact-form .hours,
  .detail-contact-form .phone,
  .detail-contact-form address {
    margin-bottom: 15px;
  }
  .detail-booking-form .address-subtitle,
  .detail-contact-form .address-subtitle {
    font-family: $font-family-base;
    font-weight: bold;;
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 0;
  }
  .detail-booking-form .actions,
  .detail-contact-form .actions {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: flex-start;
  }
  .detail-booking-form .actions .booking,
  .detail-contact-form .actions .booking {
    display: none;
  }
  @media (max-width: 480px) {
    .detail-booking-form .actions .booking,
    .detail-contact-form .actions .booking {
      display:flex;
    }
  }
}
// Imported styles
.bg-garnet .widgetcmsmodule-bg,
.extra-bg-garnet {
  background: $dark;
}
.bg-brown .widgetcmsmodule-bg,
.extra-bg-brown {
  background: $color-extra-12;
}
.bg-lightbrown .widgetcmsmodule-bg,
.extra-bg-lightbrown {
  background: $color-extra-11;
}
.bg-skin .widgetcmsmodule-bg,
.extra-bg-skin {
  background: $primary;
}
.bg-lightskin .widgetcmsmodule-bg,
.extra-bg-lightskin {
  background: $color-extra-1;
}
.bg-khaki .widgetcmsmodule-bg,
.extra-bg-khaki {
  background: #b98f53;
}
.bg-lightkhaki .widgetcmsmodule-bg,
.extra-bg-lightkhaki {
  background: #f9f6f1;
}
.bg-lightgreen .widgetcmsmodule-bg,
.extra-bg-lightgreen {
  background: #e7e8e0;
}
.bg-sand .widgetcmsmodule-bg,
.extra-bg-sand {
  background: #debc93;
}
.bg-lightsand .widgetcmsmodule-bg,
.extra-bg-lightsand {
  background: #f0e5de;
}
.bg-pink .widgetcmsmodule-bg,
.extra-bg-pink {
  background: #e0c9bd;
}
.bg-lightpink .widgetcmsmodule-bg,
.extra-bg-lightpink {
  background: $background;
}
.bg-gray .widgetcmsmodule-bg,
.extra-bg-gray {
  background: $light;
}
.bg-stone .widgetcmsmodule-bg,
.extra-bg-stone {
  background: $color-extra-5;
}
.bg-mediumgray .widgetcmsmodule-bg,
.extra-bg-mediumgray {
  background: $color-extra-16;
}
.bg-lightgray .widgetcmsmodule-bg,
.extra-bg-lightgray {
  background: #d8d8d8;
}
.extra-pt-20 {
  padding-top: 20px !important;
}
.extra-pb-20 {
  padding-bottom: 20px !important;
}
@media (max-width: 991px) {
  .extra-pt-20 {
    padding-top: 10px !important;
  }
  .extra-pb-20 {
    padding-bottom: 10px !important;
  }
}
.extra-pt-40 {
  padding-top: 40px !important;
}
.extra-pb-40 {
  padding-bottom: 40px !important;
}
@media (max-width: 991px) {
  .extra-pt-40 {
    padding-top: 20px !important;
  }
  .extra-pb-40 {
    padding-bottom: 20px !important;
  }
}
.extra-pt-60 {
  padding-top: 60px !important;
}
.extra-pb-60 {
  padding-bottom: 60px !important;
}
@media (max-width: 991px) {
  .extra-pt-60 {
    padding-top: 30px !important;
  }
  .extra-pb-60 {
    padding-bottom: 30px !important;
  }
}
.extra-pt-80 {
  padding-top: 80px !important;
}
.extra-pb-80 {
  padding-bottom: 80px !important;
}
@media (max-width: 991px) {
  .extra-pt-80 {
    padding-top: 40px !important;
  }
  .extra-pb-80 {
    padding-bottom: 40px !important;
  }
}
.owlwidgetcmsmodule .widget > div > h2,
.widgetcmsmodule .widget > div > h2 {
  font-size: 32px;
  margin: 0 0 32px;
  color: $dark;
  text-align: center;
  letter-spacing: 1px;
}
.owlwidgetcmsmodule .widget.img-right > div > h2,
.widgetcmsmodule .widget.img-right > div > h2 {
  text-align: center;
}
.widgetcmsmodule {
  position: relative;
}
.widgetcmsmodule .row {
  display: flex;
  flex-wrap: wrap;
}
.widgetcmsmodule .row.in-container {
  margin: 0 auto;
  justify-content: center;
  max-width: 1320px;
}
@media (min-width: 768px) {
  .widgetcmsmodule .img-right {
    justify-content: center;
  }
  .widgetcmsmodule .img-right div.widgetcmsmodule-image {
    order: 1;
  }
}
.widgetcmsmodule .img-left {
  justify-content: flex-start;
}
.widgetcmsmodule .img-left div.widgetcmsmodule-image {
  order: 0;
}
.widgetcmsmodule .img-left div.widgetcmsmodule-content {
  order: 1;
}
.widgetcmsmodule .widgetcmsmodule-bg {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}
.widgetcmsmodule div[class*="col"],
.widgetcmsmodule.container-full div[class*="col"] {
  position: relative;
}
.widgetcmsmodule .bg-full .widgetcmsmodule-bg {
  height: 100%;
}
.widgetcmsmodule .bg-margin .widgetcmsmodule-bg {
  height: calc(100% - 40px);
}
.widgetcmsmodule div[class*="col"].widgetcmsmodule-content {
  justify-content: center;
  flex-flow: column;
  display: flex;
  padding-bottom: 15px;
  padding-top: 30px;
}
@media (max-width: 768px) {
  .widgetcmsmodule .bg-margin .widgetcmsmodule-bg {
    height: 100%;
  }
  .widgetcmsmodule div[class*="col"].widgetcmsmodule-content {
    padding: 15px;
  }
}
.widgetcmsmodule div[class*="col"].widgetcmsmodule-content .widgetcmsmodule-content-full {
  max-width: 100%;
  width: 660px;
  padding: 60px;
}
@media (max-width: 991px) {
  .widgetcmsmodule div[class*="col"].widgetcmsmodule-content .widgetcmsmodule-content-full {
    padding: 15px;
    width: 100%;
  }
}
.widgetcmsmodule div[class*="col"].widgetcmsmodule-image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 400px;
}
.widgetcmsmodule div[class*="col"].widgetcmsmodule-image > div {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
@media (max-width: 767px) {
  .widgetcmsmodule div[class*="col"].widgetcmsmodule-content {
    order: 1;
  }
  .widgetcmsmodule div[class*="col"].widgetcmsmodule-image {
    order: 2;
  }
  .widgetcmsmodule div[class*="col"].widgetcmsmodule-image .widgetcmsmodule-topimage {
    display: none;
  }
}
.widgetcmsmodule div[class*="col"].widgetcmsmodule-image .widgetcmsmodule-topimage img {
  width: auto;
  height: auto;
  max-height: 100%;
}
.widgetcmsmodule.container-full div[class*="col"].widgetcmsmodule-content {
  justify-content: flex-start;
  flex-flow: row;
  display: flex;
}
@media (max-width: 768px) {
  .widgetcmsmodule.container-full div[class*="col"].widgetcmsmodule-content {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.widgetcmsmodule .img-right.cl.extramargin .widgetcmsmodule-content {
  justify-content: flex-end;
  padding-right: 120px;
  padding-left: 15px;
}
@media (min-width: 768px) {
  .widgetcmsmodule .img-right.cl.extramargin .widgetcmsmodule-content .widgetcmsmodule-content-full {
    padding-right: 60px;
  }
}
@media (max-width: 767px) {
  .widgetcmsmodule .img-right.cl.extramargin .widgetcmsmodule-content .widgetcmsmodule-content-full {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.widgetcmsmodule .tl .widgetcmsmodule-topimage {
  position: absolute;
  top: -20px;
  left: -20px;
}
.widgetcmsmodule .t0l0 .widgetcmsmodule-topimage {
  position: absolute;
  top: 0;
  left: 0;
}
.widgetcmsmodule .tr .widgetcmsmodule-topimage {
  position: absolute;
  top: -20px;
  right: -20px;
}
.widgetcmsmodule .t0r0 .widgetcmsmodule-topimage {
  position: absolute;
  top: 0;
  right: 0;
}
.widgetcmsmodule .bl .widgetcmsmodule-topimage {
  position: absolute;
  bottom: -20px;
  left: -20px;
}
.widgetcmsmodule .b0l0 .widgetcmsmodule-topimage {
  position: absolute;
  bottom: 0;
  left: 0;
}
.widgetcmsmodule .br .widgetcmsmodule-topimage {
  position: absolute;
  bottom: -20px;
  right: -20px;
}
.widgetcmsmodule .b0r0 .widgetcmsmodule-topimage {
  position: absolute;
  bottom: 0;
  right: 0;
}
.widgetcmsmodule .ct .widgetcmsmodule-topimage {
  position: absolute;
  top: -20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}
.widgetcmsmodule .cb .widgetcmsmodule-topimage {
  position: absolute;
  bottom: -20px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
}
.widgetcmsmodule .cl .widgetcmsmodule-topimage {
  position: absolute;
  top: 0;
  left: -20px;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.widgetcmsmodule .cl.extramargin .widgetcmsmodule-topimage {
  left: -60px;
}
.widgetcmsmodule .cr .widgetcmsmodule-topimage {
  position: absolute;
  top: 0;
  right: -20px;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.widgetcmsmodule .widgetcmsmodule-headline {
  font-family: $font-family-base;
  font-weight: 600;
  font-size: 18px;
  color: $primary;
  letter-spacing: 3.5px;
  line-height: 20px;
  text-transform: uppercase;
}
.widgetcmsmodule .widgetcmsmodule-title {
  font-family: $font-family-custom2;
  font-size: 36px;
  color: $dark;
  letter-spacing: -0.2px;
  line-height: 40px;
}
@media (max-width: 992px) {
  .widgetcmsmodule .widgetcmsmodule-title {
    font-size: 32px;
    line-height: 38px;
  }
  .widgetcmsmodule .widgetcmsmodule-text,
  .widgetcmsmodule li {
    padding-left: 0;
  }
}
@media (max-width: 768px) {
  .widgetcmsmodule .widgetcmsmodule-title {
    font-size: 28px;
    line-height: 32px;
  }
}
.widgetcmsmodule ul {
  list-style-type: none;
  margin-left: 15px;
}
.widgetcmsmodule ul li:before {
  content: "-";
  position: absolute;
  margin-left: -15px;
}
.owlwidgetcmsmodule,
.widgetcmsmodule.column-widget div[class*="col"] {
  position: relative;
}
.widgetcmsmodule .widgetcmsmodule-text,
.widgetcmsmodule li {
  font-family: $font-family-base;
  font-size: 18px;
  color: $dark;
  letter-spacing: 1px;
  line-height: 22px;
  margin-bottom: 45px;
  margin-top: 45px;
}
.widgetcmsmodule .widgetcmsmodule-text.ordered-after,
.widgetcmsmodule li.ordered-after {
  margin-bottom: 5px;
}
.widgetcmsmodule .widgetcmsmodule-text a,
.widgetcmsmodule li a {
  color: $primary;
}
.widgetcmsmodule .widgetcmsmodule-button {
  width: max-content;
  width: -moz-max-content;
}
.widgetcmsmodule .widgetcmsmodule-button a {
  text-align: left;
  min-width: 140px;
  -webkit-backface-visibility: hidden;
  transform: translateZ(0);
  white-space: nowrap;
  width: max-content;
  border: 2px solid $primary;
  transition: all 0.2s;
}
.widgetcmsmodule .widgetcmsmodule-button a {
  color: $dark;
  background: 0 0;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.widgetcmsmodule .widgetcmsmodule-button a:hover {
  color: #fff;
  background: $primary;
  border: 2px solid $primary;
}
.widgetcmsmodule .small-title .widgetcmsmodule-title {
  font-family: $font-family-custom1;
  font-size: 36px;
  letter-spacing: 1px;
  line-height: 38px;
}
.widgetcmsmodule .extra-title .widgetcmsmodule-title {
  font-size: 84px;
  letter-spacing: -0.38px;
  line-height: 84px;
}
@media (max-width: 992px) {
  .widgetcmsmodule .widgetcmsmodule-button {
    padding-left: 0;
  }
  .widgetcmsmodule .extra-title .widgetcmsmodule-title {
    font-size: 52px;
    line-height: 56px;
  }
}
.widgetcmsmodule .center-right .widgetcmsmodule-image {
  background-position: center right !important;
}
.widgetcmsmodule .center-left .widgetcmsmodule-image {
  background-position: center left !important;
}
.widgetcmsmodule .size-contain .widgetcmsmodule-image {
  background-size: contain !important;
}
.widgetcmsmodule.column-widget div[class*="col"].widgetcmsmodule-content {
  flex-flow: column;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: flex-start;
  padding-bottom: 15px;
}
@media (max-width: 768px) {
  .widgetcmsmodule.column-widget div[class*="col"].widgetcmsmodule-content {
    padding: 15px;
  }
}
@media (max-width: 480px) {
  .widgetcmsmodule.column-widget div[class*="col"].widgetcmsmodule-content {
    padding: 15px;
  }
  .widgetcmsmodule.column-widget div[class*="col"].widgetcmsmodule-content .widgetcmsmodule-content-column {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: flex-start;
  }
}
.widgetcmsmodule.column-widget div[class*="col"].widgetcmsmodule-content .widgetcmsmodule-content-column {
  width: 100%;
  padding: 0 0 15px;
}
@media (max-width: 768px) {
  .widgetcmsmodule.column-widget div[class*="col"].widgetcmsmodule-content .widgetcmsmodule-content-column {
    padding: 0;
  }
}
.widgetcmsmodule.column-widget div[class*="col"].widgetcmsmodule-content p:not(.widgetcmsmodule-headline) {
  width: 100%;
}
.widgetcmsmodule.column-widget div[class*="col"].widgetcmsmodule-content li,
.widgetcmsmodule.column-widget div[class*="col"].widgetcmsmodule-content p:not(.widgetcmsmodule-headline) {
  font-size: 16px;
  line-height: 22px;
  color: $color-extra-5;
  margin-top: 5px;
  margin-bottom: 15px;
}
.widgetcmsmodule.column-widget div[class*="col"].widgetcmsmodule-content li strong,
.widgetcmsmodule.column-widget div[class*="col"].widgetcmsmodule-content p:not(.widgetcmsmodule-headline) strong {
  color: $dark;
}
@media (max-width: 480px) {
  .widgetcmsmodule.column-widget div[class*="col"].widgetcmsmodule-content li,
  .widgetcmsmodule.column-widget div[class*="col"].widgetcmsmodule-content p:not(.widgetcmsmodule-headline) {
    height: auto !important;
    width: auto !important;
  }
}
.widgetcmsmodule.column-widget div[class*="col"].widgetcmsmodule-content .widgetcmsmodule-content-column.key-data {
  text-align: center;
}
.widgetcmsmodule.column-widget
  div[class*="col"].widgetcmsmodule-content
  .widgetcmsmodule-content-column.key-data
  h3.widgetcmsmodule-title,
.widgetcmsmodule.column-widget
  div[class*="col"].widgetcmsmodule-content
  .widgetcmsmodule-content-column.key-data
  p.widgetcmsmodule-headline,
.widgetcmsmodule.column-widget
  div[class*="col"].widgetcmsmodule-content
  .widgetcmsmodule-content-column.key-data
  p.widgetcmsmodule-text {
  margin-bottom: 5px;
  margin-top: 0;
}
.widgetcmsmodule.column-widget
  div[class*="col"].widgetcmsmodule-content
  .widgetcmsmodule-content-column.key-data
  h3.widgetcmsmodule-title {
  font-size: 48px;
  margin-bottom: 15px;
}
.widgetcmsmodule.column-widget
  div[class*="col"].widgetcmsmodule-content
  .widgetcmsmodule-content-column.key-data
  p.widgetcmsmodule-headline {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 3px;
  height: 36px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
}
.widgetcmsmodule.column-widget
  div[class*="col"].widgetcmsmodule-content
  .widgetcmsmodule-content-column.key-data
  p:not(.widgetcmsmodule-headline) {
  color: $background;
}
.widgetcmsmodule.column-widget
  div[class*="col"].widgetcmsmodule-content
  .widgetcmsmodule-content-column.key-data.darkbg
  h3.widgetcmsmodule-title {
  color: #fff;
}
.extra-bg-lightkhaki
  .widgetcmsmodule.column-widget
  .widgetcmsmodule-content-column.key-data
  p:not(.widgetcmsmodule-headline) {
  color: $color-extra-5 !important;
}
.extra-bg-lightgreen
.widgetcmsmodule.column-widget
.widgetcmsmodule-content-column.key-data
p:not(.widgetcmsmodule-headline) {
  color: $color-extra-5 !important;
}
@media (max-width: 767px) {
  .hide-cta-mobile .widgetcmsmodule-button {
    display: none;
  }
}
.widgetcmsmodule-content-column > .widgetcmsmodule-content {
  padding: 0;
}
@media (max-width: 768px) {
  .widgetcmsmodule > .widgetcmsmodule-text {
    padding-bottom: 15px;
  }
}
.graph-image {
  text-align: center;
}
@media (min-width: 1200px) {
  .graph-image img {
    max-width: 70%;
  }
}
.widgetcmsmodule-content ul.inline-images {
  width: 100%;
  margin: 0;
  list-style: none;
}
.widgetcmsmodule-content ul.inline-images li {
  float: left;
  display: inline-block;
  clear: none;
  width: 23%;
  margin-right: 2%;
  max-width: 150px;
}
.widgetcmsmodule-content ul.inline-images li:before {
  content: "";
}
.widgetcmsmodule-content ul.inline-images li img {
  width: 100%;
  max-width: 100%;
}
.widgetcmsmodule-content [role="tablist"] [role="tab"]:before {
  font-family: Linearicons;
  content: "\e93a";
  line-height: 22px;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  color: $light;
}
.widgetcmsmodule-content [role="tablist"] [role="tab"] [data-role="trigger"] span {
  font-family: $font-family-base;
  font-size: 12px;
  color: $dark;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  transition: all 0.2s;
  line-height: 2em;
  font-weight: bold;
}
.widgetcmsmodule-content [role="tab"][aria-expanded="true"]:before {
  content: "\e939";
}
.widgetcmsmodule-content a.download {
  font-family: $font-family-base;
  letter-spacing: 1px;
  color: $dark;
  display: block;
  font-size: 14px;
  margin: 5px 0 0;
  float: left;
  clear: both;
  line-height: 16px;
  font-weight: bold;
}
.widgetcmsmodule-content a.download:after {
  content: "";
  width: 0;
  display: block;
  border-bottom: 1px solid $color-extra-11;
  padding-top: 0;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}
.widgetcmsmodule-content a.download:hover {
  color: $color-extra-11;
}
.widgetcmsmodule-content a.download:hover:after {
  width: 98%;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}
.widgetcmsmodule.column-widget h3.widgetcmsmodule-title {
  margin-bottom: 15px;
}
.widgetcmsmodule.column-widget .widgetcmsmodule-text {
  font-size: 18px;
  line-height: 22px;
  color: $color-extra-12;
}
