cx-order-details-items {
  @extend %cx-order-overview !optional;

  h4.cx-checkout-title {
    margin-top: 30px;
    color: $color-extra-5;
    text-transform: uppercase;
    font-size: 1.125rem;
  }

  app-custom-gift-cards-details{
    cx-media {
      display:  flex;
      margin-right: 16px;
      max-width: 168px;
      img {
        object-fit: contain;
      }
    }
    @include media-breakpoint-up(md) {
      .wrapper-gift{
        display: flex;
      }
    }
  .details {
     width: 100%;
   }
  }

  margin-top: 30px;
  margin-bottom: 15px;

  .cx-promotions {
    font-size: var(--cx-font-size,1rem);
    font-weight: var(--cx-font-weight-semi);
    line-height: var(--cx-line-height,1.2222222222);
    color: var(--cx-color-text);
    padding: 0;

    p {
      line-height: 1.6 !important;
      font-weight: 400 !important;
      padding: 6px 14px;
      background-color: #d6e9c8;
      margin-top: 6px;
      margin-bottom: 5px;
    }
  }

  .cx-list {
    cx-order-consigned-entries {
      .cx-list {
        .cx-order-summary {
          .container {
            display: flex;
            flex-direction: column;

            @media (min-width: 768px) {
              flex-direction: row;
            }
            
            .cx-order-summary-alt {
              .cx-card {
                .card-body {
                  display: flex;
                  flex-direction: column;
                }
              }
            }
          }
        }
      } 
    }
  }
  
}

