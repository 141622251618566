cx-order-confirmation-totals {
  @extend %cx-order-confirmation-totals !optional;
  margin-top: 30px;
  margin-bottom: 30px;

  cx-order-summary {
    padding: 15px;
    text-transform: uppercase;
    background-color: var(--cx-color-background);

    h4 {
      font-size: 30px;
      margin: 0 0 5px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  @include media-breakpoint-down(md) { 
    > .row {
      margin-inline-end: 0;
      margin-inline-start: 0;
    }
  }
}
