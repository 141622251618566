cx-page-layout.ProductDetailsPageTemplate {
  @extend %ProductDetailsPageTemplate !optional;

  cx-page-slot {
    &.Summary {
      @include media-breakpoint-down(md) {
        app-custom-product-labels {
          position: relative;
        }
      }
    }
  }
}
