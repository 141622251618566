app-custom-products-searchbox {

  .searchbox {
    margin-bottom: 0;

  }

  > * {
    @include media-breakpoint-up(md) {
      background-color: var(--cx-color-transparent);
    }
  }

  .cx-icon, cx-icon {
    font-size: 20px;
  }

  label {
    width: auto;
    max-width: none;
    min-width: auto;
    padding-bottom: 7px;
  }

  .form-control {
    margin-bottom: 0;
  }

  .products-results {
    width: 100%;
    box-shadow: 0 5px 27px rgb(0 0 0 / 9%);
    max-height: 254px;
    overflow: auto;

    .result {
      cursor: pointer;
      padding: 8px 4px;

      &:hover {
        background: var(--cx-color-light);
      }
    }

    .facets {
      .divider {
        background-color: #ebebeb;
        margin-bottom: 0;
        padding: 4px 8px;
      }
    }

    .products {
      display: block;
      border: thin solid var(--cx-color-ghost);

      .result {
        border-top: solid thin var(--cx-color-light);
      }

      .has-media {
        display: grid;
        grid-template-columns: 50px 1fr;
      }

      cx-media {
        grid-row: 1/3;
      }

      .name {
        text-overflow: ellipsis;
        font-size: inherit;
        font-weight: inherit;
        white-space: nowrap;
        overflow: hidden;
      }

      .price {
        font-weight: normal;
      }
    }
  }

  .custom-product-list-input {
    position: relative;
    padding-bottom: 0;

    cx-icon {
      position: absolute;
      transform: translateY(-50%);
      top: 50%;

      &.prepend {
        left: 0;
        margin-left: 12px;
      }

      &.append {
        right: 0;
        margin-right: 12px;
      }

    }

    input {
      padding-left: 36px;
      padding-right: 44px;
      margin-bottom: 0;
    }

  }
}
