// SPARTA v0.1
// This file is for theme configuration. These variables are used in global and component CSS files.
//
// You can:
//   1) Set new values for Bootstrap variables - https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss
//   2) Set new values for cxbase variables - cxbase/_variables.scss
//   3) Set new values for component variables - app/__/_.scss
// You cannot:
//   1) Add new variables

//fonts (see _fonts.scss to import)

// change theme-colors here
$primary: #899064 !default;
$secondary: #2D2926 !default;
$success: #5dac06 !default;
$danger: #db0002 !default;
$warning: #ffc107 !default;
$info: #17a2b8 !default;
$light: #A89F9A !default;
$highlight: #FFEBD7 !default;
$alternative: #ffccb8  !default;
$dark: #2D2926 !default;
$background: #E7E8E0 !default;
$inverse: #ffffff !default;
$text: #000000 !default;
$transparent: transparent !default;
$visual-focus: #6d9df7 !default;
$background-focus: rgba(80, 176, 244, 0.1);
$color-social-media: #899064;

//This theme only colors
$color-theme-1: #3F474C;
$color-theme-2: #8C9397;
$color-theme-3: #bd8543;

//type — https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss#L225
$font-size-base: 1rem; //16px
// $h1-font-size: $font-size-base * 2.375; //38px
$h1-font-size: $font-size-base * 1.85; //36px
$h2-font-size: $font-size-base * 1.75; //28px
$h3-font-size: $font-size-base * 1.375; //22px
$h4-font-size: $font-size-base * 1.125; //18px
$h5-font-size: $font-size-base; //16px
$small-font-size: $font-size-base * 0.875; //14px

// GLOBAL VARIABLE OVERRIDES
// These can be set with anything above or any variables exposed in Bootsrap or cxbase
$grid-gutter-width: 30px;

$line-height-base: 1.6;
$headings-line-height: 1.222222222222222;

$paragraph-margin-bottom: 1.5rem;
$border-radius: 0.25rem;

$btn-padding-y: 14px;

$input-height: 48px;
$input-btn-focus-box-shadow: none;
$input-padding-y: 0.6875rem;
$label-margin-bottom: 0.375rem;
$alert-padding-y: 20px;
$alert-border-radius: 0;
$input-bg-color: #f5f5f5;

$custom-select-bg: color('white');

$defaultAnimationDuration: .45s;

// COMPONENT VARIABLE OVERRIDES
// For example, the following would override the cx-storefront background set
// in src/app/ui/layout/main/main.component.scss
// $cx-storefront-background-color: "info";
$spinWhileLoading: true !default;
$spinWhileWaiting: true !default;
$zoomThumbOnHover: true !default;
$overlayWhileLoading: true !default;

// OTHER
$hamburger-layer-color: $secondary;
$hamburger-active-layer-color: #FFFFFF;
$border-menu: #eae9e9;
$notice-text-align: left;
$notice-color: #999;
$btn-border-radius: 300px;
$btn-letter-spacing: normal;
$btn-text-transform: none;
$btn-padding-sides: 30px;
$btn-custom-font-size: 18px;

// PDP
$pdpCarouselTitleAlign: center;
$pdpCarouselTitleBgColor: transparent;
$pdpTitleTextTransform: uppercase;
$pdpTitleBorderBottom: none;
$pdpTitleSize: 20px;
$pdPriceSize: 20px;
$pdpBrandsDisplay: none;

// COMPONENTS
$compCarouselBannerSliderJustifyContent: center;
$compCarouselBannerSliderTextAlign: center;
$compCarouselBannerSliderFontWeight: 500;
$compCarouselBannerSliderLineHeight: 1.2;
$compCarouselBannerSliderLineHeightMd: 1.2;
$compCarouselBannerSliderLineHeightLg: 1.2;
$compCarouselBannerSliderLetterSpace: normal;
$compCarouselBannerSliderBtnPadding: 12px 40px;
$compCarouselBannerSliderFontFamily: $font-family-teodor;
$compImageInfoSubtitleColor: $primary;
$compImageInfoContainerTitleColor: $color-theme-1;
$compProductCategoriesTitleColor: $color-theme-2;

// Extra colors
$color-extra-1: #F9E5CE;
$color-extra-2: #FCF3E9;
$color-extra-3: #faebcc;
$color-extra-4: #B98F53;
$color-extra-5: #7E7069;
$color-extra-6: #999999;
$color-extra-7: #D6E9C8;
$color-extra-8: #e9ecef;
$color-extra-9: #8d6435;
$color-extra-10: #F0E5DE;
$color-extra-11: #be7239;
$color-extra-12: #66443b;
$color-extra-13: #f9f6f1;
$color-extra-14: #debc93;
$color-extra-15: #D8D8D8;
$color-extra-16: #ADA39D;
$grey-light: #F6F6F6;

// Emarsys colors
$emarsys-osborne: #411718;

$emarsys-hover: #000000;
$emarsys-readonly: #E2E1E5;
$emarsys-placeholder: #D2D2D2;
$emarsys-subscribe: rgba($emarsys-hover, 0.5);

$emarsys-bodegas: #9BC198;
$emarsys-bodegas-hover: #8FD6AA;
$emarsys-bodegas-readonly: rgba($emarsys-bodegas, 0.5);

$emarsys-gold: #D6B95C;
$emarsys-gold-logo: #F2F2E6;
$emarsys-gold-hover: #F3D67B;
$emarsys-gold-text: #291902;
$emarsys-gold-readonly: rgba($emarsys-gold, 0.5);

$emarsys-brandy: #D6B35D;
$emarsys-brandy-hover: #FF761E;
$emarsys-brandy-readonly: rgba($emarsys-brandy, 0.5);

$emarsys-nordes: #1A2674;
$emarsys-nordes-readonly: rgba($emarsys-nordes, 0.5);

$emarsys-sanchez: #005C5D;
$emarsys-sanchez-title: #DDA594;
$emarsys-sanchez-hover: #D3DED6;
$emarsys-sanchez-readonly: rgba($emarsys-sanchez, 0.5);

$emarsys-11nudos: #262755;

$emarsys-riofrio: #142C46;

//FOOTER
$footerTopBackground: white;

//COMPONENTS
$compNewsletterTitleColor: $primary;
$compNewsletterBorderColor: $primary;
$compNewsletterTableBorderColor: $primary;
$compInfoTitleDisplay: none;
$compInfoBackground: $inverse!important;
$compInfoSizeImg: 57px;
$compInfoGridMobile: 1fr 1fr;
$compTitleColor: $primary;
$compTitleSize: 16px;
$compTitleWeight: 100;
$compTitleSpacing: 0;

// Grid overrides
$grid-breakpoints: (//min-widths; change according to custom-layout ts
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1320px
) !default;

$container-max-widths: (//change according to breakpoints
    sm: 768px,
    md: 992px,
    lg: 1200px,
    xl: 1320px//if changed, also change --cx-page-width-max in _root.scss
) !default;
