.custom-list-filter {
    .form-group {
        @include media-breakpoint-up(lg) {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: flex-end;

            &:last-child {
                margin-bottom: 20px;
            }

            .form-control {
                display: inline-block;
                width: auto;
                vertical-align: middle;
                margin-bottom: 0;
                width: 180px;
            }
            
            label {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 0;

                .label-content {    
                    margin: 0 6px 0;
                }

                + label,
                + button {
                    margin-left: 16px;
                }
            }
        }

        @include media-breakpoint-down(md) {            
            text-align: right;
            margin-bottom: 12px;

            .form-control {
                margin-bottom: 0;
            }

            label {
                &:nth-of-type(1) {
                    text-align: left;
                }
            }
            
            > button {
                min-height: auto;
                height: auto;
                line-height: normal;
                padding: 6px 0;
                font-size: 14px;
            }

            &.form-group-range {
                display: grid;
                grid-template-columns: repeat(2, 48%);
                grid-column-gap: 4%;

                > button {
                  grid-column: 1/span 2;
                  justify-self: end;
                }

                &:not(.filter-specific) {
                  > label{
                    align-self: end;
                  
                    &:nth-of-type(1) {
                      grid-column: 1;
                    }
                    &:nth-of-type(2) {
                      grid-column: 2;
                    }
                  }
                }

                &.filter-specific {
                  > label:nth-of-type(1) {
                    grid-column: 1/span 2;
                    grid-row: 1;
                  }
                  > label:nth-of-type(2) {
                    grid-column: 1;
                    grid-row: 2;
                  }
                  > label:nth-of-type(3) {
                    grid-column: 2;
                    grid-row: 2;
                  }
                }
            }
        }
        
        @include media-breakpoint-down(xs) {  
            .form-control {
                &[type=date] {
                    padding-right: 4px;
                    font-size: 14px;
                }
            }

            &.form-group-range {
                grid-template-columns: repeat(2, 49%);
                grid-column-gap: 2%;
            }
        }
    }
}

cx-sorting.normalize-width {
  min-width: 180px;
}

.custom-list-filter-no-order {
  margin: 40px 30px 30px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  min-height: 60px;
}

.custom-list-filter-header {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  margin: 0;

  > div:first-child {
    padding: 0;
  }

  @include media-breakpoint-up(lg) {
    align-items: flex-end;

    > div:first-child:not(:last-child) {
      text-align: right;
      padding-bottom: 16px;
    }

    .form-check {
      display: inline-block;
      margin-bottom: 0;

      + .form-check {
        margin-left: 28px;
      }
    }
  }

  .custom-list-filter-header-sort {
    padding: 0;
    margin-bottom: 0;

    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    padding-top: 0;
  }
}
