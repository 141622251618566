cx-anonymous-consent-open-dialog {
  @extend %cx-anonymous-consent-open-dialog !optional;
  
  .btn-link {
    color: currentColor;
    &:hover {
      color: var(--cx-color-primary);
    }
  }
}
