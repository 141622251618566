cx-review-submit {
  @extend %cx-review-submit !optional;

  .cx-review-summary {
    .cx-review-shipping-col,
    .cx-review-payment-col {
      div.cx-review-summary-card {
        @include media-breakpoint-down(md) {
          background-color: transparent;
        }

        &.cx-review-card-address,
        &.cx-review-card-shipping {
          @include media-breakpoint-down(md) {
            background-color: transparent;
          }
        }
      }
    }
    .cx-review-payment-col {
      @include media-breakpoint-down(md) {
        padding-bottom: 0;
      }
    }
  }

  .cx-review-cart-heading {
    border-width: 0 0 thin;
    padding: 8px 0;
    font-size: 20px;
  }

  .cx-review-cart-item.col-md-12 {
    padding: 0;
  }
}
