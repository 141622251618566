cx-order-overview {
  @extend %cx-order-overview  !optional;

  .cx-order-summary {
    background-color: var(--cx-color-background);

    .container {
      display: flex;
      flex-wrap: wrap;
      padding: 15px 0;
    }

    cx-card {
      @extend %cx-card !optional;

      &.cx-order-summary-alt .cx-card-body {
        @include media-breakpoint-down(sm) {
          flex-direction: row;
          align-items: baseline;
        }
      }

      &.cx-order-summary-order-number {
        .cx-card-label,
        .cx-card-title {
          font-size: 20px;
        }

        @include media-breakpoint-up(md) {
          .cx-card-body {
            margin-top: -2px;
          }
        }
      }

      .cx-card-title {
        font-size: 18px;
        text-transform: none;
        margin-bottom: 0;
        margin-top: 0;
        font-weight: bold;
        
        &:after {
          content: ':';
          margin-left: -3px;
          margin-right: 6px;
        }
      }

      .card-body {
        justify-content: start;
      }

      @include media-breakpoint-down(sm) {
        margin: 4px 0;

        .card-body {
          padding: 0;
        }
      }
    }
    
    @include media-breakpoint-up(md) {
      .container > * {
        flex-basis: 25%;
      }
    }

    @include media-breakpoint-down(sm) {
      background-color: var(--cx-color-background);

      > .container {
        padding: 15px 30px;
      }
    }
  }
}
