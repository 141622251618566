.user-form {
  @include media-breakpoint-up(md) {
    width: 100% !important;
    --cx-max-width: 66% !important;
  }

  form {
    display: block !important;
    width: 100%;
  }
  
  .user-form-actions {
    width: 100%;
    text-align: right;
    margin-top: 30px;

    button,
    a {
      flex: 0 0 calc(50% - 10px);
      margin-top: 12px;
      
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }
}
