cx-page-layout {
  &:empty {
    display: none;
  }
    app-box-categories{
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-flow: row;
        position: relative;
        padding: 0;
        border: solid medium white;

        @include media-breakpoint-up(md) {
            grid-auto-columns: 1fr;
            grid-template-columns: 1fr;
            grid-auto-flow: column;
        }
    }
}
