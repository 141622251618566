app-custom-navigation {
  // all ategory headings in desktop have uppercase text, as
  // well as the root category links
  h5,
  app-custom-navigation-ui > nav > cx-generic-link,
  cx-navigation-ui > nav > cx-generic-link {
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  app-custom-navigation-ui,
  cx-navigation-ui {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@include media-breakpoint-up(lg) {
  cx-category-navigation {
    cx-navigation-ui {
      > nav {
        > .wrapper {
          a {
            white-space: break-spaces;
          }
        }
      }

      span {
        font-weight: var(--cx-font-weight);
      }
    }
  }
}

cx-category-navigation {
  .childs {
    &[depth="2"] {
      @include media-breakpoint-up(md) {
        > {
          @for $i from 1 through 10 {
            .grid-col-#{$i} {
              grid-column: $i;
            }
            .grid-row-start-#{$i} {
              grid-row-start: $i;
            }

            .grid-row-end-#{$i} {
              grid-row-end: $i;
            }
          }

          [class*="grid-col-"] {
            border-left: 1px solid $border-menu;
          }

          [class*="grid-row-"] {
            padding: 12px 24px 12px 24px;
          }

          .grid-col-1 {
            border-left: none;
          }

          .grid-row-start-1 {
            margin-top: 0;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .ellipsis {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap !important;
      display: block !important;
      border-bottom: none !important;
    }
  }
}

@include media-breakpoint-down(md) {
  header.is-expanded {
    cx-category-navigation {
      app-custom-navigation-ui.flyout,
      cx-navigation-ui.flyout {
        &.is-open {
          nav.is-open {
            .wrapper-menu {
              height: 300px;
              overflow: auto;
            }
          }
        }
      }
    }

    app-custom-navigation-ui.flyout,
    cx-navigation-ui.flyout {
      nav {
        .user-menu .icon-chevron-down {
          transition: transform .2s;
          color: white;
        }
      }

      span {
        padding: 0;
        font-weight: 300;
      }

      &.is-open {
        nav {
          &.is-open {
            .user-menu .icon-chevron-down {
              transform: scale(0.5) rotate(180deg);
            }
          }
        }
      }
    }

    .SiteLogin {
      flex: 70%;

      app-custom-navigation-ui > nav {
        width: 100%;
      }
    }

    .NavigationBar {
      app-custom-navigation-ui,
      cx-navigation-ui {
        nav {
          width: 100%;
        }
      }

      // we hide the categories in flyout mode on mobile
      cx-category-navigation > cx-navigation-ui > .is-opened > h5 {
        display: none;
      }

      cx-category-navigation > cx-navigation-ui > .is-opened > h5 {
        color: var(--cx-color-primary);

        .icon-chevron-down {
          display: none;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  app-custom-navigation-ui.flyout .wrapper, cx-navigation-ui.flyout .wrapper {
    background-color: white;
  }
}
