app-custom-unit-display {
  &.unit {
    display: flex;
    flex-grow: 1;
    border: thin solid var(--cx-color-light);
    margin-right: 16px;
    padding: 0 13px;
    line-height: 1.15;
    min-height: 48px;
    align-items: center;
  }

  .unit-info {
    min-width: 68px;
  }

  .unit-name {
    font-size: 15px;
  }

  .unit-discount {
    font-size: 16px;
    color: var(--cx-color-danger);
    margin-left: 4px;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .unit-prices {
    flex-grow: 1;
    text-align: right;
  }

  .unit-price-item {
    font-size: 14px;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .unit-price-old {
    font-size: 12px;
    margin-left: 6px;
    text-decoration: line-through;
  }

  .unit-price-final {
    font-size: 16px;
    margin-left: 6px;
  }

  .unit-price-nostock {
    font-size: 18px;
    font-weight: bold;
    color: var(--cx-color-secondary);
    text-align: center;
    display: inline-block;
  }

  cx-product-grid-item cx-add-to-cart.default-unit-only & {
    &.unit {
      margin-right: 0;
    }
  }

  @include media-breakpoint-down(xs) {
    cx-product-list-item cx-add-to-cart.default-unit-only & {
      &.unit {
        margin-right: 0;
      }
    }
  }
}
