app-custom-banner-and-products-grid {
  display: block;
  @include media-breakpoint-up(md) {
    padding: 50px;

    &.invert {
      .container {
        > .row {
          flex-direction: row-reverse;
        }
      }
    }
  }

  .title {
    color: var(--cx-color-secondary);
    text-align: center;
    font-size: 32px;
    letter-spacing: 1px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: bold;
    display: block;
  }

  .banner {
    height: 100%;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .product-item {
    position: relative;
    margin: 16px 0;

    .name {
      color: var(--cx-color-secondary);
      font-weight: 600;
      font-size: 20px;
      text-align: center;
      line-height: 18px;
      margin-bottom: 12px;
      position: relative;
      z-index: 2;
    }

    .price {
      text-align: center;
      margin-bottom: 8px;
      visibility: hidden;
      color: $light;
      position: relative;
      z-index: 2;
    }

    app-custom-unit-display {
      display: none;
    }

    .actions {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: none;
      z-index: 1;
    }

    .btn-add-to-cart, .url {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 8px;
    }

    &:hover {
      .actions {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, .35);
      }

      .price {
        visibility: visible;
      }
    }
  }
}
