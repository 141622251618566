@mixin link-anim {
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: var(--cx-color-secondary);;
    transition: transform .25s cubic-bezier(0.25, 0.8, 0.25, 1);
    transform-origin: left center;
    transform: scale(0, 1);
  }

  &:hover:after {
    transform-origin: left center;
    transform: scale(1, 1);
  }
}

@mixin link-anim-ellipsis {
  .ellipsis {
    &:after {
      content: '';
      display: block;
      margin-top: -6px;
      width: 100%;
      height: 1px;
      background: var(--cx-color-secondary);;
      transition: transform .25s cubic-bezier(0.25, 0.8, 0.25, 1);
      transform-origin: right center;
      transform: scale(0, 1);
    }
  }

  &:hover {
    .ellipsis:after {
      transform-origin: left center;
      transform: scale(1, 1);
    }
  }
}
