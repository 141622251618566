cx-storefront{
  &.ContentPagePublicTemplate {
    @include media-breakpoint-down(md) {
      padding-bottom: 0;
      main{
        margin-top: -48px;
      }
    }
  }
}

.ContentPagePublicTemplate {
  padding-bottom: 3rem;
  .BottomHeaderSlot{
    margin: 0;;
  }

  header {
    display: flex;
    &.is-expanded {
      .navigation {
        margin-top: 50px;
        height: calc(100vh - 50px);
        overflow: auto;
      }
    }
    .header {
      padding: 0 10px;
      margin-top: 25px;

      @include media-breakpoint-down(lg) {
        padding: 10px 15px;
      }

      .HeaderText {
        left: 0;
        margin-left: 0;
        max-width: none;
        padding: 0;
        margin-top: -25px;

        &,
        .se-storefront-component {
          min-height: 0;
        }
      }
    }

    .SiteLogo {
      padding: 0;
    }

    .navigation {
        margin-top: 25px;
    }
  }

  cx-page-slot.BottomHeaderSlot:empty{
    display: none;
  }

  @include media-breakpoint-up(xl) {
    header {
      &:not(.is-expanded) cx-page-layout.navigation {
        padding-inline-end: calc((100vw - var(--cx-page-width-max)) /2);

        app-custom-site-context-selector {
          padding-right: 15px;
        }
      }
      
      .header {
        padding-inline-start: calc((100vw - var(--cx-page-width-max)) /2);

        .SiteLogo {
          padding-left: 15px;

          cx-banner {
            max-width: none;
          }
        }
      }
    }
  }

  @include media-breakpoint-only(lg) {
    header {
      &:not(.is-expanded) cx-page-layout.navigation {
        padding-right: 15px;
      }
      
      .header {
        padding: 0 15px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    header:not(.is-expanded) cx-page-layout.navigation {
      display: flex;
      justify-content: flex-end;
    }

    header cx-page-slot {
      display: flex;
      align-items: center;
      flex-direction: column;
      max-width: var(--cx-page-width-max);
      padding-inline-end: 1.5rem;
      padding-bottom: 0;
      padding-inline-start: 1.5rem;
      margin: 0;
      padding-top: 3rem;

      > * {
        max-width: var(--cx-max-width, 50%);
        width: 100%;
      }
    }
    .navigation {
      .SiteContext {
        margin: 0;
        flex: 0;
        padding: 0;
        app-custom-site-context-selector {
          max-width: none;
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    --cx-max-width: 100%;

    cx-page-slot {
      > * {
        max-width: var(--cx-max-width, 80%);
      }
    }
  }

  @include media-breakpoint-down(md) {
    .PreHeader {
      padding: 0;
    }
  }

  cx-page-layout {
    &.ContentPagePublicTemplate{
      max-width: var(--cx-page-width-max);
      padding-top: 2rem;
      padding-inline-end: 1.5rem;
      padding-bottom: 0;
      padding-inline-start: 1.5rem;
      margin: auto;
    }

    @include media-breakpoint-down(md) {
      &.ContentPagePublicTemplate,
      &.navigation {
        display: block;
      }

      &.ContentPagePublicTemplate{
        padding-top: 15%;
      }
    }

    @include media-breakpoint-down(sm) {
      &.ContentPagePublicTemplate{
        padding-top: 0;
      }
    }
  }

  footer {
    .FooterBottom {
      padding: 0;
    }

    .footer {
      position: fixed;
      left: 0;
      bottom: 0;
      max-width: none;
    }

    .cx-notice {
      padding: 8px;
    }

    cx-paragraph {
      width: 100%;
      max-width: unset;
    }
  }

  .overlay {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
  }
}
