app-custom-video-carousel {
  display: block;

  @include media-breakpoint-down(md) {
    max-width: 100%;
  }

  app-custom-video-info {
    background-color: transparent;

    @include media-breakpoint-up(md) {
      youtube-player {
        grid-column: 2;
        grid-row: 1;
      }
    }
    &.full-page-width {
      width: 100%;
      margin: unset;
    }
  }

  .icon-chevron-right, .icon-chevron-left {
    font-size: 48px;
  }

  cx-carousel .indicators button {
    color: #d2d2d2;

    &[disabled] {
      color: var(--cx-color-light);
    }
  }
}
