cx-carousel {
  @extend %cx-carousel !optional;

  .carousel-panel {
    .slides {
      height: 100%;
      
      .slide {
        justify-content: center;
      }
    }
  }

  .item h4 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .price {
    .old {
      margin-right: 6px;
      text-decoration: line-through;
    }
  }

  .previous,
  .next {
    @include forFeature('a11yFocusableCarouselControls') {
      padding: 0;
      
      &[aria-disabled='true'] {
        display: none;
      }
    }
  }
}
