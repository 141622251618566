@font-face {
  font-family: "Montserrat", sans-serif;
  src: url("../../../../assets/fonts/emarsys/montserrat/Montserrat-Bold.ttf") format("ttf");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Montserrat", sans-serif;
  src: url("../../../../assets/fonts/emarsys/montserrat/Montserrat-Black.ttf") format("ttf");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}
