.NewsPageTemplate {
  cx-page-layout {
    @extend .container !optional;
  }
  .page-title{
    text-transform: uppercase;
    font-size: 35px;
    line-height: 1;
    letter-spacing: 2.4px;
    font-weight: bold;
  }
  .news-date {
    font-size: 12px;
    color: $light;
  }

  .news-image {
    margin-bottom: 36px;
    text-align: center;
  }
}
