cx-order-details-actions {
  @extend %cx-order-details-actions !optional;

  .cx-nav {
    text-align: right;
    margin-top: 30px;

    div:empty {
      display: none;
    }

    .btn {
      @include media-breakpoint-up(md) {
        margin-bottom: 12px;

        +.btn {
          margin-left: 16px;
        }
      }

      @include media-breakpoint-down(sm) {
        margin: 0 0 16px;
        width: 100%;
        display: block;
      }
    }
  }
}
