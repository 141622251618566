cx-page-layout {
  app-info-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    padding: 0 12px;

    .title {
      margin: 36px auto;
    }

    .info-title {
      color: $compTitleColor;
      font-size: $compTitleSize;
      font-weight: $compTitleWeight;
      letter-spacing: $compTitleSpacing;
      margin: 16px 0 0;
      line-height: 24px;
    }

    p {
      color: var(--cx-color-primary);
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 2px;
    }

    cx-media {
      width: $compInfoSizeImg;
      height: $compInfoSizeImg;
      margin: 8px auto;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        @include image-rendering;
      }
    }
  }
}
