cx-address-book {
  .header-default {
    background-color: white;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 30px;
  }
  .cx-action-link {
    text-decoration: none;
    padding: 8px;
  }

  app-custom-header-tables {
    font-size: 18px;
    display: flex;
    padding: 10px;
    align-items: center;
    text-align: center;
    div:nth-child(2),
    div:nth-child(3),
    div:nth-child(5) {
      flex: 20%;
    }
    div:last-child {
      flex: 10%;
      margin-right: 0;
    }
    div {
      flex: 15%;
      margin-right: 10px;
    }
  }

  app-custom-row {
    cx-address-book .cx-address-deck {
      padding-top: 0;
    }

    .cx-card-label-container,
    .cx-card-actions {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }
  .buttons-actions {
    margin: 50px 0 50px 0;
  }

  .cx-address-card.defaultAddress {
    background-color: $background;
  }
  .cx-address-card:nth-child(even) {
    background-color: $background;
  }

  .cx-card-label-container {
    padding: 10px;
    .cx-card-actions {
      flex: 10%;
      margin-right: 0;
      justify-content: flex-end;
    }
    .cx-card-label:nth-child(2),
    .cx-card-label:nth-child(3),
    .cx-card-label:nth-child(5) {
      flex: 20%;
    }
    .cx-card-label {
      flex: 15%;
      margin-right: 10px;
      text-align: center;
    }
  }

  @include media-breakpoint-down(md) {
    .cx-card-label-container {
      .cx-card-label {
        text-align: left;
      }
      .cx-card-actions {
        flex-direction: row;
        justify-content: flex-start;
      }
      .btn-remove{
        margin-right: 10px;
      }
    }

    app-custom-row {
      .cx-card-label-container,
      .cx-card-actions {
        align-items: flex-start;
        flex-direction: column;
        flex-wrap: wrap;
      }
    }
    app-custom-header-tables {
      display: none;
    }
    .cx-action-link {
      background-color: white;
      text-transform: uppercase;
      border: 2px solid $primary;
      color: $secondary;
      padding: 10px 15px;
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 2px;
      margin-top: 15px;
    }
  }
}
