cx-item-counter {
  @extend %cx-item-counter !optional;
  
  &:not(.readonly) {
    input {
      &:focus {
        background: var(--cx-color-transparent);
      }
    }
  }
}
