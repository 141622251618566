app-custom-popup-shipping {
  .modal-dialog {
    .modal-content {
      padding: 40px;
    }
  }
}


app-custom-age-status {
  .SiteLogo {
    height: 30px;
    width: 100%;
    max-width: 180px;
    margin-bottom: 24px;
    cx-media {
      width: 100%;
      height: 100%;
    }
    img {
      object-fit: contain;
    }
  }
  
  .modal-text {
    color: var(--cx-color-secondary);
  }

  .wrapper-buttons {
    margin-top: 25px;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    a,
    button {
      min-width: 150px;
      margin-left: 24px;
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
      width: 100%;
      a,
      button {
        max-width: none;
        width: 100%;
        margin-top: 12px;
      }
      a {
        margin-right: 0;
        margin-bottom: 30px;
      }
    }
  }
}
