.alert {
  .close {
    right: 1rem;
  }

  button {
    min-width: 36px;
    max-width: 36px;
  }
}
