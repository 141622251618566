.modal {
  &-dialog {
    .modal-content {
      .modal-header {
        .close {
          right: -20px;

          .cx-icon {
            font-size: 26px;
          }
        }
      }

      .cx-dialog-header {
        padding-top: 2rem;
        padding-inline-end: 1.75rem;
        padding-bottom: 0.85rem;
        padding-inline-start: 1.75rem;
        border-width: 0;
      }

      .cx-dialog-title {
        @include type('3');
      }

      .modal-body {
        padding: 1rem 0.2rem;

        .cx-dialog-row {
          margin: 0;
          display: flex;
          padding-top: 0;
          padding-inline-end: 0.875rem;
          padding-bottom: 0.85rem;
          padding-inline-start: 0.875rem;
          max-width: 100%;
          flex-wrap: wrap;

          .cx-dialog-item {
            cx-cart-item {
              .cx-compact {
                display: flex;
                flex-direction: row;

                .cx-info {
                  &-container {
                    display: flex;
                    flex-direction: column;
                    margin: 0;

                    .cx-name {
                      font-weight: var(--cx-font-weight-bold);
                      overflow-wrap: break-word;

                      .cx-link {
                        color: var(--cx-color-text);
                        text-decoration: none;
                      }
                    }
                  }

                  .cx-total {
                    .cx-value {
                      font-size: 20px;
                      font-weight: 700;
                    }
                  }

                  &-price {
                    .cx-quantity {
                      .cx-value {
                        font-size: var(--cx-font-size, 0.875rem);
                        font-weight: var(--cx-font-weight-normal);
                        line-height: var(--cx-line-height, 1.2222222222);

                        cx-item-counter {
                          display: inline-flex;
                          border: solid 1px var(--cx-color-light);
                          border-radius: 4px;

                          &:disabled {
                            cursor: not-allowed;
                          }

                          button {
                            margin: 0;
                            max-height: 48px;
                            min-width: 40px;
                            border: none;
                            cursor: pointer;
                            color: var(--cx-color-text);
                            background-color: var(--cx-color-transparent);
                            font-size: var(--cx-font-size, 1rem);
                            font-weight: var(--cx-font-weight-normal);
                            line-height: var(--cx-line-height, 1.6);

                            &:disabled {
                              color: var(--cx-color-light);
                            }
                          }

                          input {
                            cursor: default;
                            border: solid 1px var(--cx-color-light);
                            border-width: 0 1px;

                            padding: 12px 9px;
                            max-height: 46px;
                            min-width: 48px;
                            max-width: 78px;

                            text-align: center;
                          }

                          input[type='number'] {
                            /* Remove inner arrows */
                            -webkit-appearance: none;
                            -moz-appearance: textfield; /* to Firefox */

                            /* Remove outer arrows */
                            appearance: textfield; /* to Firefox */
                          }

                          /* Removes inner arrows in Webkit browsers (Chrome, Safari) */
                          input[type='number']::-webkit-inner-spin-button,
                          input[type='number']::-webkit-outer-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                          }
                        }

                        @include media-breakpoint-up(md) {
                          justify-content: flex-end;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .cx-dialog-actions {
            display: flex;
            flex-direction: column;
            padding-inline-start: 2.5rem;
            padding-inline-end: 1rem;
            border-inline-start-width: 1px;
            border: 0 solid var(--cx-color-light);

            .cx-dialog-total {
              font-size: var(--cx-font-size,1.125rem);
              font-weight: var(--cx-font-weight-bold);
              line-height: var(--cx-line-height,1.2222222222);
              display: flex;
              justify-content: space-between;
              padding: 0;
              margin-bottom: 0;
            }

            .cx-dialog-promotions {
              padding: 0 0 1.25rem;
            }

            .cx-dialog-buttons {
              display: flex;
              flex-direction: column;
            }
          }
        }
      }
    }
  }

  // TODO: Remove feature flag next major and leave bellow tag
  @include forFeature('a11yExpandedFocusIndicator') {
    .cx-dialog-header button.close,
    button.close {
      padding: 1rem 1rem !important;
      margin: -1rem -1rem -1rem auto !important;
      margin-inline-end: 0 !important;
    }
  }
  //

  .cx-dialog-header button.close,
  button.close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
    margin-inline-end: 0;
  }
}

@include media-breakpoint-down(md) {
  .cx-dialog-actions {
    margin-top: 6px;
    margin-bottom: 6px;
  }
}
