osborne-captcha-disclaimer {
  display: block;
  color: var(--cx-color-text);
  background-color: #f7f7f7;
  padding: 0.25rem;

  &.osborne-spa {
    background-color: rgba(40, 40, 40, 0.7);
    color: #ffffff;
  }

  &.osborne-public, &.osborne-spa, &.riofrio {
    a {
      margin-top: 0;
    }
  }

  span {
    a {
      color: var(--cx-color-primary);
      text-decoration: underline;
      display: inline-block;
    }
  }
}
