cx-page-layout {
  &.footer {
    --cx-color-dark: var(--cx-color-background);
    --cx-color: var(--cx-color-primary);

    .Footer > :not(cx-footer-navigation):not(cx-paragraph) {
      @extend .full-page-width !optional;

      background-color: $footerTopBackground;
    }
  }

  .page-title {
    font-size: 35px;
    margin-top: 40px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: inherit;
  }

  .page-description {
    margin: 20px 0 8px;
    font-size: 18px;
    line-height: 1.4;
  }

  cx-paragraph {
    a:not([href]):not([tabindex]) {
      &,
      &:hover,
      &:focus {
        color: var(--cx-color-primary);
        cursor: pointer;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
  &.ContentPage1Template{
    .full-page-width {
      display: block;
      width: 100vw;
      margin-left: -1.5rem;
      margin-right: -1.5rem;
      @media (min-width: map-get($container-max-widths, "xl")) {
        margin-left: calc(((100vw - var(--cx-page-width-max)) / -2) - 1.5rem);
        margin-right: calc(((100vw - var(--cx-page-width-max)) / -2) - 1.5rem);
      }
    }
  }
}

.full-page-width {
  display: block;
  @media (min-width: map-get($container-max-widths, "xl")) {
    min-width: 100vw;
    width: 100vw;
    margin-left: auto;
    margin-right: auto;
    max-width: 100vw;

    footer & {
      margin-left: calc(((100vw - var(--cx-page-width-max)) / -2) - 15px);
      margin-right: calc(((100vw - var(--cx-page-width-max)) / -2) - 15px);
    }
  }

  footer & {
    @media (max-width: map-get($container-max-widths, "xl") - 1) {
      margin-right: -15px;
      margin-left: -15px;
    }
  }
}

app-root.revolution {
  .LandingPageTransparentHeaderTemplate {
    app-custom-news-list {
      .row {
        @include media-breakpoint-down(md) {
          margin-right: 0;
          margin-left: 0;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      app-custom-media-links {
        ul {
          li {
            cx-generic-link {
              .btn {
                padding-left: 20px;
                padding-right: 20px;
              }

              img {
                height: 30px;
              }
            }
          }

          li:first-child {
            cx-generic-link {
              .btn {
                margin-left: 15px;
              }
            }
          }

          li:last-child {
            cx-generic-link {
              .btn {
                margin-right: 15px;
              }
            }
          }
        }
      }
    }
  }

  cx-paragraph {
    .container.mt-4 {
      .row {
        .col-md-5 {
          .text-center {
            iframe {
              @include media-breakpoint-down(md) {
                max-width: 100%!important;
              }
              @include media-breakpoint-down(sm) {
                max-width: 92vw!important;
              }
            }
          }
        }
      }
    }
  }
}
