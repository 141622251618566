@import '@spartacus/checkout';

cx-payment-type {
  @extend %cx-payment-type !optional;

  @include checkout-media-style();
  padding-top: 0;

  @include media-breakpoint-down(md) {
    padding-top: 20px;
  }

  .cx-payment-type-container {
    padding-top: 20px;
  }

  .cx-payment-type-label {
    margin: 0;

    .cx-payment-type {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .payment-icon {
    height: 25px;
    margin-right: 10px;
    vertical-align: top;
  }
}

.MultiStepCheckoutSummaryPageTemplate {
  cx-payment-type {
    cx-place-order {
      .form-group {
        justify-content: center;
      }
      .cx-checkout-btns {
        justify-content: center;
        margin-top: 10px;
      }
    }
  }
}


.credit-card-holder {
  width: 100%;
  margin-left: 24px;
  @include media-breakpoint-down(sm) {
    margin-left: 16px;
  }
}

.credit-card-item {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @include media-breakpoint-down(xs) {
    justify-content: end;
    border-bottom: solid thin #aaa;
  }
}
