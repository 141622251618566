app-custom-image-info {
  &.alternate {
    background-color: var(--cx-color-background);
  }

  &.invert {
    .image {
      order: 2
    }

    .content {
      order: 1;
    }
  }

  cx-media img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .container {
    padding: 0;
    display: grid;

    @include media-breakpoint-up(lg) {
      padding: 50px;
      grid-auto-columns: 1fr;
      grid-template-columns: 1fr;
      grid-auto-flow: column;
      column-gap: 50px;

      .image,
      .content {
        align-self: center;
      }
    }

    @include media-breakpoint-down(md) {
      .content {
        padding: 30px 15px;
      }

      &.alternate {
        .content {
          padding: 30px 15px;
        }
      }
    }
  }

  .image {
    margin-bottom: 0;
    display: block;

    @include media-breakpoint-up(lg) {
      display: flex;
      height: 100%;
      margin-bottom: 0;
    }

    cx-media {
      width: 100%;

      iframe {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .btn {
    min-width: 150px;
  }

  .title {
    color: var(--cx-color-primary);
    font-size: 18px;
    letter-spacing: 3.5px;
    font-weight: 600;

    + button {
      margin-top: 30px;
    }
  }

  .subtitle {
    color: $compImageInfoSubtitleColor;
    font-weight: 600;
    font-size: 36px;
    font-family: $font-family-custom2;
    letter-spacing: -0.2px;

    @include media-breakpoint-only(md) {
      font-size: 32px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 28px;
    }

    + button {
      margin-top: 30px;
    }
  }

  .paragraph {
    margin: 45px 0 30px;
  }

  .description {
    color: var(--cx-color-secondary);
    margin: 45px 0;
    line-height: normal;
    font-size: 18px;

    
    + .paragraph {
      margin-top: -22px;
    }
  }

  .custom-image-info-actions {
    margin-top: auto;

    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: space-between;
      column-gap: 10px;
      margin-top: auto;

      cx-generic-link + cx-generic-link {
        justify-content: flex-end;
      }
    }

    @include media-breakpoint-only(lg) {
      cx-generic-link {
        + cx-generic-link,
        &:first-child:not(:last-child) .btn {
          min-width: auto;
        }
      }
    }

    @include media-breakpoint-down(md) {
      cx-generic-link {
        display: block;
      }

      cx-generic-link + cx-generic-link .btn {
        margin-top: 20px;
      }
    }
  }

  &.full-page-width.full-image-width {
    @media (min-width: map-get($container-max-widths, "xl")) {
      .container .content {
        max-width: 660px;
      }
    }

    @include media-breakpoint-up(lg) {
      .container {
        max-width: none;
        padding: 50px 0;
      } 

      &.invert {
        .content {
          padding-left: 60px;
        }
      }
    }
  }
}

.info-data {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  border: thin solid $primary;
  border-radius: 2px;

  li {
    padding: 20px;
    text-align: center;
    flex-grow: 1;
    font-weight: bold;

    &:not(:first-child) {
      border-left: thin solid $primary;
    }

    span {
      display: block;
      font-size: 24px;
      margin-bottom: -8px;
    }
  }
}
