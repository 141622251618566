app-custom-files-info-component {
  margin-bottom: 16px;
  display: block;
  .file-title {
    font-weight: 600;
    font-size: 18px;
  }

  .file-description {
    display: inline-block;
    vertical-align: top;
    margin-right: 8px;
    flex: auto;
  }

  a.download .icon {
    margin-left: 0;
  }

  .icon {
    display: inline-block;
    vertical-align: top;
    max-width: 5%;
    font-size: 1.5rem;
  }

  .btn-link {
    color: var(--cx-color-secondary);
    display: flex;
    font-size: 14px;
    margin: 5px 0 0;
    padding-bottom: 10px;
    position: relative;
    text-decoration: none;

    &:after {
      position: absolute;
      content: "";
      width: 0;
      display: block;
      border-bottom: 1px solid var(--cx-color-primary);
      padding-top: 10px;
      -webkit-transition: .3s all;
      -moz-transition: .3s all;
      -ms-transition: .3s all;
      -o-transition: .3s all;
      transition: .3s all;
      bottom: 0;
    }

    &:hover {
      text-decoration: none;
      &:after {
        width: 100%;
        -webkit-transition: .3s all;
        -moz-transition: .3s all;
        -ms-transition: .3s all;
        -o-transition: .3s all;
        transition: .3s all;
      }
    }

  }

}
