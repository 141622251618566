.OrderConfirmationPageTemplate {
  cx-cart-item-list .cx-info .cx-info-price {
    grid-column: auto;
    grid-row: auto;
  }

  cx-order-confirmation-thank-you-message {
    order: 0;
  }

  cx-order-confirmation-overview {
    order: 1;
  }

  cx-order-confirmation-items {
    order: 2;

    .cx-order-items {
      max-width: 1320px;

      &-header {
        font-size: var(--cx-font-size, 1.125rem);
        font-weight: var(--cx-font-weight-bold);
        line-height: var(--cx-line-height, 1.2222222222);
        padding: 1.375rem 0;
        margin: 0;
        border: solid var(--cx-color-light);
        border-width: 0 0 1px;
      }

      cx-promotions {
        .cx-promotions {
          font-size: var(--cx-font-size, 1rem);
          font-weight: var(--cx-font-weight-semi);
          line-height: var(--cx-line-height, 1.2222222222);
          color: var(--cx-color-text);
          padding: 0;

          p {
            line-height: 1.6 !important;
            font-weight: 400 !important;
            padding: 6px 14px;
            background-color: #d6e9c8;
            margin-top: 6px;
            margin-bottom: 5px;
          }
        }
      }
    }

    cx-cart-item-list {
      .cx-item-list-row {
        .cx-info {
          grid-template-columns: 150px auto;
          position: relative;
          justify-items: stretch;
          display: grid;

          .cx-info-container {
            .cx-name {
              font-size: var(--cx-font-size, 1rem);
              font-weight: var(--cx-font-weight-bold);
              line-height: var(--cx-line-height, 1.2222222222);
              overflow-wrap: break-word;
              padding: 0;
              min-width: 14rem;

              .cx-link {
                text-decoration: none;
                color: var(--cx-color-text);
              }
            }
          }

          .cx-info-price-slim-price {
            font-weight: 700;
            display: block;
          }
        }

        .cx-total {
          justify-self: flex-start;

          .cx-value {
            font-size: 20px;
            font-weight: 700;
          }
        }
      }
    }
  }

  cx-guest-register-form {
    margin-top: 30px;
    order: 4;
  }

  cx-order-confirmation-totals {
    margin-top: 30px;
    margin-bottom: 30px;
    order: 3;

    .cx-order-summary {
      padding-inline-end: var(--cx-padding, 0);
      max-width: 1320px;

      cx-order-summary {
        padding: 15px;
        text-transform: uppercase;
        background-color: var(--cx-color-background);

        display: block;
        padding-inline-end: 15px;
        padding-inline-start: 15px;
        margin: 0;

        h4 {
          font-size: 30px;
          margin: 0 0 5px;
          font-weight: 700;
          text-transform: uppercase;
      }

      .cx-summary-partials {
        .cx-summary-row {
          padding: .5rem 0;
          display: flex;
          flex-wrap: wrap;
      }
      }
      }
    }
  }
}
