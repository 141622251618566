cx-product-list {
  @extend %cx-product-list !optional;

  cx-star-rating,
  .cx-product-rating {
    display: none;
  }

  .cx-sorting {
    &.top {
      > .row > .col-12 {
        @include media-breakpoint-down(md) {
          @include make-col(4,12);
        }
      }
    }

    .Section2 ~ .ProductGridSlot & {
      @include media-breakpoint-down(xs) {
        flex-flow: row wrap;
      }

      .custom-product-list-input {
        display: block;
      }
    }
  }

  .cx-page-section {
    padding-top: 30px;

    @include media-breakpoint-down(md) {
      padding-top: 0;
    }

    @include media-breakpoint-down(sm) {
      .cx-double-btn-container,
      .cx-single-btn-container {
        width: 100%;
        display: block;
        
        .btn + .btn {
          margin-top: 30px !important;
        }
      }
    }
  }

  .custom-product-list-input {
    display: none;
    position: relative;
    margin-bottom: 0;
    margin-left: 20px;

    @include media-breakpoint-down(xs) {
      margin-top: 20px;
      margin-left: 0;
      width: 100%;
    }

    cx-icon {
      position: absolute;
      transform: translateY(-50%);
      top: 50%;

      &.search {
        margin-left: 12px;
      }

      &.reset {
        opacity: 0;
        right: 2px;
        cursor: pointer;
        padding: 10px;
        transition: all .3s linear;

        &.visible {
          opacity: 1;
        }
      }
    }

    input {
      margin-bottom: 0;
      padding-left: 36px;
      padding-right: 30px;
    }
  }
}
