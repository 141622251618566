// Estilos comúnes
cx-storefront.PreferenceCenterPageTemplate {
  header {
    display: none;
  }
  &.stop-navigating:after {
    display: none;
    background-color: unset;
  }
}

cx-page-layout.PreferenceCenterPageTemplate {
  .TopContent {
    cx-banner {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 136px;
    }
    cx-paragraph {
      letter-spacing: 1px;
    }
  }
  .MiddleContent {
    padding: 4.25rem 0 3.25rem;
    text-align: center;

    h1 {
      font-size: 30px;
      letter-spacing: 1px;
    }
    h2 {
      font-size: 13px;
      letter-spacing: 2px;
    }
  }

  .BodyContent {
    padding-bottom: 6rem;

    .btn-primary {
      padding: 1.25rem 2rem;
    }
  }
  
  app-custom-preference-center form {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    margin: 0 auto;

    h2 {
      margin-bottom: 1rem;
      font-size: 13px;
      font-weight: 900;
      letter-spacing: 1px;
    }

    label {
      margin-bottom: 1.25rem;
    }
    
    input.form-control,
    ng-select .ng-select-container {
      background-color: unset;
      color: $emarsys-placeholder;
    }

    input.form-control, ng-select {
      border: 2px solid $emarsys-gold;
    }

    input.form-control[readonly]{
      color: $inverse;
    }
    
    span.label-content {
      text-transform: none;
    }

    .email-label {
      position: relative;

      &:hover {
        .email-contact {
          display: flex;
        }
      }
    }
    .email-contact {
      display: none;
      position: absolute;
      top: 0;
      left: 4.5rem;
      max-width: 265px;
      padding: 1rem;
      background-color: $inverse;
      border-radius: 10px;
      box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.29);

      p {
        margin-bottom: 0;
        font-size: 13px;
      }
    }

    .label-content.unsubscribe {
      font-size: 13px;
      letter-spacing: 2.08px;
    }

    .unsubscribe-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      padding: 1rem 0;
      border: 1px solid #E2E1E5;
      color: $emarsys-subscribe;
      line-height: 24px;

      p {
        margin-bottom: 0;
      }
    }
  }

  .toggle-button {
    position: relative;
    margin-inline: 1rem;

    input[type="checkbox"] {
      display: none; 
    }
  
    &-switch {
      position: absolute;
      top: 5px; 
      left: 4px;
      width: 24px;
      height: 24px;
      border-radius: 100%;
      transition: transform 0.3s;
      z-index: 100;
      cursor: pointer;
    }
  
    &-body {
      width: 52px;
      border-radius: 25px;
      transition: background-color 0.3s;
      overflow: hidden;
    }
  
    &-handle {
      width: 50%;
      height: 32px;
      color: #fff;
    }
  
    input[type="checkbox"]:checked  ~ .toggle-button-switch {
      transform: translate(20px);
      background-color: #F5F5F5;
    }
  }

  @include media-breakpoint-down(md) {
    .MiddleContent {
      padding-inline: 2rem;
    }
    app-custom-preference-center form {
      max-width: unset;
      padding-inline: 2rem;

      .unsubscribe-wrapper {
        justify-content: flex-start;
      }
    }
  }
}

@mixin set-emarsys-banner($class, $color, $logo-size) {
  .TopContent:has(> #{$class}) {
    cx-banner {
      background-color: $color;

      img {
        width: $logo-size;
      }
    }
  }
}

@mixin set-emarsys-variables($class, $color, $hover-color, $readonly-color) {
  .TopContent:has(> #{$class}) ~ .BodyContent {
    ::-webkit-scrollbar-thumb {
      background-color: $color;
    }

    .ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked, 
    .ng-select.ng-select-single .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
      background-color: $readonly-color;
    }

    input.form-control, ng-select {
      border: 1px solid $readonly-color;
    }

    input.form-control{
      &[readonly], 
      &:focus {
        color: white;
        background-color: $readonly-color;
      }
      &[readonly]::placeholder, 
      &:focus::placeholder {
        color: white;
      }
    }

    .toggle-button {
      &-switch {
        background-color: $color;
      }
      &-body {
        border: 1px solid $color;
      }
      input[type="checkbox"]:checked ~ .toggle-button-body {
        background-color: $color;
      }
    }

    .btn-primary {
      align-self: center;
      background-color: $color;
      border-color: $color;

      &:hover {
        background-color: $hover-color;
        border-color: $hover-color;
      }
    }
  }
}

@mixin emarsys-text-styles($class, $font-title, $font-subtitle, $h1-color, $h2-color, $form-title, $uppercase: true) {
  .TopContent:has(> #{$class}) ~ .MiddleContent {
    h1 {
      font-family: $font-title;
      color: $h1-color;

      @if $uppercase {
        text-transform: uppercase;
      }
    }
    h2 {
      font-family: $font-subtitle;
      color: $h2-color;
    }
  }
  .TopContent:has(> #{$class}) ~ .BodyContent {
    form {
      h2 {
        color: $form-title;
      }

      span.unsubscribe {
        color: $form-title;
      }
    }
  }
}

@mixin set-font-weight($class, $h1-weight, $h2-weight, $span-weight) {
  .TopContent:has(> #{$class}) ~ .MiddleContent {
    h1 {
      font-weight: $h1-weight;
    }
    h2 {
      font-weight: $h2-weight;
    }
  }

  .TopContent:has(> #{$class}) ~ .BodyContent {
    form span.unsubscribe {
      font-weight: $span-weight;
    }
  }
}

@include set-emarsys-banner('.osborne-logo-pub', $background, 300px);
@include set-emarsys-variables('.osborne-logo-pub', $primary, $secondary, $emarsys-readonly);
@include emarsys-text-styles('.osborne-logo-pub', $font-family-roboto, $font-family-roboto, $emarsys-osborne, $primary, $emarsys-osborne);
@include set-font-weight('.osborne-logo-pub', 1000, 700, 1000);

@include set-emarsys-banner('.osborne-logo-priv', $secondary, 300px);
@include set-emarsys-variables('.osborne-logo-priv', $primary, $secondary, $emarsys-readonly);
@include emarsys-text-styles('.osborne-logo-priv', $font-family-roboto, $font-family-roboto, $emarsys-osborne, $primary, $secondary);
@include set-font-weight('.osborne-logo-priv', 1000, 700, 1000);

@include set-emarsys-banner('.bodegas-logo', $emarsys-bodegas, 326px);
@include set-emarsys-variables('.bodegas-logo', $emarsys-bodegas, $emarsys-bodegas-hover, $emarsys-bodegas-readonly);
@include emarsys-text-styles('.bodegas-logo', $font-family-libre-baskerville, $font-family-raleway, $text, $text, $text);
@include set-font-weight('.bodegas-logo', 700, 500, 700);

@include set-emarsys-banner('.brandy-logo', $emarsys-brandy, 171px);
@include set-emarsys-variables('.brandy-logo', $emarsys-brandy, $emarsys-brandy-hover, $emarsys-brandy-readonly);
@include emarsys-text-styles('.brandy-logo', $font-family-cinzel, $font-family-montserrat, $text, $text, $text);
@include set-font-weight('.brandy-logo', 900, 700, 900);

@include set-emarsys-banner('.gingold-logo', $emarsys-gold-logo, 208px);
@include set-emarsys-variables('.gingold-logo', $emarsys-gold, $emarsys-gold-hover, $emarsys-gold-readonly);
@include emarsys-text-styles('.gingold-logo', $font-family-conneqt, $font-family-saaSeriesDDOT, $emarsys-gold-text, $text, $text);
@include set-font-weight('.gingold-logo', 700, 600, 400);

@include set-emarsys-banner('.nordes-logo', $inverse, 158px);
@include set-emarsys-variables('.nordes-logo', $emarsys-nordes, $emarsys-hover, $emarsys-nordes-readonly);
@include emarsys-text-styles('.nordes-logo', $font-family-playfair-display, $font-family-spartan, $emarsys-nordes, $emarsys-nordes, $emarsys-nordes, false);
@include set-font-weight('.nordes-logo', 900, 700, 900);

@include set-emarsys-banner('.riofrio-logo', $inverse, 171px);
@include set-emarsys-variables('.riofrio-logo', $emarsys-riofrio, $emarsys-hover, $emarsys-readonly);
@include emarsys-text-styles('.riofrio-logo', $font-family-teodor, $font-family-teodor, $emarsys-riofrio, $text, $emarsys-riofrio, false);
@include set-font-weight('.riofrio-logo', 400, 400, 400);

@include set-emarsys-banner('.sanchez-logo', $emarsys-sanchez, 280px);
@include set-emarsys-variables('.sanchez-logo', $emarsys-sanchez, $emarsys-sanchez-hover, $emarsys-sanchez-readonly);
@include emarsys-text-styles('.sanchez-logo', $font-family-wulkan-display, $font-family-spartan, $emarsys-sanchez, $emarsys-sanchez, $emarsys-sanchez-title, false);
@include set-font-weight('.sanchez-logo', 600, 700, 900);

@include set-emarsys-banner('.nudos-logo', $inverse, 196px);
@include set-emarsys-variables('.nudos-logo', $emarsys-11nudos, $emarsys-hover, $emarsys-readonly);
@include emarsys-text-styles('.nudos-logo', $font-family-playfair-display, $font-family-spartan, $emarsys-11nudos, $emarsys-11nudos, $emarsys-11nudos, false);
@include set-font-weight('.nudos-logo', 900, 700, 900);

// Estilos específicos
.TopContent:has(> .brandy-logo) ~ .BodyContent {
  .toggle-button {
    &-body {
      border: 1px solid $emarsys-brandy-hover;
    }

    &-switch {
      background-color: $emarsys-brandy-hover;
    }
    
    input[type="checkbox"]:checked ~ .toggle-button-body {
      border: 1px solid $emarsys-brandy-hover;
      background-color: $emarsys-brandy-hover;
    }
  }

  .unsubscribe-wrapper {
    border: 1px solid $emarsys-brandy;
  }
}

.TopContent:has(> .gingold-logo) {
  ~ .MiddleContent {
    h1 {
      letter-spacing: 4.8px;
    }
    h2 {
      letter-spacing: 2.08px;
    }
  }
  ~ .BodyContent {
    form {
      h2 {
        letter-spacing: 2.08px;
      }
      input.form-control, ng-select {
        color: $emarsys-placeholder;
        border: 2px solid $emarsys-gold;
      }

      span.label-content {
        font-size: 0.8125rem;
      }
      span.label-content,
      input.form-control,
      ng-select,
      .unsubscribe-wrapper p {
        text-transform: uppercase;
      }

      span.label-content,
      .unsubscribe-wrapper p {
        font-size: 0.875rem;
        color: $emarsys-gold-text;
        font-family: $font-family-saaSeriesDDOT;
      }

      .unsubscribe-wrapper {
        border: 2px solid $emarsys-gold;

        p {
          text-transform: uppercase;
          letter-spacing: 0.5px;
        }
      }
    } 
  }
}

.TopContent:has(> .riofrio-logo) {
  ~ .MiddleContent {
    padding-top: 2.8rem;
  }
  ~ .BodyContent {
    form {
      .label-content {
        color: $text;
      }
      .unsubscribe-wrapper p {
        padding-inline: 1.5rem;
      }
      span.unsubscribe {
        margin-bottom: 0.5rem;
        letter-spacing: 3px;
      }
    }

    .btn-primary {
      letter-spacing: 2px;
      font-weight: 400;
      border-radius: unset;
    }
  }
}

.TopContent:has(> .sanchez-logo) {
  ~ .BodyContent {
    form {
      .label-content,
      .email-contact {
        color: $emarsys-sanchez;
      }

      .label-content {
        font-weight: 500;
      }

      .unsubscribe-wrapper {
        color: $emarsys-sanchez;
        border-color: $emarsys-sanchez;
      }

      span.unsubscribe {
        margin-bottom: 0.5rem;
        letter-spacing: 3px;
      }

      .unsubscribe-wrapper p {
        font-size: 16px;
        font-weight: 700;
        color: $emarsys-sanchez-readonly;
      }
    } 

    .btn-primary {
      padding: 1.25rem 2rem;
      letter-spacing: 2px;
      font-weight: 400;
    }
  }
}

.TopContent:has(> .nudos-logo) {
  ~ .BodyContent .unsubscribe-wrapper {
    font-family: $font-family-spartan;
    font-size: 14px;
  }
}

.TopContent:has(> .bodegas-logo) {
  ~ .BodyContent .label-content.unsubscribe {
    font-family: $font-family-libre-baskerville;
  }
}

.TopContent:has(> .nordes-logo) ~ .BodyContent {
  form span.label-content {
    color: $emarsys-nordes;
  }
  .unsubscribe-wrapper {
    color: $emarsys-nordes;
    border: 1px solid $emarsys-nordes;

    p {
      font-weight: 900;
    }
  }
}

.TopContent:has(> .sanchez-logo),
.TopContent:has(> .nudos-logo) {
  ~ .MiddleContent {
    max-width: 1000px;
    margin: 0 auto;
  }
}

cx-page-layout.PreferenceCenterPageTemplate {
  .TopContent:has(> .osborne-logo-priv) {
    cx-banner {
      padding-top: 0.8rem;
      min-height: unset;
    }
    cx-paragraph {
      text-align: center;
      font-weight: 700;
      font-size: 1.125rem;
      color: $inverse;
      background-color: $secondary;
    
      p {
        margin-bottom: 0.8rem;
      }
    }
    
  }
  .TopContent:has(> .osborne-logo-pub) ~ .BodyContent,
  .TopContent:has(> .osborne-logo-priv) ~ .BodyContent,
  .TopContent:has(> .nudos-logo) ~ .BodyContent {
    input.form-control, 
    ng-select, 
    .unsubscribe-wrapper {
      border-radius: 5px;
    }
  }
}
