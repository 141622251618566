cx-order-details-totals {
  margin-top: 30px;

  .cx-summary {
    padding: 15px;
    text-transform: uppercase;
    background-color: var(--cx-color-background);

    h4 {
      margin: 0 0 5px;
    }

    cx-order-summary {
      display: block;
      padding-top: 16px;
      padding-inline-end: 15px;
      padding-bottom: 0;
      padding-inline-start: 15px;
      margin: 0;

      h4 {
        text-transform: uppercase;
        font-weight: var(--cx-font-weight-semi);
      }

      .cx-summary-partials {
        .cx-summary-row {
          padding: 0.5rem 0;
          display: flex;
          flex-wrap: wrap;
        }
      }

      cx-applied-coupons {
        .coupon-summary {
          margin: 0 10px 10px 0;
          font-size: 14px;
          background: var(--cx-color-background);
          border: 1px solid #a89f9a;
          border-radius: .25rem;
          font-weight: var(--cx-font-weight-normal);
          line-height: var(--cx-line-height,1.2222222222);
          display: inline-flex;
          align-items: flex-start;
          padding: var(--cx-padding,.25rem .25rem);
          word-break: break-all;
          margin-top: .25rem;
          margin-inline-end: .25rem;
          margin-bottom: 0;
          margin-inline-start: 0;
        }
      }
    }
  }

  @include media-breakpoint-down(md) { 
    > .row {
      margin-inline-end: 0;
      margin-inline-start: 0;
    }
  }
}

cx-order-details-shipping {
  position: relative;

  app-custom-order-ticket-download {
    @include media-breakpoint-up(sm) { 
      position: absolute;
      top: 14px;
      right: 0;
    }

    @include media-breakpoint-only(sm) { 
      top: 64px;
    }

    @include media-breakpoint-down(xs) { 
      display: block;
      text-align: right;
      margin: -20px 0 20px;
    }
  }

  @include media-breakpoint-up(sm) { 
    .page-title {
      margin-top: 0;
    }
  }
}
