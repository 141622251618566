app-custom-shipping-address{
    max-width: 100%;
    .cx-shipping-address-card{
        width: 100%;
        margin-top: 16px;
        .cx-shipping-address-card-inner{
            width: 100%;
            border-color: #EAE9E9;
        }

        table.table-scrollable {
          display: block;
          max-height: 500px;
          overflow: auto;

          thead {
            position: sticky;
            top: 0;
            background-color: white;
          }
        }
    }
}