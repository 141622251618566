app-custom-giftbox-product-header {
  margin-bottom: 30px;

  @include media-breakpoint-up(lg) {
    position: relative;

    .btn-remove-cart {
      position: absolute;
      bottom: 0;
      right: calc(16px - 35%);
    }
  }

  @include media-breakpoint-down(md) {
    .btn-remove-cart {
      float: right;
      margin-top: 6px;
    }
  }

  @include media-breakpoint-down(xs) {
    .btn-remove-cart {
      margin-top: 12px;
    }
  }
}
