cx-product-grid-item {
  @extend %cx-product-grid-item !optional;

  .cx-product-name {
    font-size: 20px;
    color: var(--cx-color-secondary);
    flex-grow: 1;
    height: auto;
    margin-bottom: 12px;
  }

  .cx-product-image-container {
    flex-grow: 1;
    text-decoration: none;
    position: relative;

    cx-media {
      height: 0;
      padding-top: 100%;

      img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      }
    }

    &:hover {
      .cx-product-name {
        color: var(--cx-color-primary);
      }
    }
  }

  cx-add-to-cart {
    form {
      flex-direction: column;
      align-items: stretch;
    }

    app-custom-unit-selector {
      margin-right: 0;
      margin-bottom: 16px;
    }

    app-custom-unit-display {
      &.unit {
        margin-right: 0;
      }
    }

    .btn-add-to-cart {
      width: 100%;
    }
  }

  app-custom-product-labels-items-view {
    position: absolute;
    z-index: 1;
    top: 25px;
  }

  @include media-breakpoint-up(md) {
    cx-add-to-cart {
      .unit-discount,
      .unit-price-item {
        display: none;
      }
    }
  }

 /*  @include media-breakpoint-up(sm) {
    cx-media.is-missing {
      min-height: auto;
      padding-bottom: 100%;
    }
  } */
}
