app-custom-page-top-title {
  margin-top: 50px;

 .title {
    font-size: 35px;
    text-transform: uppercase;
    font-weight: bold;
  }

  cx-page-layout.AccountPageTemplate & {
    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }

    .title {
      font-size: 30px;
    }
  }
}
