cx-page-slot {
  &.smartEditComponent {
    height: auto !important; //Force height auto for lazy loaded components
  }
}

[class*="smartedit-page-uid"] {
  a:not(.btn) {
    color: var(--cx-color-primary, #C56D4A);
  }
  cx-page-slot.cx-pending.page-fold ~ cx-page-slot.cx-pending {
    margin-top: unset;
  }

  cx-page-slot.page-fold ~ cx-page-slot.cx-pending {
    min-height: unset;
  }

  header {
    .header {
      padding: 0 10px;
      .HeaderText {
        min-height: 20px;
        position: relative;
        .se-storefront-component{
          min-height: 20px;
        }
      }
      app-custom-site-context-selector{
        margin-top: 0;
        margin-bottom: 0;
        select {
          color: #d5d5d5
        }
        .icon-chevron-down {
          color: #d5d5d5
        }
      }
      app-custom-generic-link{
        color: #d5d5d5;
        a {
          color: #d5d5d5;
        }
      }
    }
  }
}
