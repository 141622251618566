%news {
  .item-media {
    width: 100%;
    margin-bottom: 16px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .news-date {
    font-size: 12px;
    color: $light;
  }

  .news-item {
    margin-bottom: 24px;
  }
}

app-custom-news-list {
  .custom-news-filter {
    margin-top: 30px;

    .form-group {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
      .form-group {
        margin-left: 24px;
        display: inline-block;
        padding-right: 8px;

        label {
          text-align: left;
        }
      }
    }
  }

  .category-filter {
    min-width: 250px;
  }

  .searchbox {
    display: flex;
    position: relative;

    .form-control {
      margin-bottom: 0;
      padding-left: 36px;
      padding-right: 36px;
    }

    cx-icon {
      position: absolute;
      transform: translateY(-50%);
      top: 50%;

      &.prepend {
        left: 0;
        margin-left: 12px;
      }

      &.append {
        right: 0;
        margin-right: 12px;
      }

    }

    .btn {
      margin-left: 12px;
      min-width: unset;
    }
  }

  @extend %news !optional;
}

app-custom-news-slider, app-custom-news-list {
  .news-title {
    min-height: 90px;
    color: $color-extra-9;
    font-size: 22px;

    a {
      color: $color-extra-9;
      text-transform: none;

      &:hover {
        text-decoration: none;
        color: var(--cx-color-primary);
      }
    }
  }

  cx-carousel {
    .carousel-panel .slides .slide .item {
      align-items: start;
      text-align: start;
    }
  }
}

.news-thumbnail-description {
  color: $light;
}
